import React, { useMemo } from 'react';
import { Box, Button, Center, Skeleton, useBreakpointValue } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';

import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';

import { DataCorrectionState, transformChartData } from './data-correction-helpers';
import { useGetChartDataQuery } from './dataCorrectionApi';

type DataCorrectionChartDataProps = DataCorrectionState;

export const DataCorrectionChartData = (state: DataCorrectionChartDataProps) => {
  const { data, isLoading, isFetching, isError, refetch } = useGetChartDataQuery(state);
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });

  const chartOptions = useMemo(() => {
    const { dates, seriesData } = transformChartData(data || [], state);

    const chartOptions = {
      title: {
        text: '',
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        stackLabels: {
          enabled: false,
        },
      },
      xAxis: {
        categories: dates,
        labels: {
          formatter: function () {
            // Check if the date has a time component
            // If it does, only show the time component for readability
            const hasTime = typeof this.value === 'string' && this.value.includes(' ');
            if (hasTime) return this.value.split(' ')[1].substring(0, 5);
            return this.value;
          },
        },
      },
      tooltip: {
        animation: false,
      },
      series: seriesData,
      colors: COLOURS,
      chart: {
        type: 'column',
        zoomType: 'x',
        height: '400px',
        backgroundColor: 'transparent',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
    };

    return chartOptions;
  }, [data, state]);

  if (isError)
    return (
      <Center height={isMobileViewport ? 50 : '400px'} fontWeight={'bold'} textAlign={'center'}>
        There was an error fetching the data.
      </Center>
    );

  return (
    <Box data-testid={`data-correction-chart-${state.type}`}>
      {!isLoading && !data?.length ? (
        <Center height={isMobileViewport ? 50 : '400px'} fontWeight={'bold'} textAlign={'center'}>
          There is no data for this date range.
        </Center>
      ) : (
        <Box>
          <Center mb={2}>
            <Button
              ml={'auto'}
              isLoading={isFetching}
              onClick={refetch}
              loadingText="Fetching..."
              data-testid="refresh-btn"
            >
              Refresh
            </Button>
          </Center>
          {!isLoading && !isFetching ? (
            <Chart highcharts={Highcharts} options={chartOptions} />
          ) : (
            <Skeleton height="400px" />
          )}
        </Box>
      )}
    </Box>
  );
};
