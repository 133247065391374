import React, { useMemo, useRef, useState } from 'react';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spinner,
  Switch,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomSignalIcon } from 'clipsal-cortex-ui/src/components/CustomSignalIcon';
import { WIFI_CONFIGURABLE_METER_MODELS } from 'clipsal-cortex-utils/src/constants/common-constants';

import { CustomButton } from '../../../../common/components/CustomButton';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { useNavigationState } from '../../../../context/NavigationProvider';
import { selectSite } from '../../siteSlice';
import { selectRawMeters } from '../meterSetupSlice';
import { useUpdateWifiDetailsMutation } from './wifiApi';

export const WifiReset = ({ onToggleEditMode }: { onToggleEditMode: () => void }) => {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const { setNavigationState } = useNavigationState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [isWifiConfigured, setIsWifiConfigured] = useState(true);
  const meters = useSelector(selectRawMeters);
  const [saveWifiDetails, { isLoading: isResettingWifi }] = useUpdateWifiDetailsMutation();
  const hasPendingUpdate = useMemo(
    () =>
      !!Object.values(meters)?.some((meter) => {
        const pendingValues = meter.comms.wifi?.pending;
        if (!pendingValues) return false;
        // Only show the pending state if meter has a pending ssid or pskUpdatedAt
        return 'ssid' in pendingValues || 'pskUpdatedAt' in pendingValues;
      }),
    [meters]
  );
  const isWifiUpdatePending = hasPendingUpdate || isResettingWifi;
  const toast = useToast({ isClosable: true });
  const meter = useMemo(() => {
    const wifiConfigurableMeters = Object.values(meters).filter((meter) =>
      WIFI_CONFIGURABLE_METER_MODELS.includes(meter.model)
    );
    const meterWithValidSSID = wifiConfigurableMeters.find((meter) => meter.comms.wifi?.ssid);
    return meterWithValidSSID || wifiConfigurableMeters[0];
  }, [meters]);

  return (
    <VStack
      minH={`calc(${COMPONENT_MIN_HEIGHT} - ${isMobileViewport ? 60 : 200}px)`}
      px={2}
      data-testid="wifi-reset-screen"
    >
      <HStack w="100%" justify={'space-between'} py={4}>
        <Text fontWeight={500}>Wi-Fi</Text>
        <Switch
          colorScheme="primaryBranding"
          size="lg"
          isChecked={isWifiConfigured}
          isDisabled={isWifiUpdatePending}
          onChange={(e) => {
            if (e.target.checked) {
              onToggleEditMode();
            } else {
              onOpen();
            }
          }}
          data-testid="wifi-reset-button"
        />
      </HStack>
      {isWifiConfigured && (
        <>
          <Divider />
          <HStack
            onClick={isWifiUpdatePending ? undefined : onToggleEditMode}
            w="100%"
            justify={'space-between'}
            py={4}
            cursor={isWifiUpdatePending ? 'not-allowed' : 'pointer'}
            _hover={{ bg: 'gray.50' }}
            _dark={{ _hover: { bg: 'gray.800' } }}
            data-testid="current-wifi-details"
            opacity={isWifiUpdatePending ? 0.5 : 1}
          >
            <Box>
              <Flex align={'center'}>
                <CheckIcon color="primaryBranding.500" />
                <Text ml={2}>
                  {(meter?.comms?.wifi?.pending?.ssid ?? meter?.comms?.wifi?.ssid) || 'No SSID Available'}
                </Text>
              </Flex>
              <Text fontSize={12}>
                Last Communicated:{' '}
                {meter?.comms?.wifi?.lastHeardAt
                  ? format(new Date(meter?.comms?.wifi?.lastHeardAt * 1000), 'dd/LL/yyyy kk:mm:ss')
                  : 'N/A'}
              </Text>
            </Box>
            <HStack ml="auto">
              <CustomSignalIcon meter={meter} hideCellular hideText />
              {isWifiUpdatePending ? <Spinner size={'sm'} /> : <ChevronRightIcon w={5} h={5} />}
            </HStack>
          </HStack>

          <Divider />
          {isWifiUpdatePending && (
            <Text mr="auto" fontSize="sm" color="gray.600" _dark={{ color: 'gray.300' }}>
              Note: Please wait. This can take up to 60 seconds to update.
            </Text>
          )}
        </>
      )}
      <CustomButton
        data-testid="wifi-config-complete-btn"
        minW={260}
        containerProps={{ mt: 'auto' }}
        mt={4}
        onClick={() => {
          setNavigationState({ direction: 'backward' });
          navigate(`/site/${site.clipsal_solar_id}/meter_setup/meters`);
        }}
      >
        Done
      </CustomButton>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent data-testid="wifi-reset-alert-dialog" mx={4}>
          <AlertDialogHeader>Reset Wi-Fi configuration?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Resetting the Wi-Fi configuration will remove the current Wi-Fi settings. Are you sure you want to proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              isDisabled={isWifiUpdatePending}
              data-testid="wifi-reset-cancel-button"
            >
              No
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              data-testid="wifi-reset-confirm-button"
              onClick={async () => {
                try {
                  await saveWifiDetails({
                    body: {
                      wifi_ssid: '',
                      wifi_password: '',
                    },
                    siteId: site.clipsal_solar_id,
                  }).unwrap();
                  setIsWifiConfigured((prev) => !prev);
                  onClose();
                  toast({ title: 'Wifi reset successfully!', status: 'success' });
                } catch (error) {
                  toast({ title: 'Failed to reset wifi!', status: 'error' });
                }
              }}
              isLoading={isWifiUpdatePending}
              loadingText={hasPendingUpdate ? 'Updating...' : 'Resetting...'}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </VStack>
  );
};
