import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { DesktopTopNav } from '../../../common/components/DesktopTopNav';
import { MobileTopNav } from '../../../common/components/MobileTopNav';

type SubRouteTopNavProps = {
  onGoBack: () => void;
  title: string;
  children?: JSX.Element;
};

export function SubRouteTopNav(props: SubRouteTopNavProps) {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  return isMobileViewport ? <MobileTopNav {...props} /> : <DesktopTopNav {...props} />;
}
