import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as LogoDark } from '../../../assets/images/clipsal_logo_dark.svg';
import { ReactComponent as Logo } from '../../../assets/images/clipsal_logo.svg';
import { CustomButton } from '../../../common/components/CustomButton';
import { TermsAndConditionsModal } from '../TermsAndConditionsModal';

import 'react-phone-input-2/lib/bootstrap.css';
import '../../../styles/react-phone-input-2.css';

import { Browser } from '@capacitor/browser';
import { Auth } from 'aws-amplify';
import generator from 'generate-password-ts';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { ulid } from 'ulid';

import { AddressPinIcon } from 'clipsal-cortex-icons/src/custom-icons';

import { useAppDispatch } from '../../../app/hooks';
import { CLIENT_ID, PRIVACY_POLICY_URL, USER_POOL_ID } from '../../../common/constants';
import { AWS_REGION } from '../../../region-type';
import { getPhoneValidationSchema } from '../../../utils/phone-validation';
import { ManualAddressDialog, ManualAddressFormData } from '../../site/site-details/ManualAddressDialog';
import { addUserDetails, selectUser } from '../../user/userSlice';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '+61',
  businessName: '',
  address: '',
  city: '',
  state: '',
  postCode: '',
  country: '',
};

const schema = yup.object().shape({
  email: yup.string().email('*Invalid email format').trim().required('*Email is Required'),
  firstName: yup.string().trim().required('*First Name is required'),
  lastName: yup.string().trim().required('*Last Name is required'),
  phone: getPhoneValidationSchema(),
  businessName: yup.string().trim().required('*Business Name is required'),
  address: yup.string().trim().required('*Business Address is required'),
  city: yup.string().trim().required('*City is required'),
  state: yup.string().trim().required('*State is required'),
  postCode: yup.string().trim().required('*Post Code is required'),
  country: yup.string().trim().required('*Country is required'),
});

export function SignupForm() {
  const {
    register,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const [isFormFilled, setIsFormFilled] = useState(false);

  // Check if all fields are filled and enable signup button.
  // Using subscription so that we do not rerender whole component on value changes
  useEffect(() => {
    const subscription = watch((values) => {
      const isFormFilled = Object.values(values).every((value) => !!value);
      setIsFormFilled(isFormFilled);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [isLoading, setIsLoading] = useState(false);
  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);

  const { phoneInputClassName, predictionHoverColor, LogoIcon } = useColorModeValue(
    { phoneInputClassName: 'light-mode-phone-input', predictionHoverColor: 'gray.50', LogoIcon: Logo },
    {
      phoneInputClassName: 'dark-mode-phone-input dark-mode-phone-input-transparent-bg',
      predictionHoverColor: 'gray.700',
      LogoIcon: LogoDark,
    }
  );

  const {
    isOpen: isTermsAndConditionsModalOpen,
    onOpen: onOpenTermsAndConditionsModal,
    onClose: onCloseTermsAndConditionsModal,
  } = useDisclosure();
  const {
    isOpen: isManualAddressDialogOpen,
    onOpen: onOpenManualAddressDialog,
    onClose: onCloseManualAddressDialog,
  } = useDisclosure();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const user = useSelector(selectUser);

  useEffect(() => {
    // check if there is signup details in redux
    // this is required in the event where user
    // wants to change email when coming back from otp screen
    reset({
      email: user.user_email,
      firstName: user.user_first_name,
      lastName: user.user_last_name,
      businessName: user.tenant.tenant_name,
      address: user.tenant.address || '',
      city: user.tenant.city || '',
      state: user.tenant.state || '',
      postCode: user.tenant.zipcode || '',
      country: user.tenant.country || '',
      phone: user.user_phone || '+61',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: ['AU', 'NZ'] },
    } as any,
  });

  async function handleSubmit(values: typeof initialState) {
    const { email, phone, firstName, lastName } = values;
    const username = ulid();

    // Revert to SRP for sign up
    await Auth.configure({
      region: AWS_REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: CLIENT_ID,
      authenticationFlowType: 'USER_SRP_AUTH',
    });

    // generate random password
    const tempPassword = generator.generate({
      length: 12,
      numbers: true,
      symbols: true,
      uppercase: true,
      lowercase: true,
      strict: true,
    });

    setIsLoading(true);
    try {
      await Auth.signUp({
        username,
        password: tempPassword,
        attributes: {
          email: email.toLowerCase(),
        },
        clientMetadata: {
          user_first_name: firstName,
          user_last_name: lastName,
          user_phone: phone,
          tenant_name: values.businessName,
          address: values.address,
          city: values.city,
          country: values.country,
          logo_url: '',
          state: values.state,
          zipcode: values.postCode,
        },
      });

      dispatch(
        addUserDetails({
          ...values,
          user_email: email.toLowerCase(),
          user_first_name: values.firstName,
          user_last_name: values.lastName,
          user_name: username,
          user_phone: phone,
          tempPassword: tempPassword,
          tenant: {
            tenant_id: 0,
            tenant_name: values.businessName,
            address: values.address,
            city: values.city,
            country: values.country,
            logo_url: '',
            state: values.state,
            zipcode: values.postCode,
          },
        })
      );
      setIsLoading(false);
      navigate('/signup/account-activation-info');
    } catch (error) {
      const errorToastConfig = {
        title: 'Something went wrong!',
        description: 'Please contact us if this issue persists.',
      };
      if ((error as any)?.message.includes('Email or username already in use')) {
        errorToastConfig.title = 'Email already in use!';
        errorToastConfig.description = 'Please use a different email.';
      }
      toast({
        ...errorToastConfig,
        status: 'error',
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  async function handleSelectLocation(location: AutocompletePrediction) {
    const currentValues = getValues();

    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      // iterate over address components to populate location data
      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const termsLength = location.terms.length;

      reset({
        ...currentValues,
        address: location.description,
        city: locationData.city || location.terms[termsLength - 3]?.value,
        state: locationData.state || location.terms[termsLength - 2]?.value,
        country: locationData.country || location.terms[termsLength - 1]?.value,
        postCode: locationData.postCode,
      });

      setShouldDisplayAutocompleteOptions(false);
    });
  }

  function handleSubmitManualAddress({ country, streetAddress, unit, suburb, state, postcode }: ManualAddressFormData) {
    reset({
      ...getValues(),
      address: `${unit ? unit + '/' : ''}${streetAddress}, ${suburb}, ${state}`,
      city: suburb,
      state,
      country,
      postCode: postcode.toString(),
    });

    onCloseManualAddressDialog();
    setShouldDisplayAutocompleteOptions(false);
  }

  function PredictionsList() {
    return placePredictions.length ? (
      <Box width={'100%'} mt={2} py={3} data-testid="business-address-prediction-list">
        <Heading mb={1} size={'md'}>
          Results
        </Heading>
        {placePredictions.map((location, i) => (
          <Box
            data-testid={`business-address-prediction-${i}`}
            className={'business-address-prediction-item'}
            borderBottom={i === placePredictions.length - 1 ? '1px solid grey' : undefined}
            borderTop={'1px solid grey'}
            key={`google-maps-location-${i}`}
            _hover={{ background: predictionHoverColor }}
            onClick={async () => handleSelectLocation(location)}
            cursor={'pointer'}
            py={2}
          >
            <Flex align={'center'} as={'button'} type={'button'}>
              <Box mr={1}>
                <FaMapMarkerAlt />
              </Box>
              <Text fontWeight={600} fontSize={'sm'}>
                {location.description}
              </Text>
            </Flex>
          </Box>
        ))}
        <Text mt={2} fontSize={'xs'}>
          Powered by Google
        </Text>
      </Box>
    ) : null;
  }

  return (
    <>
      <Center flexDirection={'column'} textAlign={'center'} my={5}>
        <Box cursor={'pointer'} my={5} w={'60%'}>
          <LogoIcon />
        </Box>
        <Text fontSize={'md'} mt={4}>
          Let’s get started!
        </Text>
      </Center>

      <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'} data-testid="signup-form">
        <FormControl my={3} isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} type="email" placeholder="Enter Email" data-testid="email" />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Box my={3}>
          <FormLabel>Full Name</FormLabel>
          <Flex>
            <FormControl isInvalid={!!errors.firstName} pr={2}>
              <Input {...register('firstName')} placeholder="First" data-testid="first-name" />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.lastName} pl={2}>
              <Input {...register('lastName')} placeholder="Last" data-testid="last-name" />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </Box>

        <FormControl my={3} isInvalid={!!errors.phone}>
          <FormLabel>Phone</FormLabel>
          <Box className={phoneInputClassName} data-testid="phone">
            <PhoneInput
              isValid={!errors.phone}
              placeholder="Enter your number"
              country="au"
              autoFormat
              masks={{ au: '.... ... ...' }}
              onlyCountries={['au', 'nz']}
              countryCodeEditable={false}
              enableAreaCodeStretch
              value={getValues().phone}
              onEnterKeyPress={handleFormSubmit(handleSubmit)}
              onChange={(phone) => {
                setValue(`phone` as any, '+' + phone, {
                  shouldDirty: true,
                  shouldValidate: !!errors.phone,
                });
              }}
            />
          </Box>
          <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={!!errors.businessName}>
          <FormLabel>Business name</FormLabel>
          <Input {...register('businessName')} placeholder="Enter business name" data-testid="business-name" />
          <FormErrorMessage>{errors.businessName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.address} mt={4}>
          <FormLabel>Business address</FormLabel>
          <InputGroup>
            <Input
              {...register('address')}
              onChange={(e) => {
                getPlacePredictions({ input: e.currentTarget.value });
                setValue('address', e.currentTarget.value);
                setShouldDisplayAutocompleteOptions(true);
              }}
              placeholder={'12/22 George Street, Sydney, NSW'}
              type="text"
              data-private
              autoComplete="off"
              data-testid="business-address"
            />
            <InputRightElement>
              <AddressPinIcon w={5} h={5} color="customBlue.500" />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
        </FormControl>

        <Box
          data-testid="manual-address-button"
          onClick={onOpenManualAddressDialog}
          as="button"
          type="button"
          mt={1}
          fontSize="md"
          color="customBlue.500"
        >
          Enter address manually
        </Box>

        {/* Site address autocomplete */}
        <Box w={'100%'}>
          {!isPlacePredictionsLoading ? (
            shouldDisplayAutocompleteOptions && <PredictionsList />
          ) : (
            <Flex direction={'column'} justify={'center'} align={'center'}>
              <Spinner size={'lg'} />
              <Text fontSize={'sm'}>Loading suggestions...</Text>
            </Flex>
          )}
        </Box>

        <Text fontSize={'sm'} my={8}>
          By signing up here, you accept the Clipsal Cortex
          <Box
            mx={1}
            cursor={'pointer'}
            _hover={{ textDecoration: 'underline' }}
            onClick={onOpenTermsAndConditionsModal}
            as={'span'}
            color="customLinkBlue.500"
            data-testid="open-terms-and-conditions-button"
          >
            Terms and Conditions
          </Box>
          and
          <Box
            mx={1}
            cursor={'pointer'}
            _hover={{ textDecoration: 'underline' }}
            onClick={() => Browser.open({ url: PRIVACY_POLICY_URL })}
            as={'span'}
            color="customLinkBlue.500"
          >
            Privacy Policy
          </Box>
        </Text>

        <CustomButton
          data-testid="signup-button"
          isLoading={isLoading}
          isDisabled={!isFormFilled}
          loadingText={'Signing up...'}
          w="100%"
          rounded={3}
          py={6}
        >
          Signup
        </CustomButton>

        <Center mt={6}>
          <Text fontSize={'sm'}>
            Already have an account?
            <Box
              cursor={'pointer'}
              _hover={{ textDecoration: 'underline' }}
              onClick={() => navigate('/login')}
              as={'span'}
              fontWeight={'bold'}
              color="customLinkBlue.500"
              ml={1}
            >
              Sign in
            </Box>
          </Text>
        </Center>
      </Box>

      {isTermsAndConditionsModalOpen && (
        <TermsAndConditionsModal isOpen={isTermsAndConditionsModalOpen} onClose={onCloseTermsAndConditionsModal} />
      )}

      {isManualAddressDialogOpen && (
        <ManualAddressDialog
          currentValues={{ country: getValues().country, address: getValues().address, postCode: getValues().postCode }}
          isOpen={isManualAddressDialogOpen}
          onClose={onCloseManualAddressDialog}
          onSubmitManualAddress={handleSubmitManualAddress}
        />
      )}
    </>
  );
}
