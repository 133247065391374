import React, { useEffect } from 'react';
import {
  Box,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useBreakpointValue,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  AUSTRALIAN_STATES_SELECT_OPTIONS,
  NEW_ZEALAND_REGIONS_SELECT_OPTIONS,
  US_STATES_SELECT_OPTIONS,
} from 'clipsal-cortex-utils/src/constants/timezone-states';

import { CustomButton } from '../../../common/components/CustomButton';
import { ALLOWED_COUNTRIES_SELECT_OPTIONS } from '../../../common/constants';
import { ModalProps } from '../../../common/types/types';

const schema = yup.object().shape({
  country: yup.string().not(['0'], 'This field is required').required('This field is required'),
  streetAddress: yup.string().required('This field is required'),
  suburb: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field'),
  state: yup.string().not(['0'], 'This field is required').required('This field is required'),
  postcode: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .when('country', {
      is: ['Australia', 'New Zealand'],
      then: yup.string().min(4, 'Must be exactly 4 digits').max(4, 'Must be exactly 4 digits'),
    })
    .when('country', {
      is: 'United States',
      then: yup.string().min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
    })
    .required('This field is required'),
});

export type ManualAddressFormData = {
  country: string;
  streetAddress: string;
  unit: string;
  suburb: string;
  state: string;
  postcode: string;
};

export type CurrentValuesData = {
  country: string;
  address: string;
  postCode: string;
};

type Props = {
  onSubmitManualAddress: (formValues: ManualAddressFormData) => void;
  currentValues: CurrentValuesData;
};

export function ManualAddressDialog(props: Props & ModalProps) {
  const isDesktopViewport = useBreakpointValue({
    base: false,
    lg: true,
  });

  return isDesktopViewport ? <ManualAddressModal {...props} /> : <ManualAddressBottomDrawer {...props} />;
}

function ManualAddressBottomDrawer({ onSubmitManualAddress, onClose, currentValues }: Props & ModalProps) {
  return (
    <Drawer onClose={onClose} placement="bottom" isOpen id="manualAddressDrawer">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Enter Address Manually</DrawerHeader>
        <DrawerBody>
          <Flex direction={'column'} justify={'center'}>
            <ManualAddressForm currentValues={currentValues} onSubmitManualAddress={onSubmitManualAddress} />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function ManualAddressModal({ onSubmitManualAddress, onClose, currentValues }: Props & ModalProps) {
  return (
    <Modal isCentered onClose={onClose} isOpen>
      <ModalOverlay />
      <ModalContent rounded={20} data-testid="pushNotificationsModal">
        <ModalCloseButton data-testid="modal-close-button" />
        <ModalHeader>Enter Address Manually</ModalHeader>
        <ModalBody>
          <ManualAddressForm currentValues={currentValues} onSubmitManualAddress={onSubmitManualAddress} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const COUNTRY_TO_STATE_OPTIONS: Record<string, { label: string; value: string }[]> = {
  'United States': US_STATES_SELECT_OPTIONS,
  Australia: AUSTRALIAN_STATES_SELECT_OPTIONS,
  'New Zealand': NEW_ZEALAND_REGIONS_SELECT_OPTIONS,
};

function ManualAddressForm({ onSubmitManualAddress, currentValues }: Props) {
  const {
    register,
    watch,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ManualAddressFormData>({
    resolver: yupResolver(schema),
  });
  const countryRef = register('country');
  const stateRef = register('state');
  const [streetAddressValue, countryValue] = watch(['streetAddress', 'country']);

  useEffect(() => {
    const values = {
      ...getValues(),
      country: currentValues.country,
    };

    // They might be modifying an existing manual address
    if (currentValues.address && currentValues.address.split(', ').length === 3) {
      const [streetAddress, suburb, state] = currentValues.address.split(', ');

      if (streetAddress.split('/').length === 2) {
        const [address, unit] = streetAddress.split('/');
        values.streetAddress = address;
        values.unit = unit;
      } else {
        values.streetAddress = streetAddress;
      }

      values.suburb = suburb;
      values.state = state;
      values.postcode = currentValues.postCode;
    }

    reset(values);
  }, [currentValues, reset, getValues]);

  return (
    <Box data-testid="manual-address-form" as="form" onSubmit={handleSubmit(onSubmitManualAddress)}>
      <FormControl isInvalid={!!errors.country} id={'country'}>
        <FormLabel>Country</FormLabel>
        <Select
          data-private
          data-testid="site-country"
          name={countryRef.name}
          onBlur={countryRef.onBlur}
          ref={countryRef.ref}
          onChange={countryRef.onChange}
          defaultValue="Australia"
          id="country"
        >
          <option value={''}>Select a country...</option>
          {ALLOWED_COUNTRIES_SELECT_OPTIONS.map(({ label, value }, i) => (
            <option key={`country-option-${i}`} value={value}>
              {label}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
      </FormControl>

      <FormControl my={3} isInvalid={!!errors.streetAddress} id="streetAddress">
        <FormLabel>Street Address</FormLabel>
        <Input
          data-private
          data-testid="site-street-address"
          placeholder="12 George Street"
          {...register('streetAddress')}
          type="text"
        />
        <FormErrorMessage>{errors?.streetAddress?.message}</FormErrorMessage>
      </FormControl>

      <Collapse in={!!countryValue && countryValue !== '0' && !!streetAddressValue}>
        <FormControl my={3} id="name">
          <FormLabel>Unit (Optional)</FormLabel>
          <Input data-private data-testid="site-unit" placeholder="11" {...register('unit')} type="text" />
        </FormControl>

        <FormControl my={3} isInvalid={!!errors.suburb} id="streetAddress">
          <FormLabel>Suburb</FormLabel>
          <Input data-private data-testid="site-suburb" placeholder="Haymarket" {...register('suburb')} type="text" />
          <FormErrorMessage>{errors?.suburb?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.state} id={'state'}>
          <FormLabel>{countryValue === 'New Zealand' ? 'City/Town' : 'State'}</FormLabel>
          <Select
            data-private
            data-testid="site-state"
            name={stateRef.name}
            onBlur={stateRef.onBlur}
            ref={stateRef.ref}
            onChange={stateRef.onChange}
            id="state"
          >
            <option value={0}>Select a state...</option>
            {COUNTRY_TO_STATE_OPTIONS?.[countryValue]?.map(({ label, value }, i) => (
              <option key={`state-option-${i}`} value={value}>
                {label}
              </option>
            )) ?? []}
          </Select>
          <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
        </FormControl>

        <FormControl my={3} isInvalid={!!errors.postcode} id="postcode">
          <FormLabel>{countryValue === 'United States' ? 'Zip code' : 'Postcode'}</FormLabel>
          <Input data-private data-testid="site-postcode" placeholder="2000" {...register('postcode')} type="number" />
          <FormErrorMessage>{errors?.postcode?.message}</FormErrorMessage>
        </FormControl>
      </Collapse>

      <CustomButton w="50%" isLoading={isSubmitting} data-testid="manual-address-submit-button">
        Submit
      </CustomButton>
    </Box>
  );
}
