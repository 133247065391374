import React from 'react';
import { Box, Icon, useColorModeValue } from '@chakra-ui/react';
import { IoWarningOutline } from 'react-icons/io5';

import { OperatingStatus } from '../../api/api-site-alerts';

type SiteAlertIconProps = { operatingStatus: OperatingStatus };

export const SiteAlertIcon = ({ operatingStatus }: SiteAlertIconProps) => {
  const isNormalOperatingStatus = operatingStatus === 'NORMAL';
  const iconColor = useColorModeValue('dusk100.500', 'white');
  return (
    <Box position="relative">
      <Icon as={IoWarningOutline} color={iconColor} h={5} w={5} />
      <Box
        as={'span'}
        color={'white'}
        position={'absolute'}
        top={0}
        right={'-2px'}
        fontSize={'8px'}
        zIndex={9}
        p={'1px'}
        w={3}
        h={3}
      >
        <Box bgColor={isNormalOperatingStatus ? 'primaryBranding.500' : 'customRed.500'} borderRadius={25}>
          {isNormalOperatingStatus ? '✓' : '!'}
        </Box>
      </Box>
    </Box>
  );
};
