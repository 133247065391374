import React, { useMemo } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';

import { Card } from 'clipsal-cortex-ui/src/components/card/Card';

import { MACQUARIE_TENANT_ID } from '../../../common/constants';
import { BatteryUpsellWidget } from './BatteryUpsellWidget';
import { EnvironmentalImpactWidget } from './EnvironmentalImpactWidget';
import { SitesWidget } from './SitesWidget';
import { SystemHealthStatusWidget } from './SystemHealthStatusWidget';

type FleetDashboardWidgetsProps = {
  selectedTenantId: number;
};

export const FleetDashboardWidgets = ({ selectedTenantId }: FleetDashboardWidgetsProps) => {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  const widgetList = useMemo(() => {
    const widgets: JSX.Element[] = [];

    // The widget list is ordered, so pushed widgets will be displayed in the order they are pushed
    widgets.push(<SystemHealthStatusWidget selectedTenantId={selectedTenantId} />);

    // Only display the widget to macquarie tenants
    if (selectedTenantId === MACQUARIE_TENANT_ID) widgets.push(<EnvironmentalImpactWidget {...{ selectedTenantId }} />);

    // Always shown
    widgets.push(<SitesWidget selectedTenantId={selectedTenantId} />);

    widgets.push(<BatteryUpsellWidget />);

    return widgets;
  }, [selectedTenantId]);

  return (
    <Flex wrap="wrap" data-testid="fleet-dashboard-widget-container">
      {widgetList.map((widget, idx) => {
        const totalWidgets = widgetList.length;
        const isFirstIndex = idx === 0;
        const desktopMarginLeft = isFirstIndex ? 0 : '1%';
        // subtracting 1% for margin left between widgets and
        // adding one as first one does not have margin
        const widthInPercent = (100 - totalWidgets + 1) / totalWidgets;
        return (
          <Card
            key={`fleet-dashboard-widget-${idx}`}
            w={isMobileViewport ? '100%' : `${widthInPercent}%`}
            mt={2}
            data-testid={`fleet-dashboard-widget-${idx}`}
            ml={isMobileViewport ? 0 : desktopMarginLeft}
            overflow="hidden"
            position="relative"
            minHeight="300px"
            maxWidth={isMobileViewport ? 'auto' : '500px'}
          >
            {widget}
          </Card>
        );
      })}
    </Flex>
  );
};
