import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { SearchableDropdown } from '../../../../../common/components/SearchableDropdown';
import { selectAssignments } from '../../../system-details/systemDetailsSlice';

export type SelectSortedAssignmentsProps = {
  value: string;
  onChange: (newValue: string) => void;
  dataTestId: string;
};

export function SelectSortedAssignments({ value, onChange, dataTestId }: SelectSortedAssignmentsProps) {
  const assignments = useSelector(selectAssignments);

  const assignmentOptions = useMemo(
    () =>
      assignments.map((assignment) => ({
        value: assignment.assignment,
        label: assignment.display_name,
      })),
    [assignments]
  );

  return (
    <Box data-testid={dataTestId} minW={200} w="100%" zIndex={9999}>
      <SearchableDropdown
        options={assignmentOptions}
        onChange={(newValue) => onChange(newValue.toString())}
        value={value}
        placeholder="Select monitor"
      />
    </Box>
  );
}
