import 'react-phone-input-2/lib/bootstrap.css';
import '../../styles/react-phone-input-2.css';

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { patch } from '../../api/api-helpers';
import { useAppDispatch } from '../../app/hooks';
import { ReactComponent as LogoDark } from '../../assets/images/clipsal_logo_dark.svg';
import { ReactComponent as Logo } from '../../assets/images/clipsal_logo.svg';
import { CustomButton } from '../../common/components/CustomButton';
import { ModalProps } from '../../common/types/types';
import { getPhoneValidationSchema } from '../../utils/phone-validation';
import { fetchUserDetails, selectUser } from './userSlice';

const initialState = {
  firstName: '',
  lastName: '',
  phone: '',
};

const schema = yup.object().shape({
  firstName: yup.string().trim().required('*First Name is required'),
  lastName: yup.string().trim().required('*Last Address is required'),
  phone: getPhoneValidationSchema(),
});

export const UserDetailsModal = ({ isOpen, onClose }: ModalProps) => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast({ status: 'error', isClosable: true });
  const dispatch = useAppDispatch();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const user = useSelector(selectUser);

  // Check if all fields are filled and enable submit button.
  // Using subscription so that we do not rerender whole component on value changes
  useEffect(() => {
    const subscription = watch((values) => {
      const isFormFilled = Object.values(values).every((value) => !!value);
      setIsFormFilled(isFormFilled);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { phoneInputClassName, LogoIcon } = useColorModeValue(
    { phoneInputClassName: 'light-mode-phone-input', LogoIcon: Logo },
    {
      phoneInputClassName: 'dark-mode-phone-input dark-mode-phone-input-transparent-bg',
      LogoIcon: LogoDark,
    }
  );

  async function handleSubmit({ firstName, lastName, phone }: typeof initialState) {
    setIsLoading(true);

    try {
      await patch(`/fleet/v2/users/${user.user_id}`, {
        user_first_name: firstName,
        user_last_name: lastName,
        user_phone: phone,
      });

      await dispatch(fetchUserDetails());
      setIsLoading(false);
      toast({
        title: "You're good to go!",
        status: 'success',
      });
      onClose();
      navigate('/');
    } catch (_) {
      toast({
        title: 'Something went wrong!',
        description: 'Please try again. If this issue persists, please contact support.',
      });
      setIsLoading(false);
    }
  }

  return (
    <Modal size={'sm'} isOpen={isOpen} onClose={() => undefined} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent data-testid="userDetailsModalBody" mx={5}>
        <ModalHeader>
          <Flex direction={'column'} align="center">
            <Box cursor={'pointer'} my={5} w={'60%'}>
              <LogoIcon />
            </Box>
            <Text fontSize={'md'}>Let’s get started!</Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'} data-testid="user-details-form">
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel>First Name</FormLabel>
              <Input {...register('firstName')} placeholder="Enter first name" data-testid="first-name" />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl my={3} isInvalid={!!errors.lastName}>
              <FormLabel>Last Name</FormLabel>
              <Input {...register('lastName')} placeholder="Enter first name" data-testid="last-name" />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phone}>
              <FormLabel>Phone</FormLabel>
              <Box className={phoneInputClassName} data-testid="phone">
                <PhoneInput
                  isValid={!errors.phone}
                  placeholder="Enter your number"
                  country="au"
                  autoFormat
                  masks={{ au: '.... ... ...' }}
                  onlyCountries={['au', 'nz']}
                  countryCodeEditable={false}
                  enableAreaCodeStretch
                  value={getValues().phone}
                  onEnterKeyPress={handleFormSubmit(handleSubmit)}
                  onChange={(phone: string) => {
                    setValue('phone', '+' + phone, {
                      shouldDirty: true,
                      shouldValidate: !!errors.phone,
                    });
                  }}
                />
              </Box>
              <FormErrorMessage data-testid="phone-error">{errors.phone?.message}</FormErrorMessage>
            </FormControl>

            <CustomButton
              data-testid="submit-user-details"
              isLoading={isLoading}
              loadingText={'Submitting...'}
              w="100%"
              maxW={320}
              rounded={3}
              py={6}
              mt={4}
              isDisabled={!isFormFilled}
            >
              Submit
            </CustomButton>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
