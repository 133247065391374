import React, { useEffect, useMemo, useState } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FieldArrayWithId, UseFieldArrayAppend, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { TESLA_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { InstalledDevice } from '../../../api/api-device';
import { del } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import { Counter } from '../../../common/components/Counter';
import { selectSite } from '../siteSlice';
import { MissingModelDrawer } from './MissingModel';
import { ModelManufacturerFieldPair } from './ModelManufacturerFieldPair';
import { CommonFieldListProps, SystemDetailsFormData } from './system-details-form-types';
import { EMPTY_DEVICE_TEMPLATE } from './system-details-helpers';
import { removeBattery } from './systemDetailsSlice';

export type BatteryFieldArrayProps = {
  batteryFields: FieldArrayWithId<SystemDetailsFormData, 'batteries', 'id'>[];
  appendBattery: UseFieldArrayAppend<SystemDetailsFormData, 'batteries'>;
  removeBattery: (index?: number | number[] | undefined) => void;
};

type BatteryFormProps = CommonFieldListProps & BatteryFieldArrayProps;

export function BatteryForm(props: BatteryFormProps) {
  const {
    control,
    register,
    setValue,
    getValues,
    errors,
    clearErrors,
    batteryFields: fields,
    appendBattery: append,
    removeBattery: remove,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasBattery, inverters } = useWatch({ control });
  const site = useSelector(selectSite);
  const [currentlyEditingBatteryIndex, setCurrentlyEditingBatteryIndex] = useState(-1);
  const counterValue = hasBattery ? fields.length : 0;
  const isRemovingLastBattery = fields.length === 1;
  const dispatch = useAppDispatch();
  const dividerColor = useColorModeValue('#0000001A', 'dusk100.400');
  const totalDCCoupledInverters = useMemo(
    () =>
      inverters?.reduce((acc, inverter) => {
        if (inverter.hasDCCoupledBattery) acc += 1;
        return acc;
      }, 0) || 0,
    [inverters]
  );

  const batteryError = (errors?.batteries as any)?.message;

  useEffect(() => {
    if (batteryError && fields.length >= totalDCCoupledInverters) clearErrors(`batteries`);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length, totalDCCoupledInverters]);

  async function handleDeleteBattery(batteryId: number | null, inverterIndex: number) {
    if (window.confirm('Are you sure you want to delete this battery?')) {
      if (batteryId) {
        await del<InstalledDevice>(`/fleet/sites/${site.clipsal_solar_id}/devices/${batteryId}`, {});
        dispatch(removeBattery(batteryId));
      }

      remove(inverterIndex);
    }
  }

  const handleAddBattery = () => {
    if (!hasBattery) setValue('hasBattery', true);

    append(EMPTY_DEVICE_TEMPLATE);
  };

  const handleRemoveBattery = async (batteryIndex: number) => {
    const { deviceId } = fields[batteryIndex];

    if (isRemovingLastBattery) setValue('hasBattery', false);

    await handleDeleteBattery(deviceId, batteryIndex);
  };

  return (
    <Box>
      <Flex align="center">
        <Heading size={'lg'} mb={2} ml={[4, 6]}>
          Battery
        </Heading>
        {batteryError && <WarningIcon color={'red.500'} w={5} h={5} ml={2} />}
      </Flex>
      {batteryError && (
        <Text color="red.500" mb={2}>
          Please add at least {totalDCCoupledInverters} {totalDCCoupledInverters > 1 ? 'batteries' : 'battery'}
        </Text>
      )}
      <Divider color={dividerColor} />
      <Flex align="center" justify="space-between" py={3}>
        <Input type="hidden" {...register('hasBattery' as const)} />
        <Text pl={3} pr={[8, 4]}>
          How many batteries do you want to add in this site?
        </Text>
        <Counter
          dataTestId="add-remove-battery-counter"
          value={counterValue}
          incrementAriaLabel="Add Battery"
          decrementAriaLabel="Remove Battery"
          onIncrement={handleAddBattery}
          onDecrement={() => handleRemoveBattery(fields.length - 1)}
          isDecrementDisabled={fields.length === 0 || !hasBattery}
        />
      </Flex>

      <Collapse in={hasBattery}>
        <Accordion defaultIndex={0} allowToggle>
          {fields.map((field, index) => (
            <AccordionItem data-testid="battery-form" key={`${field.id}-${index}`}>
              <AccordionButton data-testid={`battery-${index}`} role={'button'} as={Box}>
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Heading size={'md'}>Battery {index + 1}</Heading>
                  <Flex align={'center'}>
                    {/* Display an error notification post-submission on this accordion button. */}
                    {!!errors?.batteries?.[index] && <WarningIcon mr={2} color={'red.500'} w={5} h={5} />}

                    <Button
                      data-testid={`battery-${index}-removeBtn`}
                      variant={'ghost'}
                      onClick={() => handleRemoveBattery(index)}
                      size={'xs'}
                      ml={2}
                      colorScheme="red"
                      aria-label="Delete battery"
                    >
                      Remove
                    </Button>
                    <AccordionIcon />
                  </Flex>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4} px={[4, 6]}>
                <ModelManufacturerFieldPair
                  {...{ ...props, index, field, deviceType: 'BATTERY_PACK', fieldKey: 'batteries' }}
                />
                <Text
                  onClick={() => {
                    setCurrentlyEditingBatteryIndex(index);
                    onOpen();
                  }}
                  cursor={'pointer'}
                  fontWeight={'bold'}
                  textDecoration={'underline'}
                  mt={2}
                  fontSize={'sm'}
                  color={'customLinkBlue.500'}
                  data-testid={`missing-battery-model-button-${index}`}
                >
                  Can't find your battery?
                </Text>
                {/* Only required for tesla powerwall */}
                {getValues().batteries?.[index]?.manufacturer === TESLA_MANUFACTURER_ID && (
                  <FormControl my={3} isInvalid={!!errors?.batteries?.[index]?.serialNumber}>
                    <FormLabel>Gateway Serial Number</FormLabel>
                    <Input
                      placeholder={'######'}
                      {...register(`batteries.${index}.serialNumber` as const)}
                      type="text"
                    />
                    <FormErrorMessage>{errors?.batteries?.[index]?.serialNumber?.message}</FormErrorMessage>
                  </FormControl>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Collapse>

      <MissingModelDrawer
        index={currentlyEditingBatteryIndex}
        setValue={setValue}
        isOpen={isOpen}
        onClose={onClose}
        modelType={'BATTERY_PACK'}
      />
    </Box>
  );
}
