import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';

interface DraggableCircuitProps {
  id: string;
  name: string;
}

export function DraggableCircuit({ id, name }: DraggableCircuitProps) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });
  const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Box
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      py={2}
      px={isMobileViewport ? 4 : 6}
      fontWeight={500}
      bg="gray.200"
      color={'gray.700'}
      _dark={{
        bg: 'gray.700',
        color: 'gray.200',
      }}
      border={'1px dashed'}
      borderColor={'transparent'}
      borderRadius="full"
      w="fit-content"
      minW="fit-content"
      zIndex={2}
      transform={transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined}
      data-testid={`draggable-circuit-${id}`}
    >
      {name}
    </Box>
  );
}
