import React from 'react';
import { PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';

import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { BatteryUpgradeOpportunity } from '../../api/api-battery-upsell';
import { EmailIcon } from '../../styles/custom-icons';
import { useSiteUsers } from './batteryUpsellApi';

type ContactCustomerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  site: BatteryUpgradeOpportunity;
};

export const ContactCustomerModal = ({ isOpen, onClose, site }: ContactCustomerModalProps) => {
  const { users, isLoading } = useSiteUsers(site.clipsal_solar_id);
  const toast = useToast({ isClosable: true });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4} data-testid="contact-customer-modal">
        <ModalHeader fontWeight={700} px={4}>
          <Text>Contact Customers</Text>
          <Text noOfLines={1} fontSize={'sm'} fontWeight={400} color="gray">
            Site Name: {site.site_name}
          </Text>
        </ModalHeader>
        <Divider borderWidth={1} />
        <ModalCloseButton data-testid="close-contact-customer-modal" />
        <ModalBody px={2}>
          {isLoading ? (
            <CenteredLoader p={2} color="gray" />
          ) : (
            <Box>
              {users.map(({ user }, index) => {
                const isLastIndex = index === users.length - 1;
                return (
                  <Box key={`user-contact-details-${index}`} data-testid={`user-contact-details-${index}`}>
                    <Center justifyContent="space-between" p={2}>
                      <Box>
                        <Text fontWeight={500}>{user.user_full_name}</Text>
                        <Text color="gray" noOfLines={1}>
                          {user.user_email}
                        </Text>
                        <Text color="gray" noOfLines={1}>
                          {user.user_phone}
                        </Text>
                      </Box>

                      <Center>
                        <Link href={`mailto:${user.user_email}`}>
                          <IconButton
                            p={1.5}
                            data-testid={`user-contact-email-icon-${index}`}
                            aria-label="Email Customer"
                            icon={<EmailIcon />}
                            onClick={() => {
                              navigator.clipboard.writeText(user.user_email);
                              toast({ title: 'Email copied to clipboard!' });
                            }}
                          />
                        </Link>
                        <Link href={`tel:${user.user_phone}`} ml={2}>
                          <IconButton
                            p={1.5}
                            data-testid={`user-contact-phone-icon-${index}`}
                            aria-label="Call Customer"
                            icon={<PhoneIcon />}
                            onClick={() => {
                              navigator.clipboard.writeText(user.user_phone);
                              toast({ title: 'Phone number copied to clipboard!' });
                            }}
                          />
                        </Link>
                      </Center>
                    </Center>
                    {!isLastIndex && <Divider borderWidth={1} />}
                  </Box>
                );
              })}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
