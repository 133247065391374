import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Appliance } from '../../../../api/api-appliance';
import { Circuit } from '../../../../api/api-device';
import { RootState } from '../../../../app/store';

export const NEW_APPLIANCE: Appliance = {
  appliance_id: 0,
  appliance_name: 'New Appliance',
  appliance_type: '',
  control_device_id: null,
  circuits: [],
};

export type ApplianceDisplayConfig = Omit<Appliance, 'circuits'> & {
  circuits: Circuit[];
};

export type CircuitConfigState = {
  selectedMeterId: string;
  allCircuits: Circuit[];
  allAppliances: Appliance[];
};

export const initialState: CircuitConfigState = {
  selectedMeterId: '',
  allCircuits: [],
  allAppliances: [],
};

export const circuitApplianceConfigSlice = createSlice({
  name: 'circuitApplianceConfig',
  initialState,
  reducers: {
    setSelectedMeterId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        selectedMeterId: action.payload,
      };
    },
    setAllCircuits: (state, action: PayloadAction<Circuit[]>) => {
      return { ...state, allCircuits: action.payload };
    },
    setAllAppliances: (state, action: PayloadAction<Appliance[]>) => {
      return { ...state, allAppliances: action.payload };
    },
    addAppliance: (
      state,
      action: PayloadAction<{
        name: string;
        type: string;
      }>
    ) => {
      // create appliance id in negative to avoid conflict with existing appliance ids
      // we can't use null because we need to identify the appliance in the UI
      const totalNegativeApplianceIds = state.allAppliances.filter(
        (applianceDisplayConfig) => applianceDisplayConfig.appliance_id < 0
      ).length;
      const newApplianceId = -1 * (totalNegativeApplianceIds + 1);
      return {
        ...state,
        allAppliances: [
          {
            ...NEW_APPLIANCE,
            appliance_id: newApplianceId,
            appliance_name: action.payload.name,
            appliance_type: action.payload.type,
          },
          ...state.allAppliances,
        ],
      };
    },
    removeAppliance: (state, action: PayloadAction<number>) => {
      // Remove the appliance and set circuits of the removed appliance to null
      return {
        ...state,
        allAppliances: state.allAppliances.filter((appliance) => appliance.appliance_id !== action.payload),
        allCircuits: state.allCircuits.map((circuit) =>
          circuit.appliance_id === action.payload ? { ...circuit, appliance_id: null } : circuit
        ),
      };
    },
    updateApplianceName: (state, action: PayloadAction<{ applianceId: number; name: string }>) => {
      return {
        ...state,
        allAppliances: state.allAppliances.map((appliance) =>
          appliance.appliance_id === action.payload.applianceId
            ? { ...appliance, appliance_name: action.payload.name }
            : appliance
        ),
      };
    },
  },
});

export const {
  setSelectedMeterId,
  setAllCircuits,
  setAllAppliances,
  addAppliance,
  removeAppliance,
  updateApplianceName,
} = circuitApplianceConfigSlice.actions;

export const selectSelectedMeterId = (state: RootState) => state.circuitApplianceConfig.selectedMeterId;
export const selectAllCircuits = (state: RootState) => state.circuitApplianceConfig.allCircuits;
export const selectAllAppliances = (state: RootState) => state.circuitApplianceConfig.allAppliances;

export const { reducer: circuitApplianceConfigReducer } = circuitApplianceConfigSlice;
