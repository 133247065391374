import React, { useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { toZonedTime } from 'date-fns-tz';
import { FiRefreshCw } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

import { useAppSelector } from '../../../../app/hooks';
import { CustomDatePicker } from '../../../../common/components/date-picker/CustomDatePicker';
import { MultiToggleSwitch } from '../../../../common/components/MultiToggleSwitch';
import { IntervalType } from '../../../user/account/meter-configuration/meter-chart/MeterConfigurationChart';
import {
  LONG_DATA_TYPE_TO_TITLE_MAP,
  SHORT_DATA_TYPE_TO_TITLE_MAP,
} from '../../../user/account/meter-configuration/meter-config-constants';
import { LongPowerDataType, ShortPowerDataType } from '../../../user/account/meter-configuration/meter-config-types';
import { selectSite } from '../../siteSlice';
import { selectMeterConfiguration, updateMeterChartConfig } from './meterConfigurationSlice';

type MeterConfigurationChartSettingsProps = {
  isFetching: boolean;
  onRefetch: () => void;
  latestCommunicatedTime: string;
};

export function MeterConfigurationChartSettings({
  isFetching,
  onRefetch,
  latestCommunicatedTime,
}: MeterConfigurationChartSettingsProps) {
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const dispatch = useDispatch();
  const switchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch-2');
  const { timezone } = useAppSelector(selectSite);
  const { selectedInterval, selectedShortPowerDataType, selectedLongPowerDataType, selectedDate } =
    useAppSelector(selectMeterConfiguration).meterChartConfig;
  const checkDateBeforeToday = useCallback((date: Date) => date <= toZonedTime(new Date(), timezone), []);
  const isShortEnergySelected = selectedInterval === 'Short Energy';

  return (
    <Box>
      <HStack w="100%" justify={'space-between'} mb={4}>
        <HStack>
          <VStack spacing={0} align="flex-start">
            <Text fontSize={14}>Last Updated at</Text>
            <Text fontSize={14} fontWeight={500} color="black" _dark={{ color: 'white' }}>
              {latestCommunicatedTime}
            </Text>
          </VStack>
        </HStack>
        <Box className={switchClassName}>
          <MultiToggleSwitch<IntervalType>
            switchOptions={[
              { label: 'Short', value: 'Short Energy' },
              { label: 'Long', value: 'Long Energy' },
            ]}
            fontSize="xs"
            value={selectedInterval}
            onChange={(newInterval) =>
              dispatch(
                updateMeterChartConfig({
                  selectedInterval: newInterval,
                })
              )
            }
            label=""
            customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
            data-testid="interval-toggle"
          />
        </Box>
      </HStack>
      <HStack
        overflowX={'scroll'}
        my={2}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&': {
            msOverflowStyle: 'none', // Hide scrollbar on IE and Edge
            scrollbarWidth: 'none', // Hide scrollbar on Firefox
          },
        }}
      >
        {Object.entries(isShortEnergySelected ? SHORT_DATA_TYPE_TO_TITLE_MAP : LONG_DATA_TYPE_TO_TITLE_MAP).map(
          ([type, label]) => {
            const isSelected = isShortEnergySelected
              ? selectedShortPowerDataType === type
              : selectedLongPowerDataType === type;
            return (
              <Button
                key={type}
                data-testid={type}
                data-is-selected={isSelected}
                borderBottom={'3px solid'}
                borderColor={isSelected ? 'black' : 'transparent'}
                _dark={{ borderColor: isSelected ? 'white' : 'transparent' }}
                borderBottomRadius={0}
                minW="fit-content"
                variant="ghost"
                fontWeight={isSelected ? 500 : 400}
                onClick={() => {
                  dispatch(
                    updateMeterChartConfig({
                      selectedShortPowerDataType: isShortEnergySelected
                        ? (type as ShortPowerDataType)
                        : selectedShortPowerDataType,
                      selectedLongPowerDataType: !isShortEnergySelected
                        ? (type as LongPowerDataType)
                        : selectedLongPowerDataType,
                    })
                  );
                }}
              >
                {label}
              </Button>
            );
          }
        )}
      </HStack>
      {!isShortEnergySelected && (
        <Flex direction="column" mt={4}>
          <Flex align="center">
            <IconButton
              data-testid={'prev-date'}
              size={'md'}
              rounded={20}
              mr={2}
              isDisabled={isFetching}
              variant={'ghost'}
              aria-label="Previous"
              icon={<ChevronLeftIcon h={5} w={5} />}
              onClick={() => {
                const date = new Date(selectedDate.getTime());
                date.setDate(date.getDate() - 1);

                dispatch(
                  updateMeterChartConfig({
                    selectedDate: date,
                  })
                );
              }}
            />
            <CustomDatePicker
              maxDate={new Date()}
              inputProps={{
                'data-testid': 'meter-config-date-picker',
                placeholder: 'dd/mm/yyyy',
                minW: 150,
              }}
              disabled={isFetching}
              popperPlacement="top-start"
              dateFormat="dd/MM/yyyy"
              selected={selectedDate}
              onChange={(e) => {
                dispatch(
                  updateMeterChartConfig({
                    selectedDate: new Date((e as Date).getTime()),
                  })
                );
              }}
            />
            <IconButton
              data-testid={'next-date'}
              size={'md'}
              rounded={20}
              mr={2}
              variant={'ghost'}
              aria-label="Previous"
              icon={<ChevronRightIcon h={5} w={5} />}
              isDisabled={isFetching || formatDate(selectedDate) === formatDate(toZonedTime(new Date(), timezone))}
              onClick={() => {
                const date = new Date(selectedDate.getTime());

                // Can't go into the future
                if (!checkDateBeforeToday(date)) return;

                date.setDate(date.getDate() + 1);

                dispatch(
                  updateMeterChartConfig({
                    selectedDate: date,
                  })
                );
              }}
            />

            <IconButton
              data-testid={'refresh-data'}
              size={'md'}
              rounded={20}
              mr={2}
              aria-label="Previous"
              isLoading={isFetching}
              icon={<FiRefreshCw size={14} />}
              onClick={onRefetch}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
