import React, { useMemo, useState } from 'react';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  InputGroup,
  InputLeftAddon,
  LayoutProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import {
  Column,
  HeaderGroup,
  TableBodyProps as ReactTableBodyProps,
  Row,
  TableBodyPropGetter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { debounceEvent } from '../../utils/component-helpers';

export type FetchDataArgs = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: string;
  sortOrder?: string;
};

interface Props<T extends object = any> {
  data: T[];
  columns: Column[];
  onClickRow?: (row: T) => void;
  enablePagination?: boolean;
  isRemotePagination?: boolean;
  id?: string;
  onFetchData?: (args: FetchDataArgs) => void;
  isLoaded: boolean;
  isFetchMoreDataLoaded: boolean;
  totalPageCount: number; // Total number of pages
  searchTerm?: string;
  enableSorting?: boolean;
  defaultPageNumber?: number;
  defaultPageSize?: number;
  emptyTableStatusConfig?: {
    header?: string;
    subHeader?: string;
    minHeight?: LayoutProps['height'];
  };
  loadingComponent?: React.ReactNode;
}

interface State {
  redirect: null | string; // When set to a string, redirects to that page's route
  openDialog: boolean; // Whether an action wants to open a dialog
}

const DEFAULT_PAGE_SIZES = [5, 10, 20, 30, 40, 50];

const SUPPORTED_ACTION_TYPES = ['gotoPage', 'setPageSize', 'toggleSortBy'];

export function Table({
  data,
  columns,
  enablePagination,
  isRemotePagination,
  id,
  onFetchData,
  totalPageCount,
  onClickRow,
  isLoaded,
  isFetchMoreDataLoaded,
  searchTerm,
  enableSorting = false,
  defaultPageNumber = 0,
  defaultPageSize = 10,
  emptyTableStatusConfig,
  loadingComponent,
}: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      manualPagination: isRemotePagination,
      pageCount: totalPageCount,
      initialState: { pageIndex: defaultPageNumber, pageSize: defaultPageSize },
      manualSortBy: true,
      disableSortRemove: true,
      stateReducer: (newState, action) => {
        // For some reason, this action is being called on resize and reset events
        // and causes loop. So,only listen for the actions we care about
        if (!SUPPORTED_ACTION_TYPES.includes(action.type)) return newState;

        const fetchArgs: FetchDataArgs = {};
        const { pageIndex, pageSize, sortBy } = newState;

        if (enablePagination) {
          fetchArgs.pageIndex = pageIndex;
          fetchArgs.pageSize = pageSize;
        }

        if (enableSorting && sortBy.length) {
          const sortingColumn = sortBy[0];
          fetchArgs.sortOrder = sortingColumn.desc ? 'DESC' : 'ASC';
          fetchArgs.orderBy = sortingColumn.id;
        }
        // Listen for changes in pagination and use the newState to fetch our new data
        if (onFetchData) onFetchData(fetchArgs);
        return newState;
      },
    },
    useSortBy,
    usePagination
  );
  const [state, setState] = useState<State>({
    redirect: null,
    openDialog: false,
  });
  const theme = useTheme();
  const { backgroundColor, textColor, borderColor } = useColorModeValue(
    { backgroundColor: 'white', textColor: 'dusk100.500', borderColor: theme.colors.customTableGray[500] },
    { backgroundColor: 'gray.900', textColor: 'dusk100.50', borderColor: theme.colors.dusk100[400] }
  );

  if (state.redirect) {
    return <Navigate to={state.redirect} />;
  }

  const loaderScreen = loadingComponent ? (
    <Box as="table" w="100%">
      <TableHeader headerGroups={headerGroups} enableSorting={enableSorting} />
      {loadingComponent}
    </Box>
  ) : (
    <CenteredLoader />
  );

  return isLoaded ? (
    data.length ? (
      <Box background={backgroundColor} maxWidth="100vw" overflow="hidden">
        <Box
          id={id}
          data-testid={id}
          w="100%"
          borderTopRightRadius="5px"
          borderTopLeftRadius="5px"
          overflow={'hidden'}
          boxShadow={`0 2px 0 -1px ${borderColor}`}
          as="table"
          data-private
          {...getTableProps()}
        >
          <TableHeader headerGroups={headerGroups} enableSorting={enableSorting} />

          <TableBody
            onClickRow={onClickRow}
            isLoaded={isLoaded}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            state={state}
            setState={setState}
          />
        </Box>
        {enablePagination && (
          <TablePagination
            isLoaded={isFetchMoreDataLoaded}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            previousPage={previousPage}
            setPageSize={setPageSize}
          />
        )}
      </Box>
    ) : searchTerm ? (
      <Center
        height={'100%'}
        flexDirection={'column'}
        mb={4}
        minHeight={emptyTableStatusConfig?.minHeight || 'initial'}
      >
        <Heading my="5" size={'md'} color={textColor}>
          No results found for &ldquo;{searchTerm}&rdquo;
        </Heading>
        <Text size={'sm'} color=" dusk050.500">
          Please try again
        </Text>
      </Center>
    ) : (
      <Center
        height={'100%'}
        flexDirection={'column'}
        mb={4}
        minHeight={emptyTableStatusConfig?.minHeight || 'initial'}
      >
        <Heading my="5" size={'md'} color={textColor}>
          {emptyTableStatusConfig?.header || 'No sites created'}
        </Heading>
        <Text size={'sm'} color="dusk050.500" textAlign="center" px={8}>
          {emptyTableStatusConfig?.subHeader || 'Create a site using the button below'}
        </Text>
      </Center>
    )
  ) : (
    loaderScreen
  );
}

interface TableHeaderProps {
  headerGroups: HeaderGroup<object>[];
  enableSorting: boolean;
}

function TableHeader({ headerGroups, enableSorting }: TableHeaderProps) {
  const theme = useTheme();
  const { headerColor, borderColor } = useColorModeValue(
    { headerColor: 'dusk100.500', borderColor: 'customTableGray.400' },
    { headerColor: 'dusk100.100', borderColor: 'inherit' }
  );
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <Box
          as="tr"
          bg={`${theme.colors.primaryBranding[500]}1A`}
          borderBottom="1px solid"
          borderColor={borderColor}
          {...headerGroup.getHeaderGroupProps()}
          key={`${headerGroup.id}-header`} // Fix for Chakra UI bug
        >
          {headerGroup.headers.map((column) => {
            //intially isSortedDesc is undefined and table is sorted in ascending order by default
            const isTableSortAscending = column.isSortedDesc === undefined ? true : column.isSortedDesc;
            const isColumnSortable = enableSorting && column.canSort;
            const columnSortingProps = isColumnSortable && { ...column.getHeaderProps(column.getSortByToggleProps()) };
            return (
              <Box textAlign="start" p={1} as="th" {...column.getHeaderProps()} key={column.id}>
                <Button
                  {...columnSortingProps}
                  data-testid={`table-header-btn-${column.id?.replace('_', '-')}`}
                  py={[1, 2]}
                  px={0}
                  mx={0}
                  textAlign={'left'}
                  h="fit-content"
                  variant="unstyled"
                  whiteSpace="normal"
                  cursor={isColumnSortable ? 'pointer' : 'initial'}
                  _hover={{ textDecoration: isColumnSortable ? 'underline' : undefined }}
                >
                  <Heading color={headerColor} size="sm">
                    {column.render('Header')}
                    {isColumnSortable && column.isSorted && (
                      <Box as="span">{isTableSortAscending ? <ArrowDownIcon /> : <ArrowUpIcon />}</Box>
                    )}
                  </Heading>
                </Button>
              </Box>
            );
          })}
        </Box>
      ))}
    </thead>
  );
}

interface TableBodyProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<object>) => ReactTableBodyProps;
  page: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  state: State;
  setState: (state: State) => void;
  isLoaded: boolean;
  onClickRow?: (row: any) => void;
}

function TableBody({ getTableBodyProps, page, prepareRow, isLoaded, onClickRow }: TableBodyProps) {
  const { hoverColor, borderColor } = useColorModeValue(
    { hoverColor: 'gray.50', borderColor: 'customTableGray.400' },
    { hoverColor: 'gray.700', borderColor: 'inherit' }
  );
  return (
    <Box as="tbody" data-testid={'table-body'} position={'relative'} {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);

        return (
          <Box
            data-testid={`table-row-${index}`}
            data-row-index={index}
            className="table-rows"
            onClick={() => onClickRow && onClickRow(row)}
            cursor={onClickRow ? 'pointer' : 'default'}
            _hover={{ background: onClickRow ? hoverColor : 'transparent' }}
            opacity={isLoaded ? 1 : 0.3}
            borderBottom="1px solid"
            borderColor={borderColor}
            as="tr"
            {...row.getRowProps()}
            key={row.id}
          >
            {row.cells.map((cell) => {
              return (
                // use explicit key because eslint somehow doesn't realize that the spread applies the key prop
                <Box p={2} as="td" {...cell.getCellProps()} key={cell.getCellProps().key}>
                  {cell.render('Cell')}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

interface TablePaginationProps {
  gotoPage: (pageNumber: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageIndex: number;
  pageOptions?: number[];
  pageSize: number;
  pageCount: number;
  setPageSize: (pageSize: number) => void;
  isLoaded: boolean;
}

const pageListArrayGenerator = (currentPageIndex: number, totalPage: number): number[] => {
  if (totalPage < 5) return [...Array(totalPage).keys()];
  if (currentPageIndex < 2) {
    return [...Array(5).keys()];
  } else if (currentPageIndex + 3 > totalPage) {
    return [totalPage - 5, totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1];
  } else {
    return [currentPageIndex - 2, currentPageIndex - 1, currentPageIndex, currentPageIndex + 1, currentPageIndex + 2];
  }
};

export function TablePagination({
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageIndex,
  pageSize,
  pageCount,
  setPageSize,
  isLoaded,
}: TablePaginationProps) {
  const isDesktopViewport = useBreakpointValue(
    {
      base: false,
      xl: true,
    },
    { ssr: false }
  );

  const { textColor, backgroundColor } = useColorModeValue(
    { textColor: 'dusk100.500', backgroundColor: 'white' },
    { textColor: 'dusk100.50', backgroundColor: 'gray.800' }
  );

  const PaginationButton = ({ pageIndexNumber }: { pageIndexNumber: number }) => (
    <Button
      data-testid={`pagination-page-${pageIndexNumber + 1}`}
      variant="ghost"
      borderRadius="100"
      h="10"
      w="10"
      className={`${pageIndex === pageIndexNumber ? 'pagination-active' : 'pagination-inactive'} pagination-page-${
        pageIndexNumber + 1
      }`}
      border={'2px solid'}
      borderColor={pageIndex === pageIndexNumber ? 'primaryBranding.500' : 'transparent'}
      onClick={() => gotoPage(pageIndexNumber)}
      cursor="pointer"
      mr={[0, 0, 1]}
    >
      {!isLoaded && pageIndex === pageIndexNumber ? (
        <Box>
          <Spinner size={'xs'} />
        </Box>
      ) : (
        <Text color={textColor}>{pageIndexNumber + 1}</Text>
      )}
    </Button>
  );

  const onPageChangeHandler = useMemo(
    () =>
      debounceEvent((eNumber: number) => {
        gotoPage(eNumber ? eNumber - 1 : 0);
      }),
    [gotoPage]
  );

  return (
    <Flex p={2} mt={2} direction={isDesktopViewport ? 'row' : 'column'} align="center" w="100%" pt={4}>
      <Flex flexWrap="nowrap" justifyContent="space-between" w={!isDesktopViewport ? '100%' : undefined} align="center">
        <Button
          data-testid={'pagination-first-page'}
          isDisabled={!canPreviousPage}
          px={[0, 0, 2]}
          variant="ghost"
          onClick={() => isLoaded && gotoPage(0)}
        >
          <ArrowLeftIcon mr={2} w={3} />
          {isDesktopViewport && <Text>First</Text>}
        </Button>

        <Button
          data-testid={'pagination-previous-page'}
          isDisabled={!canPreviousPage}
          px={[0, 0, 2]}
          variant="ghost"
          onClick={() => isLoaded && previousPage()}
        >
          <ChevronLeftIcon w={6} h={6} />
          {isDesktopViewport && <Text>Prev</Text>}
        </Button>

        {pageListArrayGenerator(pageIndex, pageCount).map((pageIndex, index) => (
          <PaginationButton pageIndexNumber={pageIndex} key={index} />
        ))}

        <Button
          data-testid={'pagination-next-page'}
          isDisabled={!canNextPage}
          px={[0, 0, 2]}
          variant="ghost"
          onClick={() => isLoaded && nextPage()}
        >
          {isDesktopViewport && <Text>Next</Text>}
          <ChevronRightIcon w={6} h={6} />
        </Button>

        <Button
          data-testid={'pagination-last-page'}
          isDisabled={!canNextPage}
          px={[0, 0, 2]}
          variant="ghost"
          onClick={() => isLoaded && gotoPage(pageCount - 1)}
        >
          {isDesktopViewport && <Text>Last</Text>}
          <ArrowRightIcon ml={2} w={3} />
        </Button>
      </Flex>

      <Flex
        mt={isDesktopViewport ? 0 : 4}
        mr={isDesktopViewport ? 4 : 0}
        w="100%"
        justify={isDesktopViewport ? 'initial' : 'center'}
      >
        <Flex mx={isDesktopViewport ? 'auto' : 0}>
          <InputGroup bg={backgroundColor} size="sm">
            <InputLeftAddon fontSize="sm">Go to page</InputLeftAddon>
            <NumberInput
              isDisabled={!isLoaded}
              defaultValue={pageIndex + 1}
              onChange={(e, eNumber) => {
                onPageChangeHandler(eNumber);
              }}
              min={0}
              max={pageCount}
              data-testid={'pagination-goto-page'}
              style={{ width: '80px' }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Flex>
        <Box bg={backgroundColor} ml={4}>
          <Select
            isDisabled={!isLoaded}
            data-testid={'pagination-pageSize'}
            size="sm"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {DEFAULT_PAGE_SIZES.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
    </Flex>
  );
}
