import React, { useRef, useState } from 'react';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { AddIcon, InfoIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useTheme,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';
import { IS_NATIVE, SCHNEIDER_ELECTRIC_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { DeviceMetadata } from '../../../../api/api-device-metadata';
import { useAppSelector } from '../../../../app/hooks';
import schneiderChargeDeviceQRImg from '../../../../assets/images/se_charge_device_qr.png';
import { CustomButton } from '../../../../common/components/CustomButton';
import { IS_RUNNING_CYPRESS_TESTS } from '../../../../common/constants';
import { selectModelsByDeviceType } from '../systemDetailsSlice';

const MULTI_PHASE_EV_CHARGER_MODELS = ['EVH5A22N2S'];

type SchneiderEVChargerConfigurationProps = {
  setChargerId: (chargerId: string) => void;
  setSerialNumber: (serialNumber: string) => void;
  setEVChargerModel: (deviceMetadataId: number) => void;
  isConfigured?: boolean;
};

export function SchneiderEVChargerConfiguration({
  setChargerId,
  setSerialNumber,
  setEVChargerModel,
  isConfigured,
}: SchneiderEVChargerConfigurationProps) {
  const toast = useToast();
  const theme = useTheme();
  const scrollTopPosition = useRef(0);
  const allEVChargerModels = useAppSelector(selectModelsByDeviceType('EV_CHARGER'));
  const [selectableEVChargerModels, setSelectableEVChargerModels] = useState<DeviceMetadata[]>([]);
  const [selectedDeviceMetadataId, setSelectedDeviceMetadataId] = useState<number>(0);
  const [isAccordionOpen, setIsAccordionOpen] = useState(!isConfigured);

  const showInvalidQRCodeToast = () => {
    toast({
      title: 'Invalid QR code!',
      description: 'Please try again! If issue persists, please contact support.',
      status: 'error',
      isClosable: true,
    });
  };

  async function handleStartQRCodeScan() {
    // Handle barcode scan success and fail when running Cypress tests
    if (IS_RUNNING_CYPRESS_TESTS) {
      const availableModels = allEVChargerModels[SCHNEIDER_ELECTRIC_MANUFACTURER_ID].filter(
        (device) => device.manufacturer_id === SCHNEIDER_ELECTRIC_MANUFACTURER_ID
      );
      setSelectableEVChargerModels(availableModels);
      setSelectedDeviceMetadataId(availableModels[0].device_metadata_id);
      setChargerId('abcd1234-ab12-4ab1-aab1-abcdef123456');
      setSerialNumber('EVB1A33P4KI3N214912002400150027AZ');
      return;
    }

    // Save the current scroll position
    scrollTopPosition.current = window.scrollY;

    try {
      const hasPermission = await didUserGrantBarcodePermission();

      if (hasPermission) {
        await BarcodeScanner.hideBackground();
        document.body.classList.add('qrscanner');

        const result = await BarcodeScanner.startScan();
        document.body.classList.remove('qrscanner');

        if (result.hasContent) {
          // barcode content sample:
          // http://go2se.com/ref=EVH4A10N5PU/sn=SN2205278845/cpid=b5eddbaf-984f-418e-88eb-cf0b8ff3e775
          const barcodeContent = result.content;
          if (!barcodeContent) {
            showInvalidQRCodeToast();
            return;
          }

          const splittedUrl = barcodeContent.split('.com/');
          const chargerProperties = splittedUrl[1];

          if (!chargerProperties) {
            showInvalidQRCodeToast();
            return;
          }

          const splitChargerProperties = chargerProperties.split('/');

          const barCodeValues = splitChargerProperties.reduce(
            (acc, curr) => {
              const [key, value] = curr.split('=');
              if (['ref', 'sn', 'cpid'].includes(key)) return { ...acc, [key]: value };
              return acc;
            },
            { ref: '', sn: '', cpid: '' }
          );

          // Find all the devices that match the barcode reference
          const availableModels = allEVChargerModels[SCHNEIDER_ELECTRIC_MANUFACTURER_ID].filter((model) =>
            model.model.includes(barCodeValues.ref)
          );
          // Select the first model that matches the barcode reference
          const evChargerMetadataId = availableModels[0].device_metadata_id;

          // For multi-phase EV chargers, show a modal to select the EV charger model
          if (MULTI_PHASE_EV_CHARGER_MODELS.includes(barCodeValues.ref)) {
            setSelectableEVChargerModels(availableModels);
            setSelectedDeviceMetadataId(evChargerMetadataId);
          } else if (evChargerMetadataId) {
            // For single-phase EV chargers, set the EV charger model directly
            setEVChargerModel(evChargerMetadataId);
          }
          setChargerId(barCodeValues.cpid);
          setSerialNumber(barCodeValues.sn);
          setIsAccordionOpen(false);
        } else {
          console.error('No content from QR Code');
          showInvalidQRCodeToast();
        }
      }
    } catch (error) {
      console.error('Error scanning QR Code:', error);
      document.body.classList.remove('qrscanner');
      showInvalidQRCodeToast();
    }

    // Scroll back to the previous position
    window.scrollTo(0, scrollTopPosition.current);
  }

  return (
    <>
      <Accordion
        defaultIndex={isConfigured ? -1 : 0}
        allowToggle
        my={4}
        borderColor="gray.200"
        borderWidth={'1px'}
        rounded={10}
        overflow="hidden"
      >
        <AccordionItem
          border="none"
          borderLeft={`10px solid ${theme.colors.customLinkBlue[500]}`}
          data-testid={`schneider-charge-accordion`}
        >
          <Flex w="100%" justify="space-between" py={1}>
            <Flex align="center">
              <InfoIcon color="customLinkBlue.500" mx={2} h={5} w={5} />
              <Text fontWeight="600">
                Prefill Schneider Charge Model, Serial Number and Charger ID by scanning QR Code.
              </Text>
            </Flex>

            <AccordionButton
              w="fit-content"
              mx={2}
              px={2}
              rounded={4}
              onClick={() => setIsAccordionOpen(!isAccordionOpen)}
              data-testid="schneider-charge-accordion-button"
            >
              {isAccordionOpen ? <MinusIcon h={3} /> : <AddIcon h={3} />}
            </AccordionButton>
          </Flex>
          <AccordionPanel pb={4} pt={0} pl={8}>
            <OrderedList>
              <ListItem>
                <Text>Commission Schneider Charge through e-Setup app (if not done already).</Text>
                <Text fontSize={14}>
                  <strong>NOTE</strong>: internet connection not strictly necessary.
                </Text>
              </ListItem>
              <ListItem>
                <Box my={4}>
                  Scan the QR Code on the side of the Schneider Charge device by hitting the button below.
                </Box>
                <VStack>
                  <Image
                    src={schneiderChargeDeviceQRImg}
                    alt="Schneider Charge Device Details Scan"
                    my={8}
                    w="100%"
                    maxW={150}
                    mx="auto"
                  />
                  <Button
                    data-testid={'open-camera'}
                    width="100%"
                    maxWidth={'300px'}
                    mb={2}
                    mx="auto"
                    onClick={handleStartQRCodeScan}
                    isDisabled={!IS_NATIVE && !IS_RUNNING_CYPRESS_TESTS}
                  >
                    {IS_NATIVE || IS_RUNNING_CYPRESS_TESTS ? 'Open Camera' : 'Scanning not available on web'}
                  </Button>
                </VStack>
              </ListItem>
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Modal
        onClose={() => {
          // do nothing as we don't want to close the modal except by clicking the save button
        }}
        isOpen={!!selectableEVChargerModels.length}
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={'90vw'} maxW={500} data-testid="select-ev-charger-configuration-modal">
          <ModalHeader>Select EV Charger Configuration</ModalHeader>
          <ModalBody>
            <Text>Please select how you will configure the EV Charger</Text>
            <RadioGroup
              mt={2}
              value={selectedDeviceMetadataId.toString()}
              onChange={(deviceMetadataId) => setSelectedDeviceMetadataId(Number(deviceMetadataId))}
            >
              <Stack data-testid={'selectable-ev-charger-models'}>
                {selectableEVChargerModels.map((device) => (
                  <Radio value={device.device_metadata_id.toString()} key={device.model}>
                    {device.model}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </ModalBody>
          <CustomButton
            data-testid="save-ev-charger-type"
            onClick={() => {
              setEVChargerModel(selectedDeviceMetadataId);
              setSelectableEVChargerModels([]);
            }}
            minW={300}
            mx="auto"
            mb={4}
          >
            Save
          </CustomButton>
        </ModalContent>
      </Modal>
    </>
  );
}
