import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Select,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { startOfToday, startOfYesterday } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { CustomDatePicker } from 'clipsal-cortex-ui/src/components/date-picker/CustomDatePicker';

import { formatDate } from '../../../../utils/datetime-utils';
import { DATA_CORRECTION_TYPE_OPTIONS, DataCorrectionType } from './data-correction-helpers';
import { usePostBackfillHistoryMutation, usePostDataCorrectionMutation } from './dataCorrectionApi';
import { DataCorrectionChartData } from './DataCorrectionChartData';
import { SiteSearchInput } from './SiteSearchInput';

export const DataCorrectionForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ siteId, type, startDate, endDate }, setState] = useState({
    siteId: Number(searchParams.get('siteId')) || 0,
    type: (searchParams.get('type') ?? 'energyUsage') as DataCorrectionType,
    startDate: searchParams.get('startDate') ?? formatDate(startOfYesterday()),
    endDate: searchParams.get('endDate') ?? formatDate(startOfToday()),
  });
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const isActionable = siteId && startDate && endDate;
  const [postDataCorrection, { isLoading: isPostingDataCorrection }] = usePostDataCorrectionMutation();
  const [postBackfillHistory, { isLoading: isPostingBackfillHistory }] = usePostBackfillHistoryMutation();

  const handleUpdateSearchParams = useCallback(
    (newParams: Record<string, string>) => {
      // Spread the current search params and update the specific 'query' param
      const currentParams = Object.fromEntries(searchParams.entries());
      setSearchParams({ ...currentParams, ...newParams });
    },
    [searchParams]
  );

  return (
    <Box>
      <HStack
        px={4}
        mt={2}
        alignItems={'flex-end'}
        flexDirection={isMobileViewport ? 'column' : 'row'}
        rowGap={isMobileViewport ? 2 : undefined}
        maxW={isMobileViewport ? undefined : 1400}
      >
        <SiteSearchInput
          siteId={siteId}
          onUpdateSiteId={(siteId: number, searchLabel: string) => {
            setState((prevState) => ({ ...prevState, siteId }));
            handleUpdateSearchParams({ siteId: siteId.toString(), searchLabel });
          }}
        />

        <FormControl maxW={isMobileViewport ? undefined : '180px'}>
          <FormLabel>Correction Type</FormLabel>
          <Select
            data-testid="select-data-correction-type"
            value={type}
            onChange={(event) => {
              const type = event.target.value as DataCorrectionType;
              setState((prevState) => ({ ...prevState, type }));
              handleUpdateSearchParams({ siteId: siteId.toString(), type });
            }}
          >
            {DATA_CORRECTION_TYPE_OPTIONS.map(({ label, value }) => (
              <option value={value} key={value} data-testid={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl id="start-date" maxW={isMobileViewport ? undefined : 280}>
          <FormLabel>Start date</FormLabel>
          <CustomDatePicker
            maxDate={new Date()}
            inputProps={{
              'data-testid': `start-date-picker`,
              placeholder: 'dd/mm/yyyy',
            }}
            popperPlacement="top-start"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              if (date) {
                const isDateAfterEndDate = date > new Date(endDate);
                const newFormattedDate = formatDate(date);
                const formattedEndDate = isDateAfterEndDate ? newFormattedDate : endDate;
                setState((prevState) => ({
                  ...prevState,
                  startDate: newFormattedDate,
                  endDate: formattedEndDate,
                }));
                handleUpdateSearchParams({ startDate: newFormattedDate, endDate: formattedEndDate });
              }
            }}
            selected={startDate ? new Date(startDate) : null}
          />
        </FormControl>
        <FormControl id="end-date" maxW={isMobileViewport ? undefined : 280}>
          <FormLabel>End date</FormLabel>
          <CustomDatePicker
            minDate={new Date(startDate)}
            maxDate={new Date()}
            inputProps={{
              'data-testid': `end-date-picker`,
              placeholder: 'dd/mm/yyyy',
            }}
            popperPlacement="top-start"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              if (date) {
                const isDateBeforeStartDate = date < new Date(startDate);
                const newFormattedDate = formatDate(date);
                const formattedStartDate = isDateBeforeStartDate ? newFormattedDate : startDate;
                setState((prevState) => ({
                  ...prevState,
                  startDate: formattedStartDate,
                  endDate: newFormattedDate,
                }));
                handleUpdateSearchParams({ startDate: formattedStartDate, endDate: newFormattedDate });
              }
            }}
            selected={endDate ? new Date(endDate) : null}
          />
        </FormControl>
      </HStack>

      {!!siteId && !!startDate && !!endDate && (
        <Box mx={4} mt={4} mb={8}>
          <DataCorrectionChartData siteId={siteId} type={type} startDate={startDate} endDate={endDate} />

          <Center mt={4} columnGap={4} flexWrap={'wrap'} rowGap={4}>
            <Button
              minW={210}
              colorScheme="primaryButton"
              rounded={20}
              data-testid="submit-data-correction"
              isDisabled={!isActionable}
              isLoading={isPostingDataCorrection}
              onClick={async () => {
                toast.closeAll();
                try {
                  await postDataCorrection({ siteId, type, startDate, endDate }).unwrap();
                  toast({
                    title: 'Successfully submitted data correction!',
                    description: 'This will take some time, so please check back in about 5 minutes and hit "refresh"',
                    status: 'success',
                  });
                } catch (error) {
                  toast({
                    title: 'Something went wrong while submitting data correction!',
                    status: 'error',
                  });
                }
              }}
            >
              Submit Data Correction
            </Button>
            <Button
              minW={210}
              colorScheme="primaryButton"
              rounded={20}
              data-testid="submit-backfill-history"
              variant={'outline'}
              isDisabled={!isActionable}
              isLoading={isPostingBackfillHistory}
              onClick={async () => {
                toast.closeAll();
                try {
                  await postBackfillHistory({ siteId, type }).unwrap();
                  toast({
                    title: 'Successfully submitted historical site backfill!',
                    description:
                      'This will take some time to backfill, so check back in about 5 minutes and hit "refresh"',
                    status: 'success',
                  });
                } catch (error) {
                  toast({
                    title: 'Something went wrong while submitting historical site backfill!',
                    status: 'error',
                  });
                }
              }}
            >
              Backfill Entire History
            </Button>
          </Center>
        </Box>
      )}
    </Box>
  );
};
