import {
  ALPHA_ESS_MANUFACTURER_ID,
  GOODWE_MANUFACTURER_ID,
  SOLAR_EDGE_MANUFACTURER_ID,
  SUNGROW_MANUFACTURER_ID,
} from 'clipsal-cortex-utils/src/constants/common-constants';

import { GenericDeviceFormData, InverterFormData, InverterOrientationData } from './system-details-form-types';

export const CARDINALITIES = ['NW', 'N', 'NE', 'W', 'Compass', 'E', 'SW', 'S', 'SE'];
export const CARDINAL_TO_ORIENTATION_MAP: Record<string, number> = {
  N: 0,
  NE: 1,
  E: 2,
  SE: 3,
  S: 4,
  SW: 5,
  W: 6,
  NW: 7,
};

export const EMPTY_ORIENTATION_TEMPLATE: InverterOrientationData = {
  orientationId: null,
  numOfModules: 0,
  cardinality: 'N',
  tilt: 'flat',
  panelPower: 250,
};

export const EMPTY_DEVICE_TEMPLATE: GenericDeviceFormData = {
  deviceId: null,
  manufacturer: 0,
  model: 0,
  serialNumber: null,
};

export const EMPTY_INVERTER_TEMPLATE: InverterFormData = {
  inverterId: null,
  hasSolarPanels: true,
  hasDCCoupledBattery: false,
  serialNumber: null,
  siteIdentifier: null,
  apiKey: '',
  manufacturer: 0,
  model: 0,
  totalPanels: 0,
  orientations: [EMPTY_ORIENTATION_TEMPLATE],
};

export const MANUFACTURERS_WITH_SERIAL = [
  SOLAR_EDGE_MANUFACTURER_ID,
  GOODWE_MANUFACTURER_ID,
  ALPHA_ESS_MANUFACTURER_ID,
  SUNGROW_MANUFACTURER_ID,
];

export const MANUFACTURERS_WITH_SITE_IDENTIFIER_SAME_AS_SERIAL = [SUNGROW_MANUFACTURER_ID, GOODWE_MANUFACTURER_ID];
