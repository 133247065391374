import React from 'react';
import { Badge, Button, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';

import { ChannelData, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { GRID_CIRCUIT_TYPES, SOLAR_CIRCUIT_TYPES } from '../../features/site/meter-setup/meter-setup-helpers';

export type MeterCTSelectProps = {
  onCTClick: (index: ChannelData) => void;
  selectedCTs: ChannelData[];
  reservedCTs: ChannelData[];
  meter: WattwatchersMeter;
  meterIndex?: number;
};

const getCircuitName = (circuitType: string) => {
  if (GRID_CIRCUIT_TYPES.includes(circuitType)) return 'Grid';
  if (SOLAR_CIRCUIT_TYPES.includes(circuitType)) return 'Solar';
  if (circuitType === 'battery_storage') return 'Batt';
  if (circuitType === 'hybrid_inverter') return 'Hyb';
  if (circuitType === 'backup_circuit') return 'BUP';
  return 'Load';
};

export const MeterCTSelect = ({ meter, onCTClick, selectedCTs, reservedCTs, meterIndex = 0 }: MeterCTSelectProps) => {
  const { backgroundColor, disabledBackgroundColor, textColor, disabledTextColor } = useColorModeValue(
    {
      backgroundColor: 'gray.50',
      disabledBackgroundColor: 'gray.50',
      textColor: 'gray.700',
      disabledTextColor: 'gray.400',
    },
    {
      backgroundColor: 'gray.800',
      disabledBackgroundColor: 'gray.800',
      textColor: 'gray.200',
      disabledTextColor: 'dusk100.400',
    }
  );
  return (
    <Flex direction="column" mb={8}>
      <Flex wrap={'wrap'} align="center" mb={4} rowGap={2}>
        {meter.label && (
          <Heading size="sm" fontWeight="500" mr={2}>
            {meter.label}{' '}
          </Heading>
        )}
        <Badge fontSize={14} h="fit-content" variant={'outline'} rounded={20} px={2}>
          {meter.ww_device_id}
        </Badge>
      </Flex>
      <Flex justify="space-between" maxW="350px">
        {meter.circuits.map((circuit, index) => {
          // The CT is reserved if we can find its ID in the provided reserved CTs array
          const reservedCT = reservedCTs.find((ct) => ct.ww_circuit_id === circuit.ww_circuit_id);
          // It's selected if we can find it in the selected CTs array.
          const selectedCT = selectedCTs.find((ct) => ct.ww_circuit_id === circuit.ww_circuit_id);
          // If a CT is selected, and not reserved, use green.
          const borderColor = reservedCT || !selectedCT ? 'gray.400' : 'primaryBranding.500';

          return (
            <Button
              data-testid={`meter-${meterIndex}-ct-${index}`}
              data-selected={!!selectedCT}
              key={index}
              h={10}
              w={10}
              rounded="full"
              border="3px solid"
              borderColor={borderColor}
              background={reservedCT ? disabledBackgroundColor : backgroundColor}
              _hover={{ background: reservedCT ? disabledBackgroundColor : backgroundColor }}
              onClick={() => onCTClick(circuit)}
              cursor={reservedCT ? 'not-allowed' : 'pointer'}
              isDisabled={!!reservedCT}
              _disabled={{ borderColor: 'gray.600' }}
              outline="0px transparent"
            >
              <Text fontSize="14px" color={!!reservedCT ? disabledTextColor : textColor}>
                {!!reservedCT ? getCircuitName(reservedCT.clipsal_monitors) : `CT${index + 1}`}
              </Text>
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
};
