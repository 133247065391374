import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { Carousel } from 'clipsal-cortex-ui/src/components/Carousel';

import backgroundImgSrc from '../../assets/images/carousel_bg.svg';
import clipsalAppImg from '../../assets/images/clipsal_app_illustration.png';
import clipsalBatteryImg from '../../assets/images/clipsal_battery_illustration.svg';
import clipsalHouseImg from '../../assets/images/clipsal_house_illustration.svg';
import clipsalMeterImg from '../../assets/images/clipsal_meter.png';
import { COMPONENT_MIN_HEIGHT } from '../../common/constants';

const SLIDES_CONFIG = [
  {
    imgSrc: clipsalMeterImg,
    label: 'Commission with ease',
    description: (
      <Text>
        <strong>Easy to install</strong> & commission, Clipsal Cortex monitoring solution measures energy usage of the
        main circuits and appliances in real-time, a unique selling point for homeowners.
      </Text>
    ),
  },
  {
    imgSrc: clipsalHouseImg,
    label: 'Manage your fleet',
    description: (
      <Text>
        Manage a <strong>fleet of solar installations</strong> with multiple brands of inverters and batteries
      </Text>
    ),
  },
  {
    imgSrc: clipsalBatteryImg,
    label: 'Battery upsell',
    description: (
      <Text>
        Target the right homeowners to <strong>upsell batteries</strong> with Clipsal Cortex’s battery calculator.
      </Text>
    ),
  },
  {
    imgSrc: clipsalAppImg,
    label: 'RPC and personalised insights',
    description: (
      <Text>
        <strong>Empower homes</strong> to take control of their energy usage with retail plan checks and personalised
        insights Manage a with multiple brands of inverters and batteries
      </Text>
    ),
  },
];

export const ProductFeatureCarousel = () => {
  const slides = SLIDES_CONFIG.map((slide, idx) => (
    <Flex
      key={idx}
      direction={'column'}
      px={3}
      pt={8}
      justify="center"
      align="center"
      textAlign="center"
      color="white"
      // 250px is offsetting the container height to center carousel content
      minH={`calc(${COMPONENT_MIN_HEIGHT} - 250px)`}
    >
      <Image src={slide.imgSrc} maxH={'40vh'} />
      <Text fontSize="2xl" maxW={450} mt={10}>
        {slide.label}
      </Text>
      <Box fontSize="md" maxW={450} mt={10}>
        {slide.description}
      </Box>
    </Flex>
  ));

  return (
    <Carousel
      {...{
        slides,
        backgroundImgSrc,
        navContainerProps: { bottom: 0 },
        containerProps: { 'data-testid': 'product-carousel' },
      }}
    />
  );
};
