import { useSelector } from 'react-redux';

import { Appliance, ApplianceToSave } from '../../../api/api-appliance';
import { Circuit, InstalledDevice } from '../../../api/api-device';
import { baseApi } from '../../../app/baseApi';
import { selectSite } from '../siteSlice';

export const siteApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSiteMeters: build.query<InstalledDevice[], number>({
      query: (siteId) => `/fleet/sites/${siteId}/meters`,
      providesTags: (_, __, siteId) => [{ type: 'SiteMeters', siteId }],
    }),
    getSiteAppliances: build.query<Appliance[], number>({
      query: (siteId) => `/fleet/sites/${siteId}/appliances`,
      providesTags: (_, __, siteId) => [{ type: 'SiteAppliances', siteId }],
    }),
    updateSiteAppliances: build.mutation<Appliance[], { siteId: number; appliances: ApplianceToSave[] }>({
      query: ({ siteId, appliances }) => ({
        url: `/fleet/sites/${siteId}/appliances`,
        method: 'PUT',
        body: appliances,
      }),
      invalidatesTags: (_, __, siteId) => [{ type: 'SiteAppliances', siteId }],
    }),
    getSiteCircuits: build.query<Circuit[], number>({
      query: (siteId) => `/fleet/sites/${siteId}/circuits`,
      providesTags: (_, __, siteId) => [{ type: 'SiteCircuits', siteId }],
    }),
    updateSiteCircuits: build.mutation<Circuit[], { siteId: number; circuits: Circuit[] }>({
      query: ({ siteId, circuits }) => ({
        url: `/fleet/sites/${siteId}/circuits`,
        method: 'POST',
        body: circuits,
      }),
      invalidatesTags: (_, __, siteId) => [{ type: 'SiteCircuits', siteId }],
    }),
    refreshSiteCircuits: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/refresh_circuits`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useRefreshSiteCircuitsMutation,
  useGetSiteMetersQuery,
  useGetSiteCircuitsQuery,
  useGetSiteAppliancesQuery,
  useUpdateSiteAppliancesMutation,
  useUpdateSiteCircuitsMutation,
} = siteApi;

export const useSiteMeters = () => {
  const { clipsal_solar_id: siteId } = useSelector(selectSite);
  const response = useGetSiteMetersQuery(siteId);

  return {
    siteMeters: response.data ?? [],
    isLoadingSiteMeters: response.isLoading,
    ...response,
  };
};

export const useSiteCircuits = () => {
  const { clipsal_solar_id: siteId } = useSelector(selectSite);
  const response = useGetSiteCircuitsQuery(siteId);

  return {
    siteCircuits: response.data ?? [],
    isLoadingSiteCircuits: response.isLoading,
    ...response,
  };
};

export const useSiteAppliances = () => {
  const { clipsal_solar_id: siteId } = useSelector(selectSite);
  const response = useGetSiteAppliancesQuery(siteId);

  return {
    siteAppliances: response.data ?? [],
    isLoadingSiteAppliances: response.isLoading,
    ...response,
  };
};
