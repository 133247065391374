import React from 'react';
import { Box, Divider, Grid, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';

import { CustomCheckbox } from './CustomCheckBox';
import { useMeterFilterConfiguration } from './use-meter-filter-configuration';

type MeterConfigurationFilterModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MeterConfigurationFilterModal = ({ isOpen, onClose }: MeterConfigurationFilterModalProps) => {
  const {
    availableCircuits,
    sourceCircuits,
    loadCircuits,
    isAllSourceCircuitsSelected,
    isAllLoadCircuitsSelected,
    handleSelectAllMeters,
    handleSelectAllCircuitsOfMeter,
    handleSelectAllCircuitsOfType,
    handleSelectCircuit,
    hiddenMeterIds,
    memoizedRawMeters,
  } = useMeterFilterConfiguration();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minH={400} mx={4} data-testid="filter-modal">
        <ModalCloseButton data-testid="close-filter-modal-button" />
        <Text textAlign={'center'} p={3} fontSize="lg" fontWeight="bold">
          Filters
        </Text>

        <Divider />

        <Box p={4}>
          <CustomCheckbox
            isChecked={!hiddenMeterIds.length}
            data-testid="select-all-meters-checkbox"
            onChange={handleSelectAllMeters}
          >
            <Text minW="fit-content" px={0.5} fontWeight={600}>
              All Meters
            </Text>
          </CustomCheckbox>
          <Grid templateColumns="repeat(2, 1fr)" rowGap={2} columnGap={1} mt={2}>
            {Object.values(memoizedRawMeters).map((meter, meterIndex) => {
              return (
                <CustomCheckbox
                  key={meter.ww_device_id}
                  data-testid={`meter-filter-item-${meterIndex}`}
                  isChecked={!hiddenMeterIds.includes(meter.ww_device_id)}
                  onChange={(isChecked) => handleSelectAllCircuitsOfMeter(meter.ww_device_id, isChecked)}
                >
                  <Text minW="fit-content" px={0.5}>
                    Meter {meterIndex + 1}(M{meterIndex + 1})
                  </Text>
                </CustomCheckbox>
              );
            })}
          </Grid>
        </Box>

        {!!sourceCircuits.length && (
          <Box p={4}>
            <CustomCheckbox
              data-testid="select-all-sources-checkbox"
              isChecked={isAllSourceCircuitsSelected}
              onChange={(isChecked) => handleSelectAllCircuitsOfType(isChecked, true)}
            >
              <Text minW="fit-content" px={0.5} fontWeight={600}>
                All Sources
              </Text>
            </CustomCheckbox>
            <Grid templateColumns="repeat(2, 1fr)" rowGap={2} columnGap={1} mt={2}>
              {availableCircuits.map(({ circuitId, label, isSourceCircuit, isHidden, color }, sourceCircuitIndex) => {
                if (!isSourceCircuit) return null;
                return (
                  <CustomCheckbox
                    key={label}
                    data-testid={`source-filter-item-${sourceCircuitIndex}`}
                    isChecked={!isHidden}
                    onChange={(isChecked) => handleSelectCircuit(circuitId, isChecked)}
                  >
                    <Text bg={color} color="white" minW="fit-content" px={0.5} flex={1}>
                      {label}
                    </Text>
                  </CustomCheckbox>
                );
              })}
            </Grid>
          </Box>
        )}

        {!!loadCircuits.length && (
          <Box p={4}>
            <CustomCheckbox
              data-testid="select-all-loads-checkbox"
              isChecked={isAllLoadCircuitsSelected}
              onChange={(isChecked) => handleSelectAllCircuitsOfType(isChecked, false)}
            >
              <Text minW="fit-content" px={0.5} fontWeight={600}>
                All Loads
              </Text>
            </CustomCheckbox>
            <Grid templateColumns="repeat(2, 1fr)" rowGap={2} columnGap={1} mt={2}>
              {availableCircuits.map(({ circuitId, label, isSourceCircuit, isHidden, color }, loadCircuitIndex) => {
                if (isSourceCircuit) return null;
                return (
                  <CustomCheckbox
                    key={label}
                    data-testid={`load-filter-item-${loadCircuitIndex}`}
                    isChecked={!isHidden}
                    onChange={(isChecked) => handleSelectCircuit(circuitId, isChecked)}
                  >
                    <Text bg={color} color="white" minW="fit-content" px={0.5}>
                      {label}
                    </Text>
                  </CustomCheckbox>
                );
              })}
            </Grid>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};
