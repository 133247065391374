import { ButtonProps } from '@chakra-ui/react';

import {
  BatteryIcon,
  EvChargerIcon,
  GridConnectionIcon,
  PowerPointIcon,
  SolarBatteryHybridIcon,
  SolarPVIcon,
} from 'clipsal-cortex-icons/src/custom-appliance-icons';

import { InstalledDevice } from '../../../../api/api-device';
import { BatteryCTConfiguration } from './BatteryCTConfiguration';
import { CTConfigFormData } from './ct-configuration-form-types';
import { TabConfig } from './CTConfigurationContainer';
import { EVChargerCTConfiguration } from './EVChargerCTConfiguration';
import { GridCTConfiguration } from './GridCTConfiguration';
import { HybridBatteryCTConfiguration } from './HybridBatteryCTConfiguration';
import { LoadCTConfiguration } from './LoadCTConfiguration';
import { SolarCTConfiguration } from './SolarCTConfiguration';

export const TAB_CONFIGURATION: TabConfig[] = [
  { label: 'Grid', key: 'grid', component: GridCTConfiguration, icon: GridConnectionIcon },
  { label: 'Solar', key: 'solar', component: SolarCTConfiguration, icon: SolarPVIcon },
  { label: 'Battery', key: 'battery', component: BatteryCTConfiguration, icon: BatteryIcon },
  { label: 'Hybrid + Battery', key: 'hybrid', component: HybridBatteryCTConfiguration, icon: SolarBatteryHybridIcon },
  {
    label: 'EV',
    key: 'evCharger',
    component: EVChargerCTConfiguration,
    icon: EvChargerIcon,
  },
  { label: 'Load', key: 'load', component: LoadCTConfiguration, icon: PowerPointIcon },
];

/**
 * This function is reponsible for generating tab configuration based on devices
 * @param formValues - state of the form react hook form
 * @param inverters - inverter lists
 * @param batteries - batteries lists
 * @returns - tab configuration object
 */
export function filterTabsBasedOnDevices(
  formValues: CTConfigFormData,
  inverters: InstalledDevice[],
  batteries: InstalledDevice[]
) {
  const { hasAcCoupledInverter, hasDcCoupledInverter, hasBattery, hasEVCharger } = formValues;

  const totalDCCoupledInverters = inverters.reduce((acc, inverter) => {
    if (inverter.is_hybrid_inverter) acc += 1;
    return acc;
  }, 0);

  const filteredTabs: TabConfig[] = [];

  // Adding them in order as tab order is important
  // Always show grid tab
  filteredTabs.push(TAB_CONFIGURATION[0]);

  // Conditionally add other tabs
  if (hasAcCoupledInverter) filteredTabs.push(TAB_CONFIGURATION[1]);

  // When there is equal number of hybrid inverters and batteries,
  // it means the battery belongs to hybrid inverters and there is no extra battery
  // Likewise, if there is more batteries than hybrid inverters, need to show tab for those batteries
  if (hasBattery && batteries.length > totalDCCoupledInverters) filteredTabs.push(TAB_CONFIGURATION[2]);

  if (hasDcCoupledInverter) filteredTabs.push(TAB_CONFIGURATION[3]);

  if (hasEVCharger) filteredTabs.push(TAB_CONFIGURATION[4]);

  // Always show load tab
  filteredTabs.push(TAB_CONFIGURATION[5]);

  return filteredTabs;
}

/**
 * This function calculates min width for each tab buttons based on number of tabs
 * @param tabs - tab config object
 * @param hasDcCoupledInverter - boolean
 * @returns min-width for the tab button
 */
export function getMinTabButtonWidth(tabs: TabConfig[], hasDcCoupledInverter: boolean) {
  const totalTabs = tabs.length;

  if (totalTabs === 2) return '50%';
  if (totalTabs === 3) return hasDcCoupledInverter ? 'max-content' : '33%';
  if (totalTabs === 4) return '25%';
  if (totalTabs === 5) return '20%';
  return '16.7%';
}

/**
 * This function modifies long label names into shorter ones for small screens
 * @param tabs - tab config object
 * @param label - actual label name
 * @param isMobileViewport - boolean
 * @returns - adjusted label name
 */
export function constructTabLabel(tabs: TabConfig[], label: string, isMobileViewport: boolean | undefined) {
  const totalTabs = tabs.length;

  // shorten the name so that it fits evenly
  if (isMobileViewport && totalTabs > 3 && label === 'Hybrid + Battery') return 'Hyb+Batt';
  return label;
}

export const EMPTY_LOAD_TEMPLATE = {
  applianceId: null,
  loadName: '',
  loadType: '',
  isControlledLoad: false,
  circuits: [],
  controlDeviceRowId: null,
};

export const COMMON_NEXT_BUTTON_PROPS: ButtonProps = {
  mb: [3, 3, 4],
  ml: 'auto',
  mr: [2, 2, 4],
  colorScheme: 'primaryButton',
  rounded: 25,
  py: 5,
  type: 'button',
  pointerEvents: 'auto',
};
