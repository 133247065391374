import React, { useMemo, useState } from 'react';
import { Box, Center, Heading, Select, Text, useColorModeValue, useTheme, VStack } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { BatteryDataType, BatteryDataTypePicker } from './BatteryDataPicker';

import '../fleet-dashboard/date-range-type-picker.css';

import useMeasure from 'react-use-measure';

import { BatterySimulation } from '../../api/api-battery-upsell';

type SelfPoweredWidgetProps = {
  isMobileViewport?: boolean;
  // JSPDF cannot render shadows properly, so we need to hide it when exporting to PDF
  hideShadow?: boolean;
  simulation: BatterySimulation;
};

export const SelfPoweredWidget = ({ isMobileViewport, hideShadow, simulation }: SelfPoweredWidgetProps) => {
  const [selectedBatteryDataType, setSelectedBatteryDataType] = useState<BatteryDataType>(BatteryDataType.After);
  const theme = useTheme();
  const { textColor, background } = useColorModeValue(
    {
      textColor: theme.colors.gray[900],
      background: 'white',
    },
    {
      textColor: theme.colors.dusk100[50],
      background: 'gray.900',
    }
  );

  const { selfPoweredPercent, gridPoweredPercent, differenceAfterBatteryPercent } = useMemo(() => {
    const isBeforeBattery = selectedBatteryDataType === BatteryDataType.Before;
    const {
      self_powered_fraction_before: selfPoweredFractionBefore,
      self_powered_fraction_after: selfPoweredFractionAfter,
    } = simulation || {};

    const selfPoweredFraction = isBeforeBattery ? selfPoweredFractionBefore : selfPoweredFractionAfter;
    const differenceAfterBatteryFraction = isBeforeBattery ? 0 : selfPoweredFractionAfter - selfPoweredFractionBefore;

    const selfPoweredPercent = Math.round(selfPoweredFraction * 100);

    return {
      selfPoweredPercent: selfPoweredPercent,
      gridPoweredPercent: 100 - selfPoweredPercent,
      differenceAfterBatteryPercent: Math.round(differenceAfterBatteryFraction * 100),
    };
  }, [simulation, selectedBatteryDataType]);

  // highcharts does not render chart within container heights for some reason.
  // Most likely it is due to cumulative layout shift. Hence providing a ref
  // to the container div and using react-use-measure to get the dimensions
  const [containerRef, { height: chartHeight, width: chartWidth }] = useMeasure();

  const options: Highcharts.Options = useMemo(() => {
    return {
      series: [
        {
          name: 'Ratio',
          type: 'pie',
          innerSize: '80%',
          states: {
            inactive: {
              opacity: 0.7,
            },
          },
          borderWidth: 0,
          data: [
            {
              name: 'Grid Powered',
              color: theme.colors.slate[500],
              y: gridPoweredPercent,
            },
            {
              name: 'Self Powered',
              color: theme.colors.primaryBranding[500],
              borderColor: '#FFC49C',
              y: selfPoweredPercent,
            },
          ],
        },
      ],
      chart: {
        type: 'pie',
        plotShadow: false,
        shadow: false,
        animation: false,
        height: chartHeight,
        width: chartWidth,
        backgroundColor: 'transparent',
        spacingRight: 0,
        spacingLeft: 0,
        spacingTop: 0,
        spacingBottom: 0,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        animation: false,
        outside: true,
        valueSuffix: '%',
        style: {
          zIndex: 100,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: 0,
          endAngle: 360,
          size: '100%',
        },
      },
      legend: {
        enabled: false,
      },
    };
  }, [textColor, chartHeight, chartWidth, selfPoweredPercent, gridPoweredPercent]);

  return (
    <VStack
      bg={background}
      w="100%"
      maxW={isMobileViewport ? undefined : 400}
      minH={300}
      rounded={20}
      p={4}
      pt={2}
      mr={isMobileViewport ? 0 : 4}
      mb={isMobileViewport ? 4 : 0}
      shadow={hideShadow ? 'none' : 'md'}
      data-testid="self-powered-widget"
    >
      <Box w="100%" mt={2} fontSize={'14px'} fontWeight={'600'} px={1}>
        {isMobileViewport ? (
          <Select
            data-testid="select-battery-data-type"
            fontSize={'14px'}
            border={'none'}
            w="fit-content"
            ml="auto"
            value={selectedBatteryDataType}
            onChange={(e) => {
              setSelectedBatteryDataType(e.target.value as BatteryDataType);
            }}
          >
            <option value={BatteryDataType.Before}>Before Battery</option>
            <option value={BatteryDataType.After}>After Battery</option>
          </Select>
        ) : (
          <BatteryDataTypePicker
            selectedBatteryDataType={selectedBatteryDataType}
            setSelectedBatteryDataType={(value) => {
              setSelectedBatteryDataType(value);
            }}
          />
        )}
      </Box>

      <Center position={'relative'} w="100%" h="100%">
        <Box position={'absolute'} left={0} top={0} width={'100%'} height={'100%'} ref={containerRef}>
          <VStack position={'absolute'} left="50%" top="50%" transform="translate(-50%, -50%)">
            <Heading fontSize={20} lineHeight={4} color={'black'} _dark={{ color: 'white' }}>
              {selfPoweredPercent}%
            </Heading>
            {differenceAfterBatteryPercent && (
              <Text fontSize={13} maxW={60} textAlign={'center'} mt="0px!important">
                <Box as="span" color="primaryBranding.500">
                  +{differenceAfterBatteryPercent}%
                </Box>{' '}
                <Box as="span" color={'black'} _dark={{ color: 'white' }}>
                  more
                </Box>
              </Text>
            )}
          </VStack>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
      </Center>

      <Text color="fixedCostGrey.500" textAlign={'center'} px={4} mb={2}>
        Annual Average Self Powered
      </Text>
    </VStack>
  );
};
