import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Blocker, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { get } from '../../api/api-helpers';
import { StepProgressData } from '../../api/api-step-progress';
import { useAppDispatch } from '../../app/hooks';
import { MobileTopNav } from '../../common/components/MobileTopNav';
import { SlideBiDirectional } from '../../common/components/SlideBiDirectional';
import { COMPONENT_MIN_HEIGHT, SIDENAV_WIDTH, TOP_NAV_SPACING_AFFORDANCE } from '../../common/constants';
import { BlockerContextProvider } from '../../context/BlockerContextProvider';
import { SiteRouteChangeContext, useBlockerContext, useSupportDrawerContext } from '../../context/context-exports';
import { useNavigationState } from '../../context/NavigationProvider';
import { CloseIcon } from '../../styles/custom-icons';
import { selectDashboardSites } from '../dashboard/dashboardSlice';
import { Wizard } from '../wizard/Wizard';
import {
  completeCurrentStep,
  selectStep,
  selectWizard,
  setLoaded,
  setStepOptional,
  STEP_PROGRESS_MAP_TO_COMPONENT_NAME,
  updateStepsFromProgress,
} from '../wizard/wizardSlice';
import { fetchSite, selectSite } from './siteSlice';

export type SiteRouteChangeContextProps = {
  onMoveForward: (siteId?: number) => void;
};

export function Site() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const wizard = useSelector(selectWizard);
  const site = useSelector(selectSite);
  const dashboard = useSelector(selectDashboardSites);
  const navigate = useNavigate();
  const { navigationState } = useNavigationState();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const title = site.site_name || wizard.steps[wizard.currentStepIndex].title;
  const TRANSITION_TIMEOUT_MS = 150;

  const getWizardStep = useCallback(
    (currentStep: StepProgressData) => {
      const currentStepNumber = currentStep.step;

      // turn the wizard steps into an array of step numbers
      const wizardStepNumbers = wizard.steps.map((step) => step.number);
      const indexOfWizardStep = wizardStepNumbers.indexOf(currentStepNumber);

      // if index found, return the step
      if (indexOfWizardStep > -1) {
        return wizard.steps[indexOfWizardStep];
      } else {
        // if currentStepNumber is greater than the largest number, return the last step
        if (currentStepNumber > wizardStepNumbers[wizardStepNumbers.length - 1]) {
          return wizard.steps[wizard.steps.length - 1];
        } else {
          // else find the step number greater than currentStepNumber
          const greaterStepNumberIndex = wizardStepNumbers.findIndex((stepNum) => stepNum >= currentStepNumber);
          return wizard.steps[greaterStepNumberIndex > -1 ? greaterStepNumberIndex : 0];
        }
      }
    },
    [wizard.steps]
  );

  const getStepNumberBasedOnUrl = useCallback(() => {
    let stepNumber = 1;
    if (pathname.includes('start')) stepNumber = 1;
    if (pathname.includes('system_details')) stepNumber = 2;
    if (pathname.includes('meter_setup')) stepNumber = 3;
    if (pathname.includes('customer_details')) stepNumber = 4;
    return stepNumber;
  }, [pathname]);

  useEffect(() => {
    async function fetchAPI() {
      const apiSite = unwrapResult(await dispatch(fetchSite(Number(id))));
      const progress = await get<StepProgressData[]>(`/fleet/sites/${apiSite.clipsal_solar_id}/commissioning_progress`);
      // Find the first incomplete step
      const currentStep = progress.find((step) => step.status === 'INCOMPLETE');

      const selectedStep = currentStep ? getWizardStep(currentStep) : wizard.steps[wizard.steps.length - 1];

      const updatedProgress = progress.reduce<StepProgressData[]>((allStepProgress, stepProgress) => {
        if (
          wizard.steps.find(
            (p) =>
              stepProgress.name !== 'Invite Customer' &&
              p.componentName === STEP_PROGRESS_MAP_TO_COMPONENT_NAME[stepProgress.name]
          )
        )
          allStepProgress.push(stepProgress);
        return allStepProgress;
      }, []);
      dispatch(updateStepsFromProgress(updatedProgress));

      // System details are optional if this site is consumption only.
      const indexOfSystemDetailsStep = wizard.steps.findIndex(({ title }) => title === 'System Details');
      if (indexOfSystemDetailsStep > -1 && apiSite.is_consumption_only)
        dispatch(setStepOptional(indexOfSystemDetailsStep));

      dispatch(selectStep({ stepIndex: wizard.steps.indexOf(selectedStep), isInitialLoad: true }));
      dispatch(setLoaded());

      // if the step from url is more than current maximum completed step,
      // then redirect to the current maximum completed step
      const stepNumberBasedOnUrl = getStepNumberBasedOnUrl();
      const isStepBasedOnUrlInvalid = stepNumberBasedOnUrl > selectedStep.number;

      // only redirect if url specifies it
      const shouldRedirect = searchParams.get('redirect') === 'true' || isStepBasedOnUrlInvalid;
      const url = shouldRedirect ? `/site/${id}/${selectedStep.route}` : pathname;
      navigate(url, { replace: true });
    }

    if (!wizard.isLoaded) {
      if (id !== 'new') {
        fetchAPI();
      } else {
        dispatch(setLoaded());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGoBack() {
    navigate(dashboard.lastVisitedSitePath);
  }

  const handleMoveForward = useCallback(
    (siteId?: number) => {
      const id = siteId ?? site.clipsal_solar_id;
      dispatch(completeCurrentStep());

      toast({
        title: 'Step complete!',
        description: `${wizard.steps[wizard.currentStepIndex].title} complete.`,
        status: 'success',
        isClosable: true,
      });

      // If this is the last step, redirect to the dashboard
      if (wizard.currentStepIndex === wizard.steps.length - 1) {
        navigate('/');
        return;
      }

      navigate(`/site/${id}/${wizard.steps[wizard.currentStepIndex + 1].route}`);
      window.scrollTo({ top: 0 });
    },
    [dispatch, site.clipsal_solar_id, toast, wizard.currentStepIndex, wizard.steps]
  );

  const siteRouteChangeContextValue = useMemo(
    () => ({
      onMoveForward: handleMoveForward,
    }),
    [handleMoveForward]
  );

  const blockerContext = useMemo(
    () => ({
      unBlock: (blocker: Blocker) => {
        if (blocker.state === 'blocked' && !wizard.hasUnsavedChanges) blocker.proceed();
      },
      disabled: wizard.hasUnsavedChanges,
    }),
    [wizard.hasUnsavedChanges]
  );

  const direction = useMemo(() => {
    const isWizard = ['start', 'customer_details', 'meter_setup', 'system_details'].includes(pathname.split('/')[3]);
    if (isWizard) {
      if (wizard.direction === 1) return 'left';
    } else {
      if (navigationState.direction === 'forward') return 'left';
      if (navigationState.direction === 'backward') return 'right';
    }
    return 'right';
  }, [pathname, wizard.direction, navigationState.direction]);

  if ((id !== 'new' && !site.isLoaded) || !wizard.isLoaded) return <CenteredLoader isFullHeight />;

  return (
    <Box minHeight={`calc(${COMPONENT_MIN_HEIGHT} - ${TOP_NAV_SPACING_AFFORDANCE})`}>
      <BlockerContextProvider value={blockerContext}>
        <SiteTopNavigation {...{ handleGoBack, title }} />
        <Wizard />
        <Box width={'100%'}>
          <SiteRouteChangeContext.Provider value={siteRouteChangeContextValue}>
            <SlideBiDirectional direction={direction} transitionTimeMs={TRANSITION_TIMEOUT_MS}>
              <Outlet />
            </SlideBiDirectional>
          </SiteRouteChangeContext.Provider>
        </Box>
        <ConfirmLeaveDialog />
      </BlockerContextProvider>
    </Box>
  );
}

type SiteTopNavigationProps = {
  handleGoBack: () => void;
  title: string;
};

function SiteTopNavigation({ handleGoBack, title }: SiteTopNavigationProps) {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const bgColor = useColorModeValue('customBodyBackground.500', 'customBodyBackground.800');
  const { onOpenSupportDrawer } = useSupportDrawerContext();
  const { pathname } = useLocation();
  const isOnSiteRoute = pathname.includes('site');
  const isOnMeterSetupSubRoute = /\/meter_setup\/configure\//.test(pathname);

  if (isOnMeterSetupSubRoute) return null;

  // hide site top nav cancel button for sub routes of meter setup page
  // reason being users were mistakenly clicking cancel button
  // and going straight to the dashboard without saving the changes
  return isMobileViewport ? (
    <MobileTopNav onGoBack={handleGoBack} backURL={'/'} title={title} icon={<CloseIcon h={5} w={5} />} />
  ) : (
    <Flex
      align="center"
      p={2}
      position={'fixed'}
      top={0}
      right={0}
      zIndex={10}
      width={`calc(100vw - ${SIDENAV_WIDTH} - 25px)`}
      bg={bgColor}
      data-testid="desktop-site-top-nav"
    >
      <Flex w="100%" position={'relative'} maxWidth={`calc(100vw - ${SIDENAV_WIDTH} - 90px)`}>
        <ConfirmGoBackDialog handleGoBack={handleGoBack} />
        <Text
          fontWeight={'bold'}
          fontSize="lg"
          ml="auto"
          position="absolute"
          top="50%"
          left=" 50%"
          transform="translate(-50%, -50%)"
        >
          {title}
        </Text>
        {isOnSiteRoute && (
          <Button
            variant="link"
            color="customLinkBlue.500"
            ml="auto"
            mr={4}
            onClick={onOpenSupportDrawer}
            data-testid="help-button"
          >
            Help
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export function ConfirmGoBackDialog({ handleGoBack }: { handleGoBack: () => void }) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });

  return (
    <>
      <Button data-testid="nav-back-to-dashboard-btn" variant="ghost" size="sm" onClick={onOpen} pt={1}>
        <CloseIcon w={5} h={5} />
        <Text mr={isMobileViewport ? 0 : 2}>{isMobileViewport ? '' : 'Back to dashboard'}</Text>
      </Button>

      <AlertDialogModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleGoBack}
        onCancel={onClose}
        header="Exit site configuration"
        subHeader="Are you sure you want to exit the edit site configuration?"
        confirmButtonName="Exit"
        cancelButtonName="Cancel"
        confirmButtonTestId="confirm-go-back-to-dashboard-btn"
        cancelButtonTestId="cancel-go-back-to-dashboard-btn"
        dialogContentTestId="confirm-back-to-dashboard-dialog"
      />
    </>
  );
}

function ConfirmLeaveDialog() {
  const { hasUnsavedChanges } = useSelector(selectWizard);
  const { blocker } = useBlockerContext();

  return blocker?.state === 'blocked' && hasUnsavedChanges ? (
    <AlertDialogModal
      isOpen={true}
      onClose={() => blocker.reset()}
      onConfirm={() => blocker.proceed()}
      onCancel={() => blocker.reset()}
      header="Unsaved Changes"
      subHeader="There are unsaved changes, are you sure you want to move away?"
      confirmButtonName="Leave"
      cancelButtonName="Cancel"
    />
  ) : null;
}
