import React, { FormEvent, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomButton } from '../../../../../common/components/CustomButton';
import { selectAssignments } from '../../../system-details/systemDetailsSlice';
import { SelectSortedAssignments } from '../circuit-configuration/SelectSortedAssignments';
import { addAppliance } from '../circuitApplianceConfigSlice';

const INITIAL_STATE = { name: '', type: '' };

export function AddApplianceButton() {
  const assignments = useSelector(selectAssignments);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applianceDetails, setApplianceDetails] = useState(INITIAL_STATE);
  const dispatch = useDispatch();

  const handleAddAppliance = () => {
    setApplianceDetails(INITIAL_STATE);
    onOpen();
  };

  const handleSelectApplicanceType = (newApplianceType: string) => {
    setApplianceDetails({
      ...applianceDetails,
      type: newApplianceType,
      name: assignments.find(({ assignment }) => assignment === newApplianceType)?.display_name ?? '',
    });
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    dispatch(addAppliance(applianceDetails));
    onClose();
  };

  return (
    <Box>
      <Button
        leftIcon={<AddIcon w={3} h={3} _dark={{ color: 'white' }} />}
        variant="ghost"
        fontWeight="normal"
        color="gray.700"
        _dark={{ color: 'gray.400' }}
        onClick={handleAddAppliance}
        data-testid="add-appliance-button"
      >
        Add Appliance
      </Button>

      <Modal size={'sm'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent data-testid="add-appliance-modal" mx={5}>
          <ModalCloseButton data-testid="close-add-appliance-modal" />
          <ModalHeader>Add Appliance</ModalHeader>
          <ModalBody>
            <Box onSubmit={handleSubmitForm} as={'form'} data-testid="add-appliance-form">
              <VStack w="100%" align={'flex-start'} spacing={0} mb={2}>
                <FormLabel mb={0}>Monitor</FormLabel>
                <SelectSortedAssignments
                  value={applianceDetails.type}
                  onChange={handleSelectApplicanceType}
                  dataTestId="select-appliance-type"
                />
              </VStack>

              <VStack w="100%" align={'flex-start'} spacing={0}>
                <FormLabel mb={0}>Name</FormLabel>
                <Input
                  placeholder="Enter appliance name"
                  value={applianceDetails.name}
                  onChange={(e) => setApplianceDetails({ ...applianceDetails, name: e.target.value })}
                  data-testid="input-appliance-name"
                />
              </VStack>

              <CustomButton
                isDisabled={!applianceDetails.name || !applianceDetails.type}
                data-testid="add-new-appliance-button"
              >
                Add Appliance
              </CustomButton>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
