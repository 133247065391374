import React from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { Controller, useFieldArray } from 'react-hook-form';

import { OriginalWattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { MultiToggleSwitch } from '../../../../../common/components/MultiToggleSwitch';
import { POLARITY_TYPES } from '../../../../site/meter-setup/meter-configuration/meter-config-icon-map';
import { DISPLAYED_VOLTAGE_REF_TYPES } from '../../../../site/meter-setup/meter-setup-helpers';
import { useChannelCategories } from '../meterConfigApi';
import { useMeterFormContext } from './useMeterFormContext';

const VOLTATE_REF_TYPES = DISPLAYED_VOLTAGE_REF_TYPES['3ph'];
const CT_RATINGS = [60, 120, 200, 400, 600];

export const ChannelConfiguration = ({ meter }: { meter: OriginalWattwatchersMeter }) => {
  const { data: channels, isLoading } = useChannelCategories();
  const { control, errors, register, setValue } = useMeterFormContext();
  const { fields } = useFieldArray({
    name: 'channels',
    control,
  });
  const { switchClassName } = useColorModeValue(
    {
      switchClassName: 'light-mode-toggle-switch',
    },
    {
      switchClassName: 'dark-mode-toggle-switch-2',
    }
  );

  return (
    <Box>
      <Heading size="md" fontWeight={600} mb={4}>
        Channel Configuration
      </Heading>
      {fields.map((field, index) => {
        return (
          <Box key={field.id}>
            <Flex w="100%" justify={'space-between'} wrap={'wrap'} gap={2}>
              <FormControl isInvalid={!!errors?.channels?.[index]?.id} id="id" w={180}>
                <FormLabel>Channel</FormLabel>
                <Input
                  data-testid={`channels-${index}-id`}
                  {...register(`channels.${index}.id`)}
                  isDisabled
                  isReadOnly
                  cursor={'not-allowed'}
                  _disabled={{ opacity: 0.6, background: 'gray.100', _dark: { background: 'gray.900' } }}
                />
                <FormErrorMessage>{errors?.channels?.[index]?.id?.message ?? 'This is required'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.channels?.[index]?.label} id="label" w={170}>
                <FormLabel>Label</FormLabel>
                <Input data-testid={`channels-${index}-label`} {...register(`channels.${index}.label`)} />
                <FormErrorMessage>{errors?.channels?.[index]?.label?.message ?? 'This is required'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.channels?.[index]?.categoryId} id="categoryId" w={200}>
                <FormLabel>Circuit Type</FormLabel>
                <Controller
                  control={control}
                  name={`channels.${index}.categoryId`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={isLoading}
                      placeholder={isLoading ? 'Loading...' : 'Select...'}
                      data-testid={`channels-${index}-categoryId`}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                        setValue(`channels.${index}.categoryLabel`, e.target.selectedOptions[0].text);
                      }}
                    >
                      {isLoading && (
                        <option value="" disabled>
                          Loading...
                        </option>
                      )}
                      {channels.map(({ id, label }) => (
                        <option key={id} value={id}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  )}
                />

                <FormErrorMessage>
                  {errors?.channels?.[index]?.categoryId?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.channels?.[index]?.ctRating} id="ctRating" w={85}>
                <FormLabel>CT Rating</FormLabel>
                <Select
                  data-testid={`channels-${index}-ctRating`}
                  {...register(`channels.${index}.ctRating`)}
                  isDisabled={!!meter.channels?.[index].pending?.ctRating}
                >
                  {CT_RATINGS.map((rating) => (
                    <option key={rating} value={rating}>
                      {rating}
                    </option>
                  ))}
                </Select>
                {meter.channels?.[index].pending?.ctRating && (
                  <FormHelperText fontSize={'xs'} data-testid={`channels-${index}-ctRating-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors?.channels?.[index]?.ctRating?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.channels?.[index]?.polarity} id="polarity" width={100}>
                <FormLabel>Polarity</FormLabel>
                <Controller
                  control={control}
                  name={`channels.${index}.polarity`}
                  render={({ field: { onChange, value } }) => {
                    // Initialize default value
                    if (!value) onChange(POLARITY_TYPES[0].value);
                    return (
                      <Box w="100%" fontSize="xs" fontWeight="bold" className={switchClassName}>
                        <MultiToggleSwitch
                          data-testid={`channels-${index}-polarity`}
                          switchOptions={POLARITY_TYPES}
                          fontSize="xs"
                          value={value}
                          onChange={(value) => onChange(value)}
                          disabled={!!meter.channels[index].pending?.polarity}
                          enableBottomLabel={false}
                          label="Polarity"
                          customClassName="container-height-small"
                        />
                      </Box>
                    );
                  }}
                />
                {meter.channels?.[index].pending?.polarity && (
                  <FormHelperText fontSize={'xs'} data-testid={`channels-${index}-polarity-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors?.channels?.[index]?.polarity?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.channels?.[index]?.voltageReference} id="voltageReference" width={150}>
                <FormLabel>Voltage Reference</FormLabel>
                <Controller
                  control={control}
                  name={`channels.${index}.voltageReference`}
                  render={({ field: { onChange, value } }) => {
                    // Initialize default value
                    if (!value) onChange(VOLTATE_REF_TYPES[0].value);
                    return (
                      <Box w="100%" fontSize="xs" fontWeight="bold" className={switchClassName}>
                        <MultiToggleSwitch
                          data-testid={`channels-${index}-voltageReference`}
                          switchOptions={VOLTATE_REF_TYPES}
                          fontSize="xs"
                          value={value}
                          onChange={(value) => onChange(value)}
                          disabled={!!meter.channels[index].pending?.voltageReference}
                          enableBottomLabel={false}
                          label="Phase"
                          customClassName="container-height-small"
                        />
                      </Box>
                    );
                  }}
                />
                {meter.channels?.[index].pending?.voltageReference && (
                  <FormHelperText fontSize={'xs'} data-testid={`channels-${index}-voltageReference-pending`}>
                    <Spinner size="xs" /> Updating...
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors?.channels?.[index]?.voltageReference?.message ?? 'This is required'}
                </FormErrorMessage>
              </FormControl>
            </Flex>
            <Divider my={4} />
          </Box>
        );
      })}
    </Box>
  );
};
