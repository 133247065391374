import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSiteAppliances, useSiteCircuits, useSiteMeters } from '../meterSetupApi';
import { SubRouteTopNav } from '../SubRouteTopNav';
import { ApplianceConfiguration } from './appliance-configuration/ApplianceConfiguration';
import { CircuitConfiguration } from './circuit-configuration/CircuitConfiguration';
import { setAllAppliances, setAllCircuits, setSelectedMeterId } from './circuitApplianceConfigSlice';
import { SaveCircuitsAndApplianceButton } from './SaveCircuitsAndApplianceButton';

export const CircuitApplianceConfiguration = () => {
  const { siteMeters } = useSiteMeters();
  const navigate = useNavigate();
  const { siteAppliances, isLoadingSiteAppliances } = useSiteAppliances();
  const { siteCircuits, isLoadingSiteCircuits } = useSiteCircuits();
  const isLoading = isLoadingSiteAppliances || isLoadingSiteCircuits;
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultSelectedMeterId = siteMeters[0]?.site_identifier;
    if (!isLoadingSiteCircuits && !isLoadingSiteAppliances && defaultSelectedMeterId) {
      dispatch(setSelectedMeterId(defaultSelectedMeterId));
      dispatch(setAllCircuits(siteCircuits));
      dispatch(setAllAppliances(cloneDeep(siteAppliances).sort((a, b) => b.appliance_id - a.appliance_id)));
    }

    // We don't want to run this every time siteCircuits or siteAppliances change
    // As this will cause weird UI flickers since they are not updated at the same time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteMeters, isLoadingSiteCircuits, isLoadingSiteAppliances]);

  return (
    <Box w="100%" data-testid="circuit-appliance-configuration-form">
      <SubRouteTopNav onGoBack={() => navigate(`../../meters`)} title="Circuits & Appliance Configuration" />

      <CircuitConfiguration isLoading={isLoading} />
      <ApplianceConfiguration isLoading={isLoading} />

      <SaveCircuitsAndApplianceButton isDisabled={isLoadingSiteCircuits} />
    </Box>
  );
};
