import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useForm } from 'react-hook-form';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { AddressPinIcon } from 'clipsal-cortex-icons/src/custom-icons';

import { post } from '../../api/api-helpers';
import { useAppDispatch } from '../../app/hooks';
import { CustomButton } from '../../common/components/CustomButton';
import { ModalProps } from '../../common/types/types';
import { ManualAddressDialog, ManualAddressFormData } from '../site/site-details/ManualAddressDialog';
import { fetchUserDetails } from '../user/userSlice';
import { ReactComponent as LogoDark } from './../../assets/images/clipsal_logo_dark.svg';
import { ReactComponent as Logo } from './../../assets/images/clipsal_logo.svg';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

const initialState = {
  businessName: '',
  address: '',
  city: '',
  state: '',
  postCode: '',
  country: '',
};

const schema = yup.object().shape({
  businessName: yup.string().trim().required('*Business Name is required'),
  address: yup.string().trim().required('*Business Address is required'),
  city: yup.string().trim().required('*City is required'),
  state: yup.string().trim().required('*State is required'),
  postCode: yup.string().trim().required('*Post Code is required'),
  country: yup.string().trim().required('*Country is required'),
});

export const BusinessDetailsModal = ({ isOpen, onClose }: ModalProps) => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const {
    isOpen: isManualAddressDialogOpen,
    onOpen: onOpenManualAddressDialog,
    onClose: onCloseManualAddressDialog,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isFormFilled, setIsFormFilled] = useState(false);

  // Check if all fields are filled and enable submit button.
  // Using subscription so that we do not rerender whole component on value changes
  useEffect(() => {
    const subscription = watch((values) => {
      const isFormFilled = Object.values(values).every((value) => !!value);
      setIsFormFilled(isFormFilled);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // Check if a user was deleted in cognito
    // To ensure users are not stuck on this modal due to cache issues
    const checkIfUserIsNotAuthenticated = async () => {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
      } catch (error) {
        if (typeof error === 'string' && error === 'The user is not authenticated') {
          // reloading should remove any remaining cache and take user back to login page
          window.location.reload();
        }
      }
    };

    checkIfUserIsNotAuthenticated();
  }, [location]);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: ['AU', 'NZ'] },
    } as any,
  });

  const { predictionHoverColor, LogoIcon } = useColorModeValue(
    { predictionHoverColor: 'gray.50', LogoIcon: Logo },
    {
      predictionHoverColor: 'gray.700',
      LogoIcon: LogoDark,
    }
  );

  async function handleSubmit(values: typeof initialState) {
    setIsLoading(true);

    try {
      await post('/fleet/complete_signup', {
        tenant_name: values.businessName,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.postCode,
      });

      await dispatch(fetchUserDetails());
      setIsLoading(false);
      onClose();
      toast({ title: 'Successfully saved business details!', status: 'success', isClosable: true });
      navigate('/');
    } catch (error) {
      const toastConfig = {
        title: 'Something went wrong!',
        description: 'Please try again. If this issue persists, please contact support.',
      };

      const errorStatus = (error as any)?.response?.status;
      const errorType = (error as any)?.response?.data?.type;

      if (errorStatus === 403 && errorType === 'USER_ALREADY_EXISTS') {
        toastConfig.title = 'User already exists!';
        toastConfig.description = 'Please try resetting password to reset password, or contact us for support.';
      }

      toast({
        ...toastConfig,
        status: 'error',
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  async function handleSelectLocation(location: AutocompletePrediction) {
    const currentValues = getValues();

    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      // iterate over address components to populate location data
      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const termsLength = location.terms.length;

      reset({
        ...currentValues,
        address: location.description,
        city: locationData.city || location.terms[termsLength - 3]?.value,
        state: locationData.state || location.terms[termsLength - 2]?.value,
        country: locationData.country || location.terms[termsLength - 1]?.value,
        postCode: locationData.postCode,
      });

      setShouldDisplayAutocompleteOptions(false);
    });
  }

  function handleSubmitManualAddress({ country, streetAddress, unit, suburb, state, postcode }: ManualAddressFormData) {
    reset({
      ...getValues(),
      address: `${unit ? unit + '/' : ''}${streetAddress}, ${suburb}, ${state}`,
      city: suburb,
      state,
      country,
      postCode: postcode.toString(),
    });

    onCloseManualAddressDialog();
    setShouldDisplayAutocompleteOptions(false);
  }

  function PredictionsList() {
    return placePredictions.length ? (
      <Box width={'100%'} mt={2} py={3} data-testid="business-address-prediction-list">
        <Heading mb={1} size={'md'}>
          Results
        </Heading>
        {placePredictions.map((location, i) => (
          <Box
            data-testid={`business-address-prediction-${i}`}
            className={'business-address-prediction-item'}
            borderBottom={i === placePredictions.length - 1 ? '1px solid grey' : undefined}
            borderTop={'1px solid grey'}
            key={`google-maps-location-${i}`}
            _hover={{ background: predictionHoverColor }}
            onClick={async () => handleSelectLocation(location)}
            cursor={'pointer'}
            py={2}
          >
            <Flex align={'center'} as={'button'} type={'button'}>
              <Box mr={1}>
                <FaMapMarkerAlt />
              </Box>
              <Text fontWeight={600} fontSize={'sm'}>
                {location.description}
              </Text>
            </Flex>
          </Box>
        ))}
        <Text mt={2} fontSize={'xs'}>
          Powered by Google
        </Text>
      </Box>
    ) : null;
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      size={'sm'}
      isOpen={isOpen}
      onClose={() => undefined}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent data-testid="business-details-modal" mx={5}>
        <ModalHeader>
          <Flex direction={'column'} align="center">
            <Box cursor={'pointer'} my={5} w={'60%'}>
              <LogoIcon />
            </Box>
            <Text fontSize={'md'}>Let’s get started!</Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'} data-testid="business-details-form">
            <FormControl isInvalid={!!errors.businessName}>
              <FormLabel>Business name</FormLabel>
              <Input {...register('businessName')} placeholder="Enter business name" data-testid="business-name" />
              <FormErrorMessage>{errors.businessName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.address} mt={4}>
              <FormLabel>Business address</FormLabel>
              <InputGroup>
                <Input
                  {...register('address')}
                  onChange={(e) => {
                    getPlacePredictions({ input: e.currentTarget.value });
                    setValue('address', e.currentTarget.value);
                    setShouldDisplayAutocompleteOptions(true);
                  }}
                  placeholder={'12/22 George Street, Sydney, NSW'}
                  type="text"
                  data-private
                  autoComplete="off"
                  data-testid="business-address"
                />
                <InputRightElement>
                  <AddressPinIcon w={5} h={5} color="customBlue.500" />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
            </FormControl>

            <Box
              data-testid="manual-address-button"
              onClick={onOpenManualAddressDialog}
              as="button"
              type="button"
              mt={1}
              fontSize="md"
              color="customBlue.500"
            >
              Enter address manually
            </Box>

            {/* Site address autocomplete */}
            <Box w={'100%'}>
              {!isPlacePredictionsLoading ? (
                shouldDisplayAutocompleteOptions && <PredictionsList />
              ) : (
                <Flex direction={'column'} justify={'center'} align={'center'}>
                  <Spinner size={'lg'} />
                  <Text fontSize={'sm'}>Loading suggestions...</Text>
                </Flex>
              )}
            </Box>

            <CustomButton
              data-testid="submit-business-details-button"
              isLoading={isLoading}
              loadingText={'Submitting...'}
              w="100%"
              maxW={320}
              rounded={3}
              py={6}
              mt={4}
              isDisabled={!isFormFilled}
            >
              Submit
            </CustomButton>
          </Box>

          {isManualAddressDialogOpen && (
            <ManualAddressDialog
              currentValues={{
                country: getValues().country,
                address: getValues().address,
                postCode: getValues().postCode,
              }}
              isOpen={isManualAddressDialogOpen}
              onClose={onCloseManualAddressDialog}
              onSubmitManualAddress={handleSubmitManualAddress}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
