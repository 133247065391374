import React, { useCallback, useState } from 'react';
import { ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { ReactComponent as HybridWiringDiagram } from '../../../../assets/images/hybrid_wiring_diagram.svg';
import { CustomButton } from '../../../../common/components/CustomButton';
import { InfoBottomDrawer } from '../../../../common/components/InfoBottomDrawer';
import { MeterCTSelect } from '../../../../common/components/MeterCTSelect';
import { SIDENAV_WIDTH } from '../../../../common/constants';
import { BinIcon } from '../../../../styles/custom-icons';
import { selectRawMeters } from '../meterSetupSlice';
import { CTConfigFormData } from './ct-configuration-form-types';
import { COMMON_NEXT_BUTTON_PROPS } from './ct-configuration-helpers';
import { TabComponentProps } from './CTConfigurationContainer';
import { FieldIconWrapper } from './FieldIconWrapper';
import { EmptySelectedCircuitsCard, SelectedCircuitsCard } from './SelectedCircuitsCard';

export function HybridBatteryCTConfiguration(props: TabComponentProps) {
  const { setValue, clearErrors, control, errors, isUpdatingCTs, updateCTFunctions } = props;

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  const { hasSecondACOutput } = useWatch({ control });

  const [expandedIndex, setExpandedIndex] = useState(0);
  const [isRemoveDrawerOpen, setIsRemoveDrawerOpen] = useState(false);
  const [isWiringDrawerOpen, setIsWiringDrawerOpen] = useState(false);

  const handleBackupActions = useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (hasSecondACOutput) {
        setIsRemoveDrawerOpen(true);
      } else {
        setValue(`hasSecondACOutput`, true);
        setExpandedIndex(1);
      }
    },
    [hasSecondACOutput, setValue]
  );

  const hybridInverterError = (errors?.hybrid?.inverter as any)?.message;
  const backupCircuitError = (errors?.hybrid?.backup as any)?.message;

  return (
    <Flex direction="column" data-testid="hybrid-tab-panel">
      <Accordion
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => {
          if (!hasSecondACOutput && clickedIndex === 1) return;
          setExpandedIndex(clickedIndex);
        }}
      >
        <AccordionItem data-testid={`hybrid-inverter`}>
          <AccordionButton role={'button'} as={Box} px={[1, 4]} data-testid="toggle-hybrid-inverter">
            <Flex w={'100%'} justify={'space-between'} align={'center'}>
              <Flex direction="column">
                <Flex align="center">
                  <Heading size="sm" fontWeight="500">
                    Hybrid Inverter [Hyb]
                  </Heading>
                  {hybridInverterError && <WarningIcon color={'red.500'} w={5} h={5} ml={2} />}
                </Flex>
                <Text color="dusk.100" mt={2}>
                  Select which CT’s are measuring the Hybrid inverter
                </Text>
              </Flex>
              <Flex align={'center'}>
                <AccordionIcon />
              </Flex>
            </Flex>
          </AccordionButton>

          <AccordionPanel pb={4} px={[1, 4]}>
            {hybridInverterError && (
              <Text color="red.500" mt={2}>
                {hybridInverterError}
              </Text>
            )}
            <NestedHybridBatteryCTConfigurationFieldArray {...props} hybridCircuitType="inverter" />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem data-testid={`hybrid-backup`}>
          <AccordionButton role={'button'} as={Box} px={[1, 4]} data-testid="toggle-hybrid-backup">
            <Flex w={'100%'} justify={'space-between'} align={'center'}>
              <Flex direction="column" w="100%">
                <Flex align="center">
                  <Heading size="sm" fontWeight="500">
                    Backup Circuit [BUP]
                  </Heading>
                  {backupCircuitError && <WarningIcon color={'red.500'} w={5} h={5} ml={2} />}
                  <Flex align={'center'} ml="auto">
                    <Button
                      variant={'ghost'}
                      size={'xs'}
                      mr={2}
                      color={hasSecondACOutput ? 'red.600' : 'primaryBrandingStatic.500'}
                      mb="auto"
                      data-testid={`${hasSecondACOutput ? 'remove' : 'add'}-backup-circuit-button`}
                      aria-label={`${hasSecondACOutput ? 'Remove' : 'Add'} Backup CT`}
                      fontWeight="700"
                      onClick={handleBackupActions}
                    >
                      {hasSecondACOutput ? 'Remove' : 'Add'}
                    </Button>
                    {hasSecondACOutput && <AccordionIcon />}
                  </Flex>
                </Flex>
                <Text color="dusk.100" mt={2} pr={2}>
                  {hasSecondACOutput
                    ? 'Select which CT’s are measuring the Backup Circuit'
                    : 'Add CT to the backup circuit if the hybrid inverter has a second AC output for a backup circuit'}
                </Text>
                {!hasSecondACOutput && (
                  <Text
                    color="customLinkBlue.500"
                    mt={2}
                    _hover={{ textDecor: 'underline' }}
                    onClick={() => setIsWiringDrawerOpen(true)}
                  >
                    Show the different hybrid inverter wiring types
                  </Text>
                )}
              </Flex>
            </Flex>
          </AccordionButton>

          <AccordionPanel pb={4} px={[1, 4]}>
            {backupCircuitError && (
              <Text color="red.500" mt={2}>
                {backupCircuitError}
              </Text>
            )}
            <NestedHybridBatteryCTConfigurationFieldArray {...props} hybridCircuitType="backup" />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Flex
        position={'fixed'}
        bottom={16}
        left={0}
        zIndex={99}
        width="100%"
        justifyContent={'center'}
        paddingLeft={isMobileViewport ? 0 : SIDENAV_WIDTH}
        pointerEvents="none"
      >
        <Flex width={isMobileViewport ? '100%' : '60%'}>
          <Button
            data-testid="submit-hybrid-button"
            aria-label="Submit Hybrid + Battery"
            rightIcon={isUpdatingCTs ? <Spinner size="sm" /> : <ArrowForwardIcon />}
            disabled={isUpdatingCTs}
            {...COMMON_NEXT_BUTTON_PROPS}
            onClick={() => updateCTFunctions['hybrid']()}
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <InfoBottomDrawer
        useDefaultLayout
        heading="Are you sure you want to Remove the the Backup circuit?"
        // eslint-disable-next-line max-len
        subHeading="By removing this you are confirming that your Hybrid inverter doesn’t have a second AC output for a backup circuit and this may change your testing"
        isOpen={isRemoveDrawerOpen}
        drawerHeight="440px"
        onClose={() => setIsRemoveDrawerOpen(false)}
        icon={<BinIcon w={16} h={16} />}
      >
        <CustomButton
          data-testid="confirm-remove-backup-button"
          onClick={() => {
            setValue(`hasSecondACOutput`, false);
            setValue(`hybrid.backup`, []);
            clearErrors(`hybrid.backup`);
            setExpandedIndex(0);
            setIsRemoveDrawerOpen(false);
          }}
        >
          Remove
        </CustomButton>
      </InfoBottomDrawer>
      <InfoBottomDrawer isOpen={isWiringDrawerOpen} drawerHeight="80vh" onClose={() => setIsWiringDrawerOpen(false)}>
        <Text ml={6} my={4} fontWeight="bold">
          Hybrid inverter wiring type
        </Text>
        <Center w="100%">
          <HybridWiringDiagram style={{ height: '70vh' }} />
        </Center>
      </InfoBottomDrawer>
    </Flex>
  );
}

export function NestedHybridBatteryCTConfigurationFieldArray({
  control,
  hybridCircuitType,
  register,
  circuitState,
  onCircuitChange,
  errors,
  trigger,
}: TabComponentProps & {
  hybridCircuitType: 'inverter' | 'backup';
}) {
  const rawMeters = useSelector(selectRawMeters);
  const { fields, append, remove } = useFieldArray({
    name: `hybrid.${hybridCircuitType}`,
    control,
  });
  const { values } = circuitState;
  const clipsalMonitor = hybridCircuitType === 'inverter' ? 'hybrid_inverter' : 'backup_circuit';

  const Icon = CIRCUIT_TYPE_TO_ICON[clipsalMonitor];
  const FieldIcon = (
    <FieldIconWrapper>
      <Icon w={6} h={6} />
    </FieldIconWrapper>
  );

  const { grid, solar, load, hybrid, battery } = useWatch({ control }) as CTConfigFormData;

  const reservedCts = [
    ...grid,
    ...solar.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
    ...load.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
    ...hybrid[hybridCircuitType === 'backup' ? 'inverter' : 'backup'],
    ...battery.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
  ];

  function handleSelectCircuit(circuitData: ChannelData) {
    // returns index if found otherwise returns -1
    const indexOfCTField = fields.findIndex((selectedCT) => circuitData.ww_circuit_id === selectedCT.ww_circuit_id);

    if (indexOfCTField >= 0) {
      remove(indexOfCTField);
    } else {
      const channel = {
        ...circuitData,
        circuit_name: hybridCircuitType === 'inverter' ? 'Hybrid Inverter' : 'Backup Circuit',
        clipsal_monitors: clipsalMonitor,
      };
      append(channel, { shouldFocus: false });
    }

    // revalidate form if there is any errors for hybrid tab
    if (errors?.hybrid?.inverter) trigger('hybrid.inverter');
    if (errors?.hybrid?.backup) trigger('hybrid.backup');
  }

  return (
    <Box data-testid={`hybrid-${hybridCircuitType}-meter-select`} pt={4}>
      {Object.values(rawMeters).map((meter, idx) => {
        return (
          <MeterCTSelect
            meterIndex={idx}
            key={hybridCircuitType + meter.ww_device_id}
            meter={meter}
            onCTClick={(circuitData) => handleSelectCircuit(circuitData)}
            selectedCTs={fields}
            reservedCTs={reservedCts}
          />
        );
      })}

      <Box mb={10}>
        {fields.map((field, idx) => {
          const switchValues = values.find((value) => value.circuitId === field.ww_circuit_id);
          if (!switchValues) throw new Error(`Unable to find switch value for circuit id ${field.ww_circuit_id}`);

          return (
            <SelectedCircuitsCard
              key={field.ww_circuit_id}
              source={`hybrid.${hybridCircuitType}` as `hybrid.${'inverter' | 'backup'}`}
              field={field}
              index={idx}
              register={register}
              onDismiss={() => remove(idx)}
              iconComponent={FieldIcon}
              onCircuitChange={onCircuitChange}
              polarityValue={switchValues.polarity}
              voltageRefValue={switchValues.voltageReference}
              ctRating={switchValues.ctRating}
            />
          );
        })}
        {!fields.length && <EmptySelectedCircuitsCard iconComponent={FieldIcon} />}
      </Box>
    </Box>
  );
}
