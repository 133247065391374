import React, { useEffect, useMemo } from 'react';
import { ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { MeterCTSelect } from '../../../../common/components/MeterCTSelect';
import { SIDENAV_WIDTH } from '../../../../common/constants';
import { selectEVChargers } from '../../system-details/systemDetailsSlice';
import { selectRawMeters } from '../meterSetupSlice';
import { CTConfigFormData } from './ct-configuration-form-types';
import { COMMON_NEXT_BUTTON_PROPS } from './ct-configuration-helpers';
import { TabComponentProps } from './CTConfigurationContainer';
import { FieldIconWrapper } from './FieldIconWrapper';
import { EmptySelectedCircuitsCard, SelectedCircuitsCard } from './SelectedCircuitsCard';
import { useReservedCTsForEVCharger } from './useGetReservedCTsForEVCharger';

const THREE_PHASE_EV_CHARGER_MODELS = [
  'EVH5A22N2S (Wired 3 Phase 16A)',
  'EVH5A22N2S (Wired 3 Phase 32A)',
  'EVH5A11N2C7',
  'EVH5A11N2C5',
];

function useThreePhaseEVChargerRowIds() {
  const evChargers = useSelector(selectEVChargers);
  return useMemo(
    () =>
      evChargers
        .filter(({ meta_data: metaData }) => THREE_PHASE_EV_CHARGER_MODELS.includes(metaData.model))
        .map(({ row_id: rowId }) => rowId),
    [evChargers]
  );
}

export function EVChargerCTConfiguration(props: TabComponentProps) {
  const {
    register,
    control,
    errors,
    updateCTFunctions,
    isUpdatingCTs,
    circuitState,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = props;

  const { fields } = useFieldArray({
    name: `evCharger` as const,
    control,
  });

  const threePhaseEVChargerRowIds = useThreePhaseEVChargerRowIds();

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  const handleSubmitEVChargerCTConfiguration = async () => {
    const evChargers = getValues().evCharger;
    let isValid = await trigger('evCharger');

    // check if all 3 phase chargers have at least 3 CTs
    evChargers.forEach((evCharger, index) => {
      if (threePhaseEVChargerRowIds.includes(evCharger.controlDeviceRowId) && evCharger.circuits.length < 3) {
        setError(`evCharger.${index}`, { message: 'Please select at least three CTs' });
        isValid = false;
      }
    });

    // if there is an error, don't proceed
    if (isValid) {
      clearErrors(`evCharger`);
      updateCTFunctions['evCharger']();
    }
  };

  return (
    <Flex direction="column" data-testid="ev-charger-tab-panel">
      <Accordion defaultIndex={0} allowToggle>
        {fields.map((field, index) => {
          const errorsAtIndex = errors?.evCharger?.[index];
          const isThreePhaseCharger = threePhaseEVChargerRowIds.includes(field.controlDeviceRowId);
          return (
            <AccordionItem key={`${field.id}-${index}`}>
              <AccordionButton role={'button'} as={Box}>
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Flex align="center">
                    <Heading size="sm" fontWeight="500">
                      EV Charger {index + 1} {isThreePhaseCharger && '(3 Phase)'}
                    </Heading>
                    {errorsAtIndex && <WarningIcon color={'red.500'} w={5} h={5} ml={2} />}
                  </Flex>
                  <Flex align={'center'}>
                    <AccordionIcon />
                  </Flex>
                </Flex>
              </AccordionButton>

              <AccordionPanel pb={4}>
                <FormControl my={3} isInvalid={!!errorsAtIndex?.loadName}>
                  <FormLabel>Customer-Facing Name</FormLabel>
                  <Input
                    data-testid={`evCharger-${index}-loadName`}
                    defaultValue={field.loadName}
                    placeholder={`Enter customer-facing name`}
                    type="text"
                    {...register(`evCharger.${index}.loadName` as const)}
                  />
                  <FormErrorMessage>{errorsAtIndex?.loadName?.message}</FormErrorMessage>
                </FormControl>

                <Box my={6}>
                  <Text color="dusk.100">Select which CT's are measuring the EV charger</Text>
                  {errors.evCharger?.[index] && (
                    <Flex pb={4} data-testid="error-message">
                      <WarningIcon color="red.500" w={5} h={5} pt={1} />
                      <Text color="red.500" ml={2}>
                        {errorsAtIndex?.message ?? errorsAtIndex?.circuits?.message}
                      </Text>
                    </Flex>
                  )}
                </Box>
                <NestedEVChargerCTConfigurationFieldArray field={field} applianceIndex={index} {...props} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <Flex
        position={'fixed'}
        bottom={16}
        left={0}
        zIndex={99}
        width="100%"
        justifyContent={'center'}
        paddingLeft={isMobileViewport ? 0 : SIDENAV_WIDTH}
        pointerEvents="none"
      >
        <Flex width={isMobileViewport ? '100%' : '60%'}>
          <Button
            data-testid="submit-ev-charger-button"
            aria-label="Submit EV Charger"
            rightIcon={isUpdatingCTs ? <Spinner size="sm" /> : <ArrowForwardIcon />}
            disabled={isUpdatingCTs || !!circuitState.pendingMeterIds.length}
            {...COMMON_NEXT_BUTTON_PROPS}
            onClick={handleSubmitEVChargerCTConfiguration}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function NestedEVChargerCTConfigurationFieldArray({
  control,
  applianceIndex,
  field,
  register,
  onCircuitChange,
  circuitState,
  errors,
  trigger,
  clearErrors,
  getValues,
}: TabComponentProps & {
  applianceIndex: number;
  field: FieldArrayWithId<CTConfigFormData, 'evCharger', 'id'>;
}) {
  const rawMeters = useSelector(selectRawMeters);
  const { values } = circuitState;
  const { fields, append, remove } = useFieldArray({
    name: `evCharger.${applianceIndex}.circuits` as 'evCharger.0.circuits',
    control,
  });
  const toast = useToast();
  const threePhaseEVChargerRowIds = useThreePhaseEVChargerRowIds();
  const errorsAtIndex = errors?.evCharger?.[applianceIndex];

  const Icon = CIRCUIT_TYPE_TO_ICON['load_ev_charger'];
  const FieldIcon = (
    <FieldIconWrapper>
      <Icon w={6} h={6} />
    </FieldIconWrapper>
  );

  useEffect(() => {
    // show toast errors as the form can be lengthier
    // and will make it harder to spot error messages
    if (errorsAtIndex?.circuits) {
      toast({
        title: `Missing CT in EV Charger ${applianceIndex + 1}`,
        description: (errorsAtIndex?.circuits as any)?.message + ` ${applianceIndex + 1}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [errorsAtIndex, toast, applianceIndex]);

  const reservedCTs = useReservedCTsForEVCharger(control, applianceIndex);

  function handleSelectCircuit(circuitData: ChannelData) {
    // returns index if found otherwise returns -1
    const indexOfCTField = fields.findIndex((selectedCT) => circuitData.ww_circuit_id === selectedCT.ww_circuit_id);

    if (indexOfCTField >= 0) {
      remove(indexOfCTField);
    } else {
      const channel = {
        ...circuitData,
        // placeholder, will be overridden by loadName at save time
        circuit_name: 'Electric Vehicle Charger',
        clipsal_monitors: 'load_ev_charger',
      };
      append(channel, { shouldFocus: false });
    }

    // revalidate form if there is any errors for evCharger tab
    if (errors?.evCharger) trigger(`evCharger.${applianceIndex}.circuits`);

    // Remove error if 3 phase charger has at least 3 CTs
    if (errorsAtIndex) {
      const isThreePhaseCharger = threePhaseEVChargerRowIds.includes(field.controlDeviceRowId);
      const totalCTs = getValues().evCharger[applianceIndex]?.circuits.length;
      if (isThreePhaseCharger && totalCTs > 2) clearErrors(`evCharger.${applianceIndex}`);
    }
  }

  return (
    <Box mt={4}>
      {Object.values(rawMeters).map((meter, idx) => {
        return (
          <MeterCTSelect
            meterIndex={idx}
            key={idx + field.id}
            meter={meter}
            onCTClick={handleSelectCircuit}
            selectedCTs={fields}
            reservedCTs={reservedCTs}
          />
        );
      })}

      <Box mb={10}>
        {fields.map((field, idx) => {
          const switchValues = values.find((value) => value.circuitId === field.ww_circuit_id);
          if (!switchValues) throw new Error(`Unable to find switch value for circuit id ${field.ww_circuit_id}`);

          return (
            <SelectedCircuitsCard
              key={field.ww_circuit_id}
              source={`evCharger.${applianceIndex}.circuits` as `evCharger.${number}.circuits`}
              field={field}
              index={idx}
              register={register}
              onDismiss={() => remove(idx)}
              iconComponent={FieldIcon}
              onCircuitChange={onCircuitChange}
              polarityValue={switchValues.polarity}
              voltageRefValue={switchValues.voltageReference}
              ctRating={switchValues.ctRating}
            />
          );
        })}
        {!fields.length && <EmptySelectedCircuitsCard iconComponent={FieldIcon} />}
      </Box>
    </Box>
  );
}
