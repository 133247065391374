import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/react';

import { BOTTOM_NAV_HEIGHT } from '../../../../common/constants';
import { debounceEvent } from '../../../../utils/component-helpers';
import { PAGE_SIZES } from './manage-users-helpers';

type ManageUserPaginationProps = {
  isLoaded: boolean;
  isFetching: boolean;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageIndexChange: (pageIndex: number) => void;
};

export function UserListPagination({
  isLoaded,
  isFetching,
  pageIndex,
  pageSize,
  totalPages,
  onPageIndexChange,
  onPageSizeChange,
}: ManageUserPaginationProps) {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });

  const debouncedGotoPage = debounceEvent((pageNumber: number) => {
    onPageIndexChange(pageNumber ? pageNumber - 1 : 0);
  });

  const canGoNextPage = pageIndex < totalPages - 1;
  const canGoPreviousPage = pageIndex > 0;
  const isPaginationDisabled = !isLoaded || isFetching;

  return (
    <Flex direction={'column'} mb={isMobileViewport ? BOTTOM_NAV_HEIGHT : 0} mt="auto">
      <Flex
        p={2}
        justifyContent="space-between"
        align="center"
        w="100%"
        pt={4}
        direction={isMobileViewport ? 'column' : 'row'}
      >
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex>
            <IconButton
              data-testid="goto-first-page"
              mx={1}
              size="xs"
              aria-label="Back to first page"
              icon={<ArrowLeftIcon />}
              onClick={() => onPageIndexChange(0)}
              isDisabled={!canGoPreviousPage || isPaginationDisabled}
            />
            <IconButton
              data-testid="goto-previous-page"
              mx={1}
              size="xs"
              aria-label="Go to previous page"
              icon={<ChevronLeftIcon />}
              onClick={() => onPageIndexChange(pageIndex - 1)}
              isDisabled={!canGoPreviousPage || isPaginationDisabled}
            />
            <IconButton
              data-testid="goto-next-page"
              mx={1}
              size="xs"
              aria-label="Go to next page"
              icon={<ChevronRightIcon />}
              onClick={() => onPageIndexChange(pageIndex + 1)}
              isDisabled={!canGoNextPage || isPaginationDisabled}
            />
            <IconButton
              data-testid="goto-last-page"
              mx={1}
              size="xs"
              aria-label="Go to last page"
              icon={<ArrowRightIcon />}
              onClick={() => onPageIndexChange(totalPages - 1)}
              isDisabled={!canGoNextPage || isPaginationDisabled}
            />
          </Flex>

          <Box mx={2} data-testid="page-number-info">
            Page{' '}
            <strong>
              {pageIndex + 1} of {totalPages || 1}
            </strong>
          </Box>
        </Flex>

        <Flex mx="auto" mt={isMobileViewport ? 4 : undefined}>
          <InputGroup size="sm" mr={4}>
            <InputLeftAddon>Go to page</InputLeftAddon>
            <NumberInput
              isDisabled={isPaginationDisabled}
              max={totalPages || 1}
              min={1}
              maxW={20}
              defaultValue={pageIndex + 1}
              onChange={(_, eNumber) => {
                debouncedGotoPage(eNumber);
              }}
              style={{ width: '100px' }}
            >
              <NumberInputField data-testid="goto-page" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>

          <Select
            data-testid="select-page-size"
            isDisabled={!isLoaded}
            size="sm"
            value={pageSize}
            onChange={(e) => {
              onPageSizeChange(Number(e.target.value));
            }}
          >
            {PAGE_SIZES.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <Box mx={isMobileViewport ? 'auto' : 4} h={8}>
        {isPaginationDisabled && (
          <>
            <Spinner mr={1} size={'xs'} /> Loading...
          </>
        )}
      </Box>
    </Flex>
  );
}
