import React, { useState } from 'react';
import { Box, Center, Flex, Heading, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { AlertsNotificationIconButton } from '../../common/components/AlertsNotificationIconButton';
import { MobileTopNav } from '../../common/components/MobileTopNav';
import { TenantSelect } from '../../common/components/TenantSelect';
import { BOTTOM_NAV_HEIGHT } from '../../common/constants';
import { selectUser } from '../user/userSlice';
import { getDisplayedDateTimeString } from './fleet-dashboard-helpers';
import { useGetLastUpdatedDate } from './fleetDashboardApi';
import { FleetDashboardContents } from './FleetDashboardContents';
import { FleetDashboardProfileInfo } from './FleetDashboardProfileInfo';

export function FleetDashboard() {
  const user = useSelector(selectUser);
  const isSuperAdmin = user.role === 'SUPER_ADMIN';
  const [selectedTenantId, setSelectedTenantId] = useState(user.tenant_id);
  const { lastUpdated, isLoading: isLoadingLastUpdatedAt } = useGetLastUpdatedDate(selectedTenantId);
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const iconBackground = useColorModeValue('white', 'whiteAlpha.200');

  return (
    <Box className={'fleet-dashboard'} px={[1, 1, 5]} py={3} mb={isMobileViewport ? BOTTOM_NAV_HEIGHT : undefined}>
      {isMobileViewport ? (
        <MobileTopNav title={'Fleet Dashboard'} backURL="" />
      ) : (
        <>
          <Flex mt={5} align={'center'}>
            <Heading mr={5} size={'xl'}>
              Fleet Overview
            </Heading>

            <Flex align="center" ml={'auto'} mr={2}>
              <Box mr={4} borderRadius={100} background={iconBackground} shadow="lg">
                <AlertsNotificationIconButton />
              </Box>

              <FleetDashboardProfileInfo />
            </Flex>
          </Flex>

          <Text my={5} fontSize={'md'}>
            Last updated {getDisplayedDateTimeString(lastUpdated)}
          </Text>
        </>
      )}

      {isSuperAdmin && (
        <Center mb={isMobileViewport ? 2 : 4} justifyContent={isMobileViewport ? 'center' : 'flex-start'}>
          <Box w={'200px'} data-testid="tenant-select">
            <TenantSelect
              isDisabled={isLoadingLastUpdatedAt}
              value={selectedTenantId}
              onChange={(newTenantId) => setSelectedTenantId(newTenantId as number)}
              includeAllTenants={false}
            />
          </Box>
        </Center>
      )}

      <FleetDashboardContents selectedTenantId={selectedTenantId} />
    </Box>
  );
}
