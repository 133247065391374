import React, { useState } from 'react';
import { Box, Center, Heading, Text, useToast } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { OTPInput } from 'clipsal-cortex-ui/src/components/OTPInput';

import { useAppDispatch } from '../../../app/hooks';
import { ReactComponent as MailOtp } from '../../../assets/images/mail_otp.svg';
import { CustomButton } from '../../../common/components/CustomButton';
import { checkUserLoggedIn, fetchUserDetails } from '../../user/userSlice';
import { LoginRoute } from './login-utils';

type OTPFormState = {
  isError: boolean;
  isLoading: boolean;
  value: string;
};

type OTPFormProps = { cognitoUser: CognitoUser | null; onChangeRoute: (newRoute: LoginRoute) => void };

export function OTPForm({ cognitoUser, onChangeRoute }: OTPFormProps) {
  const [state, setState] = useState<OTPFormState>({ isError: false, value: '', isLoading: false });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  async function handleSubmitOTP(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setState({
        ...state,
        isLoading: true,
      });

      const user = await Auth.sendCustomChallengeAnswer(cognitoUser, state.value);

      if (user.signInUserSession) {
        // No password needed for OTP login, so we pass an empty string
        // populate redux with user data
        const { isLoggedIn } = unwrapResult(await dispatch(checkUserLoggedIn()));
        if (isLoggedIn) await dispatch(fetchUserDetails());
        navigate('/');
      } else {
        toast({
          title: `Invalid OTP code`,
          description: `The code you entered was not correct, please double-check your email.`,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        setState({ ...state, isError: true, isLoading: false });
      }
    } catch (e) {
      console.error(e);

      toast({
        title: `Too many incorrect login attempts`,
        description: `You have entered the incorrect code too many times, please try again.`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });

      onChangeRoute(LoginRoute.Login);
    }
  }

  return (
    <Box as="form" data-testid="otp-form" maxW={320} mx="auto" mt={6} onSubmit={handleSubmitOTP}>
      <Center flexDirection={'column'}>
        <Center cursor={'pointer'} my={6} w={'60%'}>
          <MailOtp />
        </Center>

        <Heading fontWeight={700} size="md" mb={4}>
          Enter your one time password
        </Heading>

        <Text textAlign={'center'} maxW={280} mb={4}>
          Check your email inbox and enter the 6-digit code we just sent you.
        </Text>

        <OTPInput
          bg="transparent"
          isError={state.isError}
          onChange={(value) => {
            setState({
              ...state,
              isError: false,
              value,
            });
          }}
        />
        {state.isError && (
          <Text mt={3} textAlign={'center'} color={'red'} data-testid="otp-error">
            Invalid code.
          </Text>
        )}
        <CustomButton
          data-testid="submit-otp-button"
          isDisabled={state.value.length !== 6}
          isLoading={state.isLoading}
          type="submit"
          w="100%"
          rounded={3}
          py={6}
          mt={3}
          loadingText={'Logging in..'}
        >
          Login
        </CustomButton>
      </Center>
    </Box>
  );
}
