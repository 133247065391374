import React from 'react';
import { EditIcon, RepeatIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SOLAR_ANALYTICS_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { SiteData } from '../../api/api-site';
import { useAlertDrawerContext } from '../../context/context-exports';
import { EyeIcon } from '../../styles/custom-icons';
import { useRefreshSiteCircuitsMutation } from '../site/meter-setup/meterSetupApi';
import { selectUser } from '../user/userSlice';
import { openCortexAppAtRoute } from './dashboard-helpers';
import { SiteAlertIcon } from './SiteAlertIcon';

const COMMON_BUTTON_PROPS = {
  _hover: { bg: 'gray.100' },
  _dark: {
    _hover: {
      bg: 'gray.900',
    },
  },
};

const COMMON_ICON_PROPS = {
  w: 5,
  h: 5,
};

type SiteActionMenuProps = { siteData: SiteData; dataIndex: number };

export const SiteActionMenu = ({ siteData, dataIndex }: SiteActionMenuProps) => {
  const navigate = useNavigate();
  const siteId = siteData.clipsal_solar_id;
  const siteHasSolarAnalyticsMeter = siteData?.meters?.some(
    (meter) => meter.meta_data.manufacturer_id === SOLAR_ANALYTICS_MANUFACTURER_ID
  );
  const siteHasMeters = !!siteData?.meters?.length;
  const user = useSelector(selectUser);
  const isSuperAdmin = user.role === 'SUPER_ADMIN';
  const [refreshSiteCircuits, { isLoading: isRefreshingSiteCircuits }] = useRefreshSiteCircuitsMutation();
  const toast = useToast({ duration: 3000, isClosable: true });
  const operatingStatus = siteData.operating_status;
  const { onOpenAlertDrawer } = useAlertDrawerContext();
  const iconColor = useColorModeValue('dusk100.500', 'white');

  return (
    <Menu closeOnSelect={false} isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={IoEllipsisVerticalSharp} />}
        variant="unstyled"
        p={1}
        rounded={20}
        minW={7}
        h={7}
        data-testid={`site-action-menu-button-${dataIndex}`}
        {...COMMON_BUTTON_PROPS}
        _dark={{
          _hover: {
            bg: 'gray.700',
          },
        }}
      />
      <Portal>
        <MenuList zIndex={10} border="2px solid" borderColor="gray.200" py={0} data-testid="action-menu-list">
          <MenuItem
            icon={<EditIcon color={iconColor} h={5} w={5} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="edit-site-button"
            onClick={() => navigate(`/site/${siteId}/start?redirect=true`)}
          >
            Edit Site
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            {...COMMON_BUTTON_PROPS}
            data-testid="view-alerts-button"
            icon={<SiteAlertIcon operatingStatus={operatingStatus} />}
            onClick={() => onOpenAlertDrawer(siteId)}
          >
            View Alerts
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            icon={<EyeIcon {...COMMON_ICON_PROPS} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="view-site-in-cortex-button"
            onClick={() => openCortexAppAtRoute(siteId)}
          >
            View Site in Cortex
          </MenuItem>
          {siteHasMeters && (
            <MenuItem
              icon={<SettingsIcon {...COMMON_ICON_PROPS} />}
              {...COMMON_BUTTON_PROPS}
              data-testid="configure-site-meters-button"
              onClick={() => navigate(`/site/${siteId}/meter_setup/configure/meter_configuration`)}
            >
              Configure Meters
            </MenuItem>
          )}
          {isSuperAdmin && siteHasSolarAnalyticsMeter && (
            <MenuItem
              icon={isRefreshingSiteCircuits ? <Spinner size="sm" /> : <RepeatIcon {...COMMON_ICON_PROPS} />}
              {...COMMON_BUTTON_PROPS}
              data-testid="refresh-circuits-button"
              isDisabled={isRefreshingSiteCircuits}
              onClick={async () => {
                toast.closeAll();
                try {
                  await refreshSiteCircuits(siteData.clipsal_solar_id).unwrap();
                  toast({
                    title: 'Site circuits is being refreshed...',
                    description: 'Please wait a few seconds and refresh the page',
                    status: 'info',
                  });
                } catch (error) {
                  toast({
                    title: 'Failed to refresh site circuits!',
                    status: 'error',
                  });
                }
              }}
            >
              Refresh Circuits
            </MenuItem>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};
