import React, { PropsWithChildren } from 'react';
import {
  DndContext,
  DragEndEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useDispatch, useSelector } from 'react-redux';

import { selectAllAppliances, selectAllCircuits, setAllCircuits } from '../circuitApplianceConfigSlice';
import { AVAILABLE_CIRCUITS_DROP_AREA_ID } from './appliance-config-helpers';

export const DragAndDropContext = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const allAppliances = useSelector(selectAllAppliances);
  const allCircuits = useSelector(selectAllCircuits);
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 4 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 4 } })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const draggedCircuitId = active.id;
    const dropAreaId = over?.id;

    // No action if not dragged over another element.
    if (!over) return;

    const activeCircuit = allCircuits.find((circuit) => circuit.oem_circuit_id === draggedCircuitId);
    if (!activeCircuit) return; // No action if the circuit is not found.

    if (dropAreaId === AVAILABLE_CIRCUITS_DROP_AREA_ID) {
      // No action if the circuit is already available.
      const isAlreadyAvailable = allCircuits.some(
        (circuit) => circuit.oem_circuit_id === draggedCircuitId && !circuit.appliance_id
      );
      if (isAlreadyAvailable) return;

      // If the circuit is dragged over the available circuits, it is removed from the appliance.
      dispatch(
        setAllCircuits([
          ...allCircuits.filter((circuit) => circuit.oem_circuit_id !== draggedCircuitId),
          { ...activeCircuit, appliance_id: null },
        ])
      );

      // No action if the circuit is already assigned to an appliance.
    } else {
      const applianceAreaToDrop = allAppliances.find((appliance) => appliance.appliance_id === dropAreaId);
      if (!applianceAreaToDrop) return;

      // If the circuit is dragged over an invalid drop area, no action is taken.
      if (applianceAreaToDrop.appliance_type !== activeCircuit.clipsal_monitors) return;

      const hasValidDropArea = allAppliances.some((appliance) => {
        return appliance.appliance_type === activeCircuit.clipsal_monitors && appliance.appliance_id === dropAreaId;
      });

      if (!hasValidDropArea) return;

      dispatch(
        setAllCircuits(
          allCircuits.map((circuit) => {
            if (circuit.oem_circuit_id === draggedCircuitId) {
              return { ...circuit, appliance_id: Number(dropAreaId) };
            }
            return circuit;
          })
        )
      );
      dispatch(
        setAllCircuits([
          ...allCircuits.filter((circuit) => circuit.oem_circuit_id !== draggedCircuitId),
          { ...activeCircuit, appliance_id: Number(dropAreaId) },
        ])
      );
    }
  }

  return (
    <DndContext
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      autoScroll={false}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      {children}
    </DndContext>
  );
};
