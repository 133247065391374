import React from 'react';
import { Center, ChakraProvider, ColorModeScript, Heading } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import { ServiceWorkerProvider } from 'clipsal-cortex-utils/src/providers/service-worker-provider';

import { App } from './App';
import { store } from './app/store';
import { PullToRefresh } from './common/components/pull-to-refresh/PullToRefresh';
import { CombinedContextProvider } from './context/CombinedContextProvider';
import { NavigationProvider } from './context/NavigationProvider';
import { customTheme as theme } from './styles/theme';

export const AppProvider = () => {
  return (
    <React.StrictMode>
      <NavigationProvider>
        <Sentry.ErrorBoundary
          fallback={
            <Center h={'100vh'} w={'100%'}>
              <Heading textAlign={'center'}>
                An error occurred. Please refresh the page to continue. If this error persists, try closing and
                re-opening the app.
              </Heading>
            </Center>
          }
        >
          <Provider store={store}>
            <ChakraProvider theme={theme}>
              <ServiceWorkerProvider>
                <CombinedContextProvider>
                  <ColorModeScript />
                  <PullToRefresh>
                    <App />
                  </PullToRefresh>
                </CombinedContextProvider>
              </ServiceWorkerProvider>
            </ChakraProvider>
          </Provider>
        </Sentry.ErrorBoundary>
      </NavigationProvider>
    </React.StrictMode>
  );
};
