import React from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectAllAppliances } from '../circuitApplianceConfigSlice';
import { AVAILABLE_CIRCUITS_DROP_AREA_ID } from './appliance-config-helpers';
import { CircuitsDropArea } from './CircuitsDropArea';

export const ApplianceCircuitsDropArea = () => {
  const allAppliances = useSelector(selectAllAppliances);

  return (
    <Box>
      <Box data-testid="available-circuits-drop-area">
        <CircuitsDropArea id={AVAILABLE_CIRCUITS_DROP_AREA_ID} applianceIndex={-1} />
      </Box>

      <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4} data-testid="appliance-circuits-drop-area">
        {allAppliances.map((appliance, applianceIndex) => (
          <CircuitsDropArea
            id={appliance.appliance_id}
            appliance={appliance}
            key={appliance.appliance_id}
            applianceIndex={applianceIndex}
          />
        ))}
      </Grid>
    </Box>
  );
};
