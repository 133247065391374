import { Action, combineReducers, configureStore, PayloadAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

import { dashboardReducer } from '../features/dashboard/dashboardSlice';
import { circuitApplianceConfigReducer } from '../features/site/meter-setup/circuit-appliance-configuration/circuitApplianceConfigSlice';
import { meterConfigurationReducer } from '../features/site/meter-setup/meter-configuration/meterConfigurationSlice';
import { meterSetupReducer } from '../features/site/meter-setup/meterSetupSlice';
import { siteReducer } from '../features/site/siteSlice';
import { systemDetailsReducer } from '../features/site/system-details/systemDetailsSlice';
import { userReducer } from '../features/user/userSlice';
import { wizardReducer } from '../features/wizard/wizardSlice';
import { baseApi } from './baseApi';

const rootReducer = combineReducers({
  wizard: wizardReducer,
  user: userReducer,
  site: siteReducer,
  systemDetails: systemDetailsReducer,
  meterSetup: meterSetupReducer,
  meterConfiguration: meterConfigurationReducer,
  circuitApplianceConfig: circuitApplianceConfigReducer,
  dashboard: dashboardReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const resettableRootReducer = (state: RootState | undefined, action: PayloadAction<unknown>) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware);
  },
});

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
