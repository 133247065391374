import React, { useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Collapse,
  Divider,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Skeleton,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { round } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { ChannelData, CTRating, Polarity, VoltageReference } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { MultiToggleSwitch } from '../../../../common/components/MultiToggleSwitch';
import { PencilIcon } from '../../../../styles/custom-icons';
import { debounceEvent } from '../../../../utils/component-helpers';
import { selectSite } from '../../siteSlice';
import { FieldIconWrapper } from '../ct-configuration/FieldIconWrapper';
import { CTRatings } from '../ct-configuration/SelectedCircuitsCard';
import { DISPLAYED_VOLTAGE_REF_TYPES } from '../meter-setup-helpers';
import { MeterSetupPollingSubRouteProps } from '../MeterSetupPollingWrapper';
import { POLARITY_TYPES } from './meter-config-icon-map';
import {
  CircuitDisplayConfig,
  selectAllMemoizedCircuits,
  selectMeterConfiguration,
  setCircuitDisplayConfigs,
} from './meterConfigurationSlice';
import { useMeterChartOptions } from './use-meter-chart-options';

export const CircuitCardsListView = () => {
  const allCircuits = useSelector(selectAllMemoizedCircuits);
  const { circuitDisplayConfigs, isLoaded } = useSelector(selectMeterConfiguration);

  const firstActiveCircuitId = useMemo(() => {
    return circuitDisplayConfigs.find(({ isHidden }) => !isHidden)?.circuitId;
    // we only want to run this once, so we don't need to include circuitDisplayConfigs in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return isLoaded ? (
    <VStack>
      {allCircuits.map((circuit, i) => {
        const circuitConfig = circuitDisplayConfigs.find((c) => c.circuitId === circuit.ww_circuit_id);
        // If the circuit is hidden, don't render it.
        if (!circuitConfig || circuitConfig.isHidden) return null;

        return (
          <Box w={'100%'} py={2} key={circuit.ww_circuit_id} data-testid={`circuit-card-${i}`}>
            <CircuitStatusCard
              circuit={circuit}
              isInitiallyExpanded={circuitConfig.circuitId === firstActiveCircuitId}
              circuitConfig={circuitConfig}
            />
          </Box>
        );
      })}
    </VStack>
  ) : (
    <VStack spacing={2} w="100%">
      {Array.from(Array(4).keys()).map((i) => (
        <Skeleton key={i} height={20} w="100%" />
      ))}
    </VStack>
  );
};

type Props = {
  circuit: ChannelData; // The CT this card represents.
  circuitConfig: CircuitDisplayConfig;
  isInitiallyExpanded: boolean;
};

function CircuitStatusCard({ circuit, circuitConfig, isInitiallyExpanded = true }: Props) {
  const { onCircuitChange, circuitState } = useOutletContext<MeterSetupPollingSubRouteProps>();
  const { shortEnergyData, isShortEnergySelected } = useMeterChartOptions();

  const { latestPowerFactorValue, latestPolarityValue } = useMemo(() => {
    const meterData = shortEnergyData[circuitConfig.meterIndex];
    const latestData = meterData?.[meterData.length - 1];
    const latestPowerFactorValue = latestData?.powerFactor[circuitConfig.circuitIndex];
    const latestPolarityValue = latestData?.pRealKw[circuitConfig.circuitIndex];

    return {
      latestPowerFactorValue: latestPowerFactorValue ? round(latestPowerFactorValue, 2) : 0,
      latestPolarityValue: latestPolarityValue ? round(latestPolarityValue, 2) : 0,
    };
  }, [shortEnergyData]);

  const { memoizedRawMeters } = useSelector(selectMeterConfiguration);
  const meterName = useMemo(() => {
    const circuitMeterId = circuit.ww_circuit_id.split('_')[0];
    const meterForCircuit = memoizedRawMeters[circuitMeterId];
    return meterForCircuit?.label ?? `Meter ${Object.values(memoizedRawMeters).indexOf(meterForCircuit) + 1}`;
  }, [memoizedRawMeters, circuit]);
  const circuitPolarityPhaseValues = circuitState.values.find(({ circuitId }) => circuitId === circuit.ww_circuit_id);
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
  const [circuitName, setCircuitName] = useState(circuit.circuit_name);
  const dispatch = useDispatch();
  const site = useSelector(selectSite);
  const { circuitDisplayConfigs } = useSelector(selectMeterConfiguration);
  const phaseSelectOptions = DISPLAYED_VOLTAGE_REF_TYPES[site.electrical_config];
  // When clipsal monitor is not recognized, use the not_set icon
  const Icon = CIRCUIT_TYPE_TO_ICON[circuit.clipsal_monitors] ?? CIRCUIT_TYPE_TO_ICON.not_set;
  const isMobileViewport = useBreakpointValue({
    base: window.innerWidth < 768,
    md: false,
  });
  const { shadowColor, switchClassName } = useColorModeValue(
    {
      shadowColor: 'rgba(0, 0, 0, 0.25)',
      switchClassName: 'light-mode-toggle-switch',
    },
    {
      shadowColor: 'rgba(255, 255, 255, 0.25)',
      switchClassName: 'dark-mode-toggle-switch-2',
    }
  );

  const handleUpdateCircuitName = useMemo(
    () =>
      debounceEvent((newName: string) => {
        // Only update the circuit name when not empty
        if (newName) onCircuitChange(circuit.ww_circuit_id, newName, 'name');

        // Update the circuit name in the store
        dispatch(
          setCircuitDisplayConfigs(
            circuitDisplayConfigs.map((c) => {
              if (c.circuitId === circuitConfig.circuitId) {
                const label = `${newName} (M${circuitConfig.meterIndex + 1}, CH${circuitConfig.circuitIndex + 1})`;
                return { ...c, label };
              }
              return c;
            })
          )
        );
      }),
    []
  );

  if (!circuitPolarityPhaseValues) {
    Sentry.captureException({
      message: `Unable to find circuit phase and polarity value for ID ${circuit.ww_circuit_id}`,
      extra: { circuitId: circuit.ww_circuit_id, circuitPolarityPhaseValues: circuitPolarityPhaseValues },
    });
    return null;
  }

  const { polarity, voltageReference, ctRating } = circuitPolarityPhaseValues;
  const visiblePhaseValue = phaseSelectOptions.find((option) => option.value === voltageReference.value);

  return (
    <Flex className="circuit-card" shadow={`0px 0px 4px ${shadowColor}`} rounded={8}>
      <Box w={'3%'} bg={circuitConfig.color} borderTopLeftRadius={10} borderBottomLeftRadius={10} />
      <Box p={1} py={2} w={'97%'}>
        <Flex>
          <Center ml={2}>
            <FieldIconWrapper>
              <Icon w={6} h={6} />
            </FieldIconWrapper>
          </Center>

          <Box w="90%">
            <Flex justify={'space-between'} align={'flex-start'}>
              <InputGroup w="fit-content" maxW={250}>
                <Input
                  variant="unstyled"
                  width={'fit-content'}
                  data-testid="circuit-name"
                  value={circuitName}
                  onChange={(e) => {
                    const newCircuitName = e.target.value;
                    setCircuitName(newCircuitName);
                    handleUpdateCircuitName(newCircuitName);
                  }}
                />
                <InputRightElement pb={4} pointerEvents="none">
                  <PencilIcon />
                </InputRightElement>
              </InputGroup>
              <IconButton
                size={'xs'}
                variant={'ghost'}
                data-testid="expand-button"
                aria-label={isExpanded ? 'Minimize' : 'Maximize'}
                icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </Flex>
            <Text color={'fixedCostGrey.500'} fontSize={'xs'}>
              {meterName}, M{circuitConfig.meterIndex + 1}, CT{circuitConfig.circuitIndex + 1},{' '}
              {visiblePhaseValue?.label} Phase
            </Text>
          </Box>
        </Flex>

        <Collapse in={isExpanded}>
          {/* Only show in short energy mode */}
          {isShortEnergySelected && (
            <Flex mt={2} justify="space-between" w="fit-content" mx="auto">
              <Center>
                <Text>PF</Text>
                <Text
                  fontWeight="bold"
                  bg="rgba(155, 160, 161, 0.3)"
                  rounded={100}
                  border={'1px'}
                  px={isMobileViewport ? 2 : 4}
                  ml={2}
                >
                  {latestPowerFactorValue}
                </Text>
              </Center>
              <Divider orientation="vertical" mx={isMobileViewport ? 2 : 8} height="36px" />
              <Center>
                <Text>Power</Text>
                <Text
                  fontWeight="bold"
                  bg="rgba(155, 160, 161, 0.3)"
                  rounded={100}
                  border={'1px'}
                  px={isMobileViewport ? 2 : 4}
                  ml={2}
                >
                  {latestPolarityValue} kW
                </Text>
              </Center>
            </Flex>
          )}

          {/* PF/Phase controls */}
          <Flex mt={[4, 6]} mx={2} justify="space-between">
            <Flex direction="column" align="center" h="100%">
              <Select
                data-testid="ct-rating-select"
                h={isMobileViewport ? 8 : 35}
                cursor={'pointer'}
                bg="#f5f5f5"
                _dark={{ bg: '#1c2230' }}
                border="none"
                rounded={25}
                value={ctRating.value}
                disabled={ctRating.isPending}
                onChange={(e) => {
                  const newCTRating = (Number(e.target.value) || 60) as CTRating;
                  onCircuitChange(circuit.ww_circuit_id, newCTRating, 'ctRating');
                }}
              >
                {CTRatings.map((rating) => (
                  <option value={rating} key={rating}>
                    {rating}
                  </option>
                ))}
              </Select>
              {ctRating.isPending ? (
                <Flex justify="center" py={1} align="center" fontSize={'xs'}>
                  <Spinner emptyColor="gray.200" color="blue.500" size="sm" />
                  <Text fontWeight="normal" color="gray.500" ml={1}>
                    Updating...
                  </Text>
                </Flex>
              ) : (
                <Text fontSize="xs" mt={1}>
                  CT Rating (A)
                </Text>
              )}
            </Flex>

            <Box className={switchClassName}>
              <MultiToggleSwitch<VoltageReference>
                switchOptions={phaseSelectOptions}
                fontSize="xs"
                value={voltageReference.value}
                onChange={async (value) => await onCircuitChange(circuit.ww_circuit_id, value, 'voltageReference')}
                disabled={voltageReference.isPending}
                enableBottomLabel
                setValueAsBottomLabel={false}
                label="Phasing"
                customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
                data-testid="voltage-ref-toggle"
              />
            </Box>

            <Box className={switchClassName}>
              <MultiToggleSwitch<Polarity>
                switchOptions={POLARITY_TYPES}
                fontSize="xs"
                value={polarity.value}
                onChange={async (value) => await onCircuitChange(circuit.ww_circuit_id, value, 'polarity')}
                disabled={polarity.isPending}
                enableBottomLabel
                setValueAsBottomLabel={false}
                label="Polarity"
                customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
                data-testid="polarity-toggle"
              />
            </Box>
          </Flex>
        </Collapse>
      </Box>
    </Flex>
  );
}
