import React, { useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { BatterySimulation } from '../../api/api-battery-upsell';

const SITE_STAT_CONFIG = [
  {
    title: 'Avg. Solar Export (kWh/day)',
    key: 'solar_export_daily_before_kwh',
    unit: '',
  },
  {
    title: 'Avg. Grid import (kWh/day)',
    key: 'grid_import_daily_before_kwh',
    unit: '',
  },
] as const;

const COMMON_STAT_CONTAINER_STYLES = {
  direction: 'column',
  minH: '100%',
  bg: 'whiteAlpha.900',
  color: 'black',
  _dark: { bg: 'gray.900', color: 'white' },
  p: 4,
  rounded: 10,
  fontSize: 14,
} as const;

export const SiteStats = ({
  simulation,
  isRenderingPdf,
  isMobileViewport,
}: {
  simulation: BatterySimulation;
  isRenderingPdf?: boolean;
  isMobileViewport?: boolean;
}) => {
  const commonDynamicStyles = useMemo(() => {
    const width = isMobileViewport ? 'calc(50% - 12px)' : 'calc(25% - 12px)';
    return {
      minW: width,
      maxW: width,
      shadow: isRenderingPdf ? 'none' : 'md',
      pt: isRenderingPdf ? -2 : 4,
    };
  }, [isMobileViewport, isRenderingPdf]);

  return (
    <>
      <Flex {...COMMON_STAT_CONTAINER_STYLES} {...commonDynamicStyles}>
        <Text>Est Annual Battery Savings</Text>
        <Text fontWeight={700} mt="auto" fontSize={20}>
          ${simulation.metrics_yearly.savings_yearly_dollars?.toFixed(1)}
        </Text>
      </Flex>

      {SITE_STAT_CONFIG.map(({ title, key, unit }, i) => {
        return (
          <Flex key={`stat - ${i}`} {...COMMON_STAT_CONTAINER_STYLES} {...commonDynamicStyles}>
            <Text>{title}</Text>
            <Text fontWeight={700} mt="auto" fontSize={20}>
              {unit}
              {simulation.metrics_daily[key]?.toFixed(1)}
            </Text>
          </Flex>
        );
      })}

      <Flex {...COMMON_STAT_CONTAINER_STYLES} {...commonDynamicStyles}>
        <Text>Usable Battery (kWh)</Text>
        <Text fontWeight={700} fontSize={20} mt="auto">
          {simulation.battery_usable_kwh?.toFixed(1)}
        </Text>
      </Flex>
    </>
  );
};
