import React, { useEffect } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Divider, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomButton } from '../../../common/components/CustomButton';
import { useSiteRouteChangeContext } from '../../../context/context-exports';
import { openCortexAppAtRoute } from '../../dashboard/dashboard-helpers';
import { selectUser } from '../../user/userSlice';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { selectSite } from '../siteSlice';
import { selectBatteries, selectInverters } from '../system-details/systemDetailsSlice';
import { CatchPowerMeterAlert } from './CatchPowerMeterAlert';
import { mapMetersToForm } from './form-mapping-helpers';
import { MeterDetailsFieldArray } from './meter-details/MeterDetailsFieldArray';
import { MeterSetupFormData } from './meter-setup-form-types';
import { checkIfAppliancesAreAssigned, getEmptyMeterTemplate, GRID_CIRCUIT_TYPES } from './meter-setup-helpers';
import { meterFormSchema } from './meter-setup-validation-schema';
import { useSiteMeters } from './meterSetupApi';
import { selectAppliances, selectRawMeters } from './meterSetupSlice';

export function MeterSetupForm() {
  const { onMoveForward } = useSiteRouteChangeContext();
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<MeterSetupFormData>({
    resolver: yupResolver(meterFormSchema),
    defaultValues: { meters: [getEmptyMeterTemplate()] },
  });
  const meters = useSelector(selectRawMeters);
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const appliances = useSelector(selectAppliances);
  const inverters = useSelector(selectInverters);
  const batteries = useSelector(selectBatteries);
  const { buttonTextColor, secondaryButtonTextColor, dividerColor } = useColorModeValue(
    { buttonTextColor: 'white', secondaryButtonTextColor: 'customBlack.500', dividerColor: '#0000001A' },
    { buttonTextColor: 'black', secondaryButtonTextColor: 'dusk100.200', dividerColor: 'dusk100.400' }
  );
  const { siteMeters, isLoadingSiteMeters } = useSiteMeters();

  function canMoveForward() {
    let areAllCircuitsAssigned;
    if (site.is_consumption_only) {
      areAllCircuitsAssigned =
        !!appliances?.length &&
        !!appliances.find((a) => GRID_CIRCUIT_TYPES.includes(a.appliance_type) && a.circuits.length);
    } else {
      areAllCircuitsAssigned = checkIfAppliancesAreAssigned(appliances, inverters, batteries);
    }

    return !!Object.values(meters).length && areAllCircuitsAssigned;
  }

  function handleMoveForward() {
    let isConfirmed = true;

    if (!canMoveForward()) {
      isConfirmed = window.confirm(
        `Moving forward without configuring meters will result in the site not correctly displaying information in Cortex. Are you sure you want to continue?`
      );
    }

    if (isConfirmed) onMoveForward();
  }

  async function handleViewLivePage() {
    await openCortexAppAtRoute(site.clipsal_solar_id, 'live');
  }

  useEffect(() => {
    if (!isEmpty(meters)) {
      const formMetersData = mapMetersToForm(meters);
      reset({ meters: formMetersData });
    } else {
      reset({ meters: [getEmptyMeterTemplate(`${site.site_name} - Meter 1`)] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSaveMeterConfig = canMoveForward();

  return (
    <WizardSubRouteCardWrapper p={[0]}>
      <Box data-testid="meter-details-form">
        <Flex justify="space-between" align={'center'} my={2} ml={[4, 6]}>
          <Heading size={'lg'}>Meter Setup</Heading>
          {!isLoadingSiteMeters && !!siteMeters.length && user.role === 'SUPER_ADMIN' && (
            <Button
              variant={'ghost'}
              rightIcon={<ChevronRightIcon />}
              data-testid="configure-circuits-btn"
              onClick={() =>
                navigate(`/site/${site.clipsal_solar_id}/meter_setup/configure/circuit_appliance_configuration`)
              }
            >
              Configure Circuits
            </Button>
          )}
        </Flex>

        <Divider borderColor={dividerColor} opacity={1} mb={4} />
        <CatchPowerMeterAlert />
        <MeterDetailsFieldArray {...{ reset, control, setError, register, errors, setValue, getValues }} />

        {canSaveMeterConfig && (
          <Center py={5} flexDirection="column">
            <CustomButton
              color={buttonTextColor}
              onClick={handleMoveForward}
              my={2}
              data-testid="save-meter-config-btn"
            >
              Save Meter Configuration
            </CustomButton>

            <Center w={'100%'}>
              <Button
                w={['75%', '75%', '40%']}
                onClick={handleViewLivePage}
                color={secondaryButtonTextColor}
                variant={'ghost'}
              >
                View Live Page
              </Button>
            </Center>
          </Center>
        )}
      </Box>
    </WizardSubRouteCardWrapper>
  );
}
