import React, { useMemo } from 'react';
import { Box, Flex, HStack, Input, InputGroup, InputRightElement, useBreakpointValue } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { useDispatch, useSelector } from 'react-redux';

import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';

import { Appliance } from '../../../../../api/api-appliance';
import { PencilIcon } from '../../../../../styles/custom-icons';
import { selectAllCircuits, updateApplianceName } from '../circuitApplianceConfigSlice';
import { AddApplianceButton } from './AddApplianceButton';
import {
  AVAILABLE_CIRCUITS_DROP_AREA_ID,
  useApplianceCircuits,
  useAvailableCircuits,
} from './appliance-config-helpers';
import { DeleteApplianceButton } from './DeleteApplianceButton';
import { DraggableCircuit } from './DraggableCircuit';

interface CircuitsDropAreaProps {
  id: number;
  appliance?: Appliance;
  applianceIndex: number;
}

export function CircuitsDropArea({ id, appliance, applianceIndex }: CircuitsDropAreaProps) {
  const { setNodeRef, active, isOver } = useDroppable({ id });
  const isAvailableCircuitsDropArea = id === AVAILABLE_CIRCUITS_DROP_AREA_ID;
  const availableCircuits = useAvailableCircuits();
  const applianceCircuits = useApplianceCircuits(id);
  const circuitsToShow = isAvailableCircuitsDropArea ? availableCircuits : applianceCircuits;
  const dispatch = useDispatch();
  const allCircuits = useSelector(selectAllCircuits);
  const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const { isDroppable, activeCircuit } = useMemo(() => {
    // If no active circuit, then the drop area is not droppable
    if (!active?.id) return { isDroppable: false };

    // Only circuits that match the appliance type can be dropped into the appliance circuits area
    const activeCircuit = allCircuits.find((circuit) => circuit.oem_circuit_id === active.id);

    // Any circuits can be dropped into the available circuits area
    if (isAvailableCircuitsDropArea) return { isDroppable: true, activeCircuit };

    return { isDroppable: activeCircuit?.clipsal_monitors === appliance?.appliance_type, activeCircuit };
  }, [availableCircuits, appliance, active]);

  const showGhostCircuit = useMemo(() => {
    // Show a ghost circuit if the drop area is droppable and a circuit is over the available circuits drop area
    const canDropToAvailableCircuitDropArea = isAvailableCircuitsDropArea && !!activeCircuit?.appliance_id;

    // No point showing a ghost circuit if the circuit is already assigned to the appliance
    const canDropToCurrentApplianceCircuitDropArea = !isAvailableCircuitsDropArea && activeCircuit?.appliance_id !== id;

    return isDroppable && isOver && (canDropToAvailableCircuitDropArea || canDropToCurrentApplianceCircuitDropArea);
  }, [isDroppable, isOver, activeCircuit, id, isAvailableCircuitsDropArea]);

  const { backgroundColor, darkBackgroundColor, opacity } = useMemo(() => {
    if (!active?.id)
      return {
        backgroundColor: 'gray.50',
        darkBackgroundColor: 'gray.800',
        opacity: 1,
      };

    return {
      backgroundColor: isDroppable ? 'green.50' : 'red.50',
      darkBackgroundColor: isDroppable ? 'green.500' : 'red.500',
      opacity: isDroppable ? 1 : 0.7,
    };
  }, [isDroppable, active]);

  const handleUpdateApplianceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (appliance?.appliance_id)
      dispatch(updateApplianceName({ applianceId: appliance.appliance_id, name: e.target.value }));
  };

  return (
    <Flex
      ref={setNodeRef}
      bg={backgroundColor}
      _dark={{ bg: darkBackgroundColor }}
      borderRadius={10}
      shadow="md"
      opacity={opacity}
      h="100%"
      minH={100}
      data-testid={`circuit-drop-area-${id}`}
    >
      <Box
        w={'12px'}
        minH="100%"
        bg={isAvailableCircuitsDropArea ? 'gray' : COLOURS[applianceIndex]}
        borderTopLeftRadius={10}
        borderBottomLeftRadius={10}
      />
      <Box w={'calc(100% - 12px)'} h="100%" py={2}>
        <HStack justify="space-between" px={4} mb={isAvailableCircuitsDropArea ? 0 : 1}>
          <InputGroup maxW={250}>
            <Input
              variant={'unstyled'}
              borderBottom={isAvailableCircuitsDropArea ? 'none' : '1px solid'}
              borderBottomRadius={0}
              borderColor={'gray.200'}
              color={'gray.600'}
              _dark={{ color: 'gray.400' }}
              value={appliance?.appliance_name ?? 'Available Circuits'}
              isReadOnly={isAvailableCircuitsDropArea}
              onChange={handleUpdateApplianceName}
              data-testid={`input-appliance-name-${id}`}
            />
            {!isAvailableCircuitsDropArea && (
              <InputRightElement pb={4} pointerEvents="none">
                <PencilIcon />
              </InputRightElement>
            )}
          </InputGroup>
          {!isAvailableCircuitsDropArea && appliance ? (
            <DeleteApplianceButton appliance={appliance} />
          ) : (
            <AddApplianceButton />
          )}
        </HStack>
        <HStack wrap={'wrap'} w="100%" p={2} data-testid="circuits">
          {circuitsToShow.map((circuit) => (
            <DraggableCircuit key={circuit.id} id={circuit.oem_circuit_id} name={circuit.circuit_name || 'N/A'} />
          ))}

          {/* Show a ghost circuit if the area is droppable when a circuit is over the drop area */}
          {showGhostCircuit && (
            <Box
              py={2}
              px={isMobileViewport ? 4 : 6}
              opacity={0.7}
              fontWeight={500}
              border={'1px dashed'}
              color={'gray.700'}
              borderRadius="full"
              _dark={{
                color: 'gray.200',
              }}
            >
              {activeCircuit?.circuit_name || 'N/A'}
            </Box>
          )}
        </HStack>
      </Box>
    </Flex>
  );
}
