import React from 'react';
import { Box, Center, Flex, FormLabel, HStack, Input, Select, Text, VStack } from '@chakra-ui/react';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';

import { Circuit } from '../../../../../api/api-device';
import { SelectSortedAssignments } from './SelectSortedAssignments';
import { useUpdateCircuit } from './use-update-circuit';

export const CircuitConfigurationCard = ({ circuit, index }: { circuit: Circuit; index: number }) => {
  const {
    shortenedCircuitId,
    handleSelectMonitor,
    handleChangeCircuitName,
    handleSelectControlledLoad,
    handleSelectControlledLoad2,
  } = useUpdateCircuit(circuit);

  // When clipsal monitor is not recognized, use the not_set icon
  const Icon = CIRCUIT_TYPE_TO_ICON[circuit.clipsal_monitors] ?? CIRCUIT_TYPE_TO_ICON.not_set;

  return (
    <Flex
      shadow={`0px 0px 4px rgba(0, 0, 0, 0.25)`}
      _dark={{
        shadow: `0px 0px 4px rgba(255, 255, 255, 0.25)`,
      }}
      rounded={8}
      w="100%"
    >
      <Box w={'3%'} bg={COLOURS[index]} borderTopLeftRadius={10} borderBottomLeftRadius={10} />
      <Box w={'97%'} p={4}>
        <HStack w="100%">
          <VStack>
            <Text
              bg={COLOURS[index]}
              w="100%"
              px={2}
              textAlign={'center'}
              color="white"
              borderRadius={4}
              fontSize="sm"
              mb={-2}
            >
              {shortenedCircuitId}
            </Text>
            <Box minW={6}>
              <Center h={10}>
                <Icon w={6} h={6} />
              </Center>
            </Box>
          </VStack>
          <VStack w="100%" align={'flex-start'} spacing={0} ml={2}>
            <FormLabel mb={0}>Monitor</FormLabel>
            <SelectSortedAssignments
              value={circuit.clipsal_monitors}
              onChange={handleSelectMonitor}
              dataTestId={`select-monitor-${index}`}
            />
          </VStack>
        </HStack>

        <VStack w="100%" align={'flex-start'} spacing={0} mt={2}>
          <FormLabel mb={0}>Name</FormLabel>
          <Input
            value={circuit.circuit_name}
            onChange={handleChangeCircuitName}
            data-testid={`input-circuit-name-${index}`}
          />
        </VStack>

        <HStack mt={2}>
          <VStack w="100%" align={'flex-start'} spacing={0}>
            <FormLabel mb={0}>Controlled Load</FormLabel>
            <Select
              placeholder=""
              value={circuit.controlled_load_yn}
              onChange={handleSelectControlledLoad}
              data-testid={`select-controlled-load-${index}`}
            >
              <option value={'Y'}>Y</option>
              <option value={'N'}>N</option>
            </Select>
          </VStack>
          <VStack w="100%" align={'flex-start'} spacing={0}>
            <FormLabel mb={0}>Controlled Load 2</FormLabel>
            <Select
              placeholder=""
              value={circuit.controlled_load2_yn}
              onChange={handleSelectControlledLoad2}
              data-testid={`select-controlled-load2-${index}`}
            >
              <option value={'Y'}>Y</option>
              <option value={'N'}>N</option>
            </Select>
          </VStack>
        </HStack>
      </Box>
    </Flex>
  );
};
