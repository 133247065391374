import React, { useMemo } from 'react';
import { Box, Skeleton, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';

import { Table } from '../../../../../common/components/Table';
import { selectAllCircuits, selectSelectedMeterId } from '../circuitApplianceConfigSlice';
import { CIRCUIT_TABLE_COLUMNS } from './circuit-table-columns';
import { CircuitConfigurationCard } from './CircuitConfigurationCard';

export const CircuitConfigurationContents = ({ isLoaded }: { isLoaded: boolean }) => {
  const allCircuits = useSelector(selectAllCircuits);
  const selectedMeterId = useSelector(selectSelectedMeterId);
  const circuitsInView = useMemo(() => {
    // sort circuits by oem_circuit_id in ascending order
    // this is especially useful for CATCH meters so we can determine channels more easily
    return allCircuits
      .filter((circuit) => circuit.site_id === selectedMeterId)
      .sort((a, b) => a.oem_circuit_id.localeCompare(b.oem_circuit_id));
  }, [allCircuits, selectedMeterId]);
  const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Box>
      {isMobileViewport ? (
        <VStack>
          {circuitsInView.map((circuit, index) => (
            <CircuitConfigurationCard key={circuit.oem_circuit_id} circuit={circuit} index={index} />
          ))}
        </VStack>
      ) : (
        <Table
          id={'circuits-table'}
          totalPageCount={1}
          isLoaded={isLoaded}
          columns={CIRCUIT_TABLE_COLUMNS as Column[]}
          data={circuitsInView}
          isFetchMoreDataLoaded
          loadingComponent={
            <VStack w="100%" overflow={'auto'} spacing={4}>
              {Array.from({ length: 7 }).map((_, index) => (
                <Skeleton h="30px" w="100%" key={index} />
              ))}
            </VStack>
          }
        />
      )}
    </Box>
  );
};
