import React from 'react';
import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { ApplianceToSave } from '../../../../api/api-appliance';
import { CustomButton } from '../../../../common/components/CustomButton';
import { selectSite } from '../../siteSlice';
import { useSiteCircuits, useUpdateSiteAppliancesMutation, useUpdateSiteCircuitsMutation } from '../meterSetupApi';
import { setAppliances } from '../meterSetupSlice';
import {
  selectAllAppliances,
  selectAllCircuits,
  setAllAppliances,
  setAllCircuits,
} from './circuitApplianceConfigSlice';

export function SaveCircuitsAndApplianceButton({ isDisabled }: { isDisabled: boolean }) {
  const allAppliances = useSelector(selectAllAppliances);
  const allCircuits = useSelector(selectAllCircuits);
  const toast = useToast({ duration: 5000, isClosable: true });
  const { clipsal_solar_id: siteId } = useSelector(selectSite);
  const dispatch = useDispatch();
  const { refetch } = useSiteCircuits();
  const [updateCircuits, { isLoading: isUpdatingCircuits }] = useUpdateSiteCircuitsMutation();
  const [updateAppliances, { isLoading: isUpdatingAppliances }] = useUpdateSiteAppliancesMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSaveAppliances = async () => {
    // For circuits added to new appliances, set `appliance_id` to null
    const circuitsToSave = allCircuits.map((circuit) => ({
      ...circuit,
      appliance_id: circuit.appliance_id && circuit.appliance_id < 0 ? null : circuit.appliance_id,
    }));

    // Save the appliance configurations with the updated circuits
    const applianceToSave: ApplianceToSave[] = allAppliances
      .map((appliance) => ({
        ...appliance,
        appliance_id: appliance.appliance_id >= 0 ? appliance.appliance_id : null,
        circuits: allCircuits
          .filter((circuit) => circuit.appliance_id === appliance.appliance_id)
          .map((circuit) => circuit.id),
      }))
      .filter((appliance) => appliance.circuits.length > 0);

    toast.closeAll();

    try {
      await updateCircuits({ siteId, circuits: circuitsToSave }).unwrap();
      const updatedAppliances = await updateAppliances({ siteId, appliances: applianceToSave }).unwrap();

      // refetch the circuits to get the updated data for new appliances
      const updatedCircuits = await refetch().unwrap();

      // update data in redux to ensure the UI is in sync with other parts of the app
      // once we refactor the meter setup page to use rtk query, we can remove this
      dispatch(setAppliances(updatedAppliances));

      // update data in redux to ensure the UI is in sync with other parts of the app
      dispatch(setAllAppliances(cloneDeep(updatedAppliances).sort((a, b) => b.appliance_id - a.appliance_id)));
      dispatch(setAllCircuits(updatedCircuits));

      toast({
        title: 'Appliances and circuits have been updated!',
        status: 'success',
      });
    } catch (error) {
      console.error('Error updating appliances and circuits:', error);
      toast({
        title: 'Error updating appliances and circuits!',
        status: 'error',
      });
    }
  };

  const handleClickSaveAppliances = () => {
    const hasAppliancesWithNoCircuits = allAppliances.some((appliance) => {
      return allCircuits.filter((circuit) => circuit.appliance_id === appliance.appliance_id).length === 0;
    });
    hasAppliancesWithNoCircuits ? onOpen() : handleSaveAppliances();
  };

  return (
    <Box mt={12} mb={6}>
      <CustomButton
        w="100%"
        maxW={300}
        onClick={handleClickSaveAppliances}
        isDisabled={isDisabled}
        isLoading={isUpdatingCircuits || isUpdatingAppliances}
        data-testid="save-circuits-and-appliances-button"
      >
        Save Circuits and Appliances
      </CustomButton>

      <AlertDialogModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSaveAppliances}
        onCancel={onClose}
        header={'Appliances with no circuits will be deleted.'}
        subHeader={'Are you sure you want to save?'}
        confirmButtonName="Confirm"
      />
    </Box>
  );
}
