import React, { useEffect, useState } from 'react';
import { ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { GridSourceIcon } from 'clipsal-cortex-icons/src/custom-appliance-icons';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { Card } from 'clipsal-cortex-ui/src/components/card/Card';

import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as WireMeter } from '../../../../assets/images/ct_configuration.svg';
import { CustomButton } from '../../../../common/components/CustomButton';
import { MeterCTSelect } from '../../../../common/components/MeterCTSelect';
import { SIDENAV_WIDTH } from '../../../../common/constants';
import { setHasUnsavedChanges } from '../../../wizard/wizardSlice';
import { selectSite } from '../../siteSlice';
import { DISPLAYED_VOLTAGE_REF_TYPES } from '../meter-setup-helpers';
import { selectRawMeters } from '../meterSetupSlice';
import { COMMON_NEXT_BUTTON_PROPS } from './ct-configuration-helpers';
import { TabComponentProps } from './CTConfigurationContainer';
import { FieldIconWrapper } from './FieldIconWrapper';
import { EmptySelectedCircuitsCard, SelectedCircuitsCard } from './SelectedCircuitsCard';

export function GridCTConfiguration({
  control,
  errors,
  register,
  getValues,
  trigger,
  onChangeTab,
  onCircuitChange,
  circuitState,
  updateCTFunctions,
  isUpdatingCTs,
  openThreePhaseWarningDrawer,
  setOpenThreePhaseWarningDrawer,
}: TabComponentProps) {
  const rawMeters = useSelector(selectRawMeters);
  const site = useSelector(selectSite);
  const phaseOptions = DISPLAYED_VOLTAGE_REF_TYPES[site.electrical_config];
  const dispatch = useAppDispatch();
  const shouldShowCTConfigurationModal = localStorage.getItem('CTConfigurationModalDismissed') !== 'true';
  const { fields, append, remove } = useFieldArray({
    name: `grid` as const,
    control,
  });
  const { solar, battery, hybrid, load, grid } = getValues();
  const { values, pendingMeterIds } = circuitState;
  const hasPendingValues = !!pendingMeterIds.length;
  const [hasMaxGridCTs, setHasMaxGridCTs] = useState(false);
  const reservedCTs = [
    ...solar.reduce((acc: ChannelData[], val) => {
      if (val?.circuits) acc.push(...val.circuits);
      return acc;
    }, []),
    ...battery.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
    ...load.reduce((acc: ChannelData[], val) => {
      if (val?.circuits) acc.push(...val.circuits);
      return acc;
    }, []),
    ...Object.values(hybrid).flat(),
  ];

  async function handleSelectCircuit(circuitData: ChannelData) {
    const isCTSelected = fields.find(
      (currentlySelectedCT: ChannelData) => circuitData.ww_circuit_id === currentlySelectedCT.ww_circuit_id
    );
    if (isCTSelected) {
      // Remove the previously selected circuit
      const indexOfCTField = fields.findIndex(
        (selectedCircuit) => selectedCircuit.ww_circuit_id === circuitData.ww_circuit_id
      );
      remove(indexOfCTField);
      setHasMaxGridCTs(false);
    } else if (grid.length < 3) {
      const phase = phaseOptions?.find((phaseOption) => phaseOption?.value === circuitData.voltage_reference);
      const channel = {
        ...circuitData,
        circuit_name: `Grid ${phase?.label?.charAt(phase?.label?.length - 1) ?? ''}`,
        clipsal_monitors: site.metering_configuration === 'NET' ? 'ac_load_net' : 'ac_load',
      };
      append(channel, { shouldFocus: false });
    } else {
      setHasMaxGridCTs(true);
    }

    // revalidate form if there is any errors for grid tab
    if (errors?.grid) await trigger('grid');
  }

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  return (
    <Flex direction="column" data-testid="grid-tab-panel">
      <Heading size="sm" fontWeight="500" mb={4}>
        Grid
      </Heading>
      <Text size="sm" mb={8}>
        Select which CT's are measuring the grid
      </Text>
      {errors.grid && (
        <Flex pb={4}>
          <WarningIcon color="red.500" w={5} h={5} ml={2} pt={1} />
          <Text color="red.500" ml={4}>
            {(errors.grid as any)?.message}
          </Text>
        </Flex>
      )}

      {hasMaxGridCTs && (
        <Flex pb={4}>
          <WarningIcon color="red.500" w={5} h={5} ml={2} pt={1} />
          <Text color="red.500" ml={4}>
            A maximum of 3 CTs can be allocated to the grid
          </Text>
        </Flex>
      )}

      {Object.values(rawMeters).map((meter, idx) => {
        const selectedCTs = fields.filter((circuit) => circuit.ww_circuit_id.includes(meter.ww_device_id));
        return (
          <MeterCTSelect
            meterIndex={idx}
            key={meter.ww_device_id}
            meter={meter}
            onCTClick={handleSelectCircuit}
            selectedCTs={selectedCTs}
            reservedCTs={reservedCTs}
          />
        );
      })}

      <Box>
        {fields.length ? (
          fields.map((field, index) => {
            const switchValues = values.find((value) => value.circuitId === field.ww_circuit_id);
            if (!switchValues) throw new Error(`Unable to find switch value for circuit id ${field.ww_circuit_id}`);

            const errorsAtIndex = errors?.grid?.[index];
            const handleDismiss = (index: number) => {
              remove(index);
              setHasMaxGridCTs(false);
            };

            return (
              <Box key={field.id} onChange={() => dispatch(setHasUnsavedChanges(true))}>
                <Flex pb={4}>
                  {errorsAtIndex && <WarningIcon color="red.500" w={5} h={5} ml={2} pt={1} />}
                  <Text color="red.500" ml={4}>
                    {errorsAtIndex?.circuit_name?.message}
                  </Text>
                </Flex>
                <SelectedCircuitsCard
                  key={field.ww_circuit_id}
                  source="grid"
                  field={field}
                  index={index}
                  register={register}
                  onDismiss={() => handleDismiss(index)}
                  iconComponent={
                    <FieldIconWrapper>
                      <GridSourceIcon h={12} w={12} />
                    </FieldIconWrapper>
                  }
                  onCircuitChange={onCircuitChange}
                  polarityValue={switchValues.polarity}
                  voltageRefValue={switchValues.voltageReference}
                  ctRating={switchValues.ctRating}
                />
              </Box>
            );
          })
        ) : (
          <Box mb={4}>
            <EmptySelectedCircuitsCard iconComponent={<GridSourceIcon h={12} w={12} />} />
          </Box>
        )}
      </Box>
      <Flex
        position={'fixed'}
        bottom={16}
        left={0}
        zIndex={99}
        width="100%"
        justifyContent={'center'}
        paddingLeft={isMobileViewport ? 0 : SIDENAV_WIDTH}
        paddingRight={isMobileViewport ? 0 : 15}
        pointerEvents="none"
      >
        <Flex width={isMobileViewport ? '100%' : '60%'}>
          <Button
            data-testid="submit-grid-button"
            aria-label="Submit Grid"
            rightIcon={isUpdatingCTs ? <Spinner size="sm" /> : <ArrowForwardIcon />}
            {...COMMON_NEXT_BUTTON_PROPS}
            onClick={() => updateCTFunctions['grid']()}
            isDisabled={hasPendingValues || isUpdatingCTs}
          >
            Next
          </Button>
        </Flex>
      </Flex>

      {shouldShowCTConfigurationModal && <CTConfigurationModal />}
      <ThreePhaseWarningBottomDrawer
        isOpen={openThreePhaseWarningDrawer}
        onClose={() => setOpenThreePhaseWarningDrawer(false)}
        onChangeTab={onChangeTab}
      />
    </Flex>
  );
}

export function CTConfigurationModal() {
  const [isChecked, setIsChecked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => onOpen(), [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="fit-content" maxWidth={'90vw'}>
        <ModalHeader fontSize="md" pr={12}>
          How to wire CTs to the meter?
        </ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody bg={'backgroundGrey.500'} display="flex" justifyContent="center" mx={3} px={2} height="60vh">
          <Icon viewBox="0 0 284 282" as={WireMeter} w="100%" h="100%" />
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column" alignItems="flex-start">
          <Checkbox
            mb={4}
            border="darkgray"
            isChecked={isChecked}
            onChange={(e) => {
              localStorage.setItem('CTConfigurationModalDismissed', `${e.target.checked}`);
              setIsChecked(e.target.checked);
            }}
          >
            Don't show me again
          </Checkbox>
          <CustomButton
            type="button"
            mx="auto"
            w="fit-content"
            px={10}
            py={4}
            my={0}
            onClick={onClose}
            data-testid="wire-cts-to-meter-modal-done"
          >
            Done
          </CustomButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface ThreePhaseWarningBottomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onChangeTab: (index: number, gotoClickedTabIndex?: boolean) => void;
}

function ThreePhaseWarningBottomDrawer({ isOpen, onClose, onChangeTab }: ThreePhaseWarningBottomDrawerProps) {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const dividerColor = useColorModeValue('#0000001A', 'dusk100.400');

  const handleContinue = () => {
    onClose();
    onChangeTab(1, true);
  };

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent mb={4} backgroundColor="transparent">
        <Center mr={3} ml={isMobileViewport ? 0 : SIDENAV_WIDTH} data-testid="grid-no3ct-drawer">
          <Card rounded="10" p="10px 0px" w={['95%', '60%']}>
            <VStack>
              <Text px="25px" textAlign="center" fontWeight={600} mb={2}>
                3 Grid CT's expected for a 3 phase site. Are you sure you want to proceed? Incorrect configuration of
                CT's can lead to incorrect data displayed in the Cortex app
              </Text>
              <Divider color={dividerColor} />
              <Button
                data-testid="grid-no3ct-continue-button"
                my={0}
                color="customRed.500"
                borderColor="white"
                bgColor="white"
                w="100%"
                onClick={handleContinue}
              >
                Continue
              </Button>
            </VStack>
          </Card>
        </Center>

        <Center mr={3} ml={isMobileViewport ? 0 : SIDENAV_WIDTH}>
          <Button
            w={['95%', '60%']}
            mt={1}
            py={8}
            color="customBlue.500"
            bgColor="white"
            onClick={onClose}
            data-testid="grid-no3ct-cancel-button"
          >
            Cancel
          </Button>
        </Center>
      </DrawerContent>
    </Drawer>
  );
}
