import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Circuit } from '../../../../../api/api-device';
import { selectAssignments } from '../../../system-details/systemDetailsSlice';
import { selectAllCircuits, setAllCircuits } from '../circuitApplianceConfigSlice';

export const useUpdateCircuit = (circuit: Circuit) => {
  const assignments = useSelector(selectAssignments);
  const dispatch = useDispatch();
  const allCircuits = useSelector(selectAllCircuits);

  const shortenedCircuitId = useMemo(() => {
    const oemCircuitId = circuit.oem_circuit_id;
    const shortenedId = oemCircuitId?.split('_').pop(); // get the last part of the circuit id for easier reading

    return shortenedId ?? oemCircuitId;
  }, []);

  const handleSelectMonitor = (newMonitor: string) => {
    const hasAssignmentChanged = circuit.clipsal_monitors !== newMonitor;
    if (hasAssignmentChanged) {
      const selectedAssignment = assignments.find(({ assignment }) => assignment === newMonitor);
      dispatch(
        setAllCircuits(
          allCircuits.map((c) =>
            c.oem_circuit_id === circuit.oem_circuit_id
              ? {
                  ...c,
                  clipsal_monitors: newMonitor,
                  // change circuit_name to display_name if the assignment has changed
                  circuit_name: selectedAssignment?.display_name ?? 'Not Set',
                }
              : c
          )
        )
      );
    }
  };

  const handleChangeCircuitName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAllCircuits(
        allCircuits.map((c) =>
          c.oem_circuit_id === circuit.oem_circuit_id ? { ...c, circuit_name: e.target.value } : c
        )
      )
    );
  };

  const handleSelectControlledLoad = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      setAllCircuits(
        allCircuits.map((c) =>
          c.oem_circuit_id === circuit.oem_circuit_id
            ? { ...c, controlled_load_yn: e.target.value as Circuit['controlled_load_yn'] }
            : c
        )
      )
    );
  };
  const handleSelectControlledLoad2 = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      setAllCircuits(
        allCircuits.map((c) =>
          c.oem_circuit_id === circuit.oem_circuit_id
            ? { ...c, controlled_load2_yn: e.target.value as Circuit['controlled_load2_yn'] }
            : c
        )
      )
    );
  };

  return {
    shortenedCircuitId,
    handleSelectMonitor,
    handleChangeCircuitName,
    handleSelectControlledLoad,
    handleSelectControlledLoad2,
  };
};
