import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  Heading,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Link, useSearchParams } from 'react-router-dom';

import { MobileTopNav } from '../../../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT } from '../../../../common/constants';
import { LogTable } from './LogTable';
import { MeterConfigurationChart } from './meter-chart/MeterConfigurationChart';
import { MeterForm } from './meter-form/MeterForm';
import { useMeter, useSiteMeter } from './meterConfigApi';
import { MeterDetails } from './MeterDetails';
import { MeterSearchForm } from './MeterSearchForm';

export const MeterConfiguration = () => {
  const [urlParams] = useSearchParams();
  const [{ meterId, siteMeterId }, setMeterId] = useState({
    meterId: urlParams.get('meterId') ?? '',
    siteMeterId: Number(urlParams.get('siteMeterId')) || null,
  });
  const { data: meter } = useMeter(meterId);
  const { data: siteMeter } = useSiteMeter(siteMeterId);
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box px={[0, 0, 10]}>
      {isMobileViewport ? (
        <MobileTopNav title={'Meter Configuration'} backURL={'/account/home'} />
      ) : (
        <Button as={Link} to={'/account/home'} mt={3}>
          Back
        </Button>
      )}
      <Center>
        <Box
          w={isMobileViewport ? '100%' : '90%'}
          minH={isMobileViewport ? '90vh' : '60vh'}
          bg={'white'}
          _dark={{ bg: 'gray.900' }}
          borderRadius={isMobileViewport ? 0 : 10}
          overflow="hidden"
        >
          {isMobileViewport || (
            <Heading mb={5} size={'lg'} paddingLeft={isMobileViewport ? 0 : 6} paddingTop={isMobileViewport ? 0 : 6}>
              Meter Configuration
            </Heading>
          )}
          <Divider />

          <MeterSearchForm
            meterId={meterId}
            onUpdateMeterId={(meterId, siteMeterId) =>
              setMeterId((prevState) => ({ ...prevState, meterId, siteMeterId }))
            }
          />

          {meter && (
            <Box mb={isMobileViewport ? BOTTOM_NAV_HEIGHT : undefined}>
              <Divider mt={4} />

              <Flex mx={4} my={4} flexDirection={'column'}>
                <Heading size="md" fontWeight={600} mb={4}>
                  Meter Details - {meter.id}
                </Heading>
                <MeterDetails meterId={meterId} meterData={meter} />
                <Divider my={4} />
                <Heading size="md" fontWeight={600} mb={4}>
                  Visualise Meter Configuration
                </Heading>
                <MeterConfigurationChart serialNumber={meterId} channels={meter.channels} />
                <Divider my={4} />
                <MeterForm meter={meter} siteMeter={siteMeter} serialNumber={meterId} />
                <Divider my={4} />
                <Button onClick={onToggle} w="fit-content" mb={4} data-testid="show-log-btn">
                  {isOpen ? 'Close' : 'View'} Logs
                </Button>
                <Collapse in={isOpen} animateOpacity>
                  {isOpen && <LogTable meterId={meterId} timezone={meter.timezone} />}
                </Collapse>
              </Flex>
            </Box>
          )}
        </Box>
      </Center>
    </Box>
  );
};
