import 'react-phone-input-2/lib/bootstrap.css';
import '../../../styles/react-phone-input-2.css';

import React, { useMemo, useRef, useState } from 'react';
import { CloseIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { BsPlusCircle } from 'react-icons/bs';
import { FaUserAlt } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { ErrorResponse, get } from '../../../api/api-helpers';
import { UserData } from '../../../api/api-user';
import { CustomButton } from '../../../common/components/CustomButton';
import { FieldData } from '../../../common/types/types';
import { debounceEvent } from '../../../utils/component-helpers';
import { getPhoneValidationSchema } from '../../../utils/phone-validation';
import { selectSite } from '../siteSlice';
import { CommissioningCompleteModal } from './CommissioningCompleteModal';
import { EMPTY_CUSTOMER_TEMPLATE } from './customer-details-helpers';
import { useAssociateExistingUserMutation, usePostNewUserMutation } from './siteUsersApi';
import { UserDetails } from './UserDetails';

const customerSchema = yup.object().shape({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  email: yup.string().trim().email('Email is not valid').required('Email is required'),
  phoneNumber: getPhoneValidationSchema(),
});

type Customer = typeof EMPTY_CUSTOMER_TEMPLATE;

const INITIAL_STATE = {
  isLoading: false,
  searchedUsers: [] as UserData[],
  searchTerm: '',
  selectedUser: null as UserData | null,
  isExisingCustomer: false,
};

const FIELD_TEMPLATES: FieldData<Customer>[] = [
  {
    label: 'First name',
    placeholder: 'John',
    name: 'firstName',
    type: 'text',
  },
  {
    label: 'Last name',
    placeholder: 'Smith',
    name: 'lastName',
    type: 'text',
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'user@domain.com',
    icon: {
      position: 'left',
      icon: <EmailIcon />,
    },
    type: 'email',
  },
  {
    label: 'Phone number',
    name: 'phoneNumber',
    placeholder: '+6112345678',
    icon: {
      position: 'left',
      icon: <PhoneIcon />,
    },
    type: 'tel',
  },
];

type InviteCustomerModalProps = {
  isDisabled?: boolean;
  hasInvitedUsers: boolean;
};

export const InviteCustomerModal = ({ isDisabled, hasInvitedUsers }: InviteCustomerModalProps) => {
  const {
    isOpen: isOpenInviteCustomersModal,
    onOpen: onOpenInviteCustomersModal,
    onClose: onCloseInviteCustomersModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCommissioningCompleteModal,
    onOpen: onOpenCommissioningCompleteModal,
    onClose: onCloseCommissioningCompleteModal,
  } = useDisclosure();
  const {
    register,
    reset,
    getValues,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setValue,
  } = useForm<Customer>({
    resolver: yupResolver(customerSchema),
    defaultValues: EMPTY_CUSTOMER_TEMPLATE,
    reValidateMode: 'onChange',
  });

  const { clipsal_solar_id: siteId } = useSelector(selectSite);
  const [postNewUser, { isLoading: isInviting }] = usePostNewUserMutation();
  const [associateUser, { isLoading: isAssociating }] = useAssociateExistingUserMutation();
  const toast = useToast({ isClosable: true });
  const { phoneInputClassName, hoverBgColor } = useColorModeValue(
    {
      phoneInputClassName: 'light-mode-phone-input',
      hoverBgColor: 'gray.50',
    },
    {
      phoneInputClassName: 'dark-mode-phone-input dark-mode-phone-input-transparent-bg',
      hoverBgColor: 'gray.500',
    }
  );

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [{ isLoading, searchedUsers, searchTerm, isExisingCustomer, selectedUser }, setState] = useState(INITIAL_STATE);

  const handleClose = () => {
    reset(EMPTY_CUSTOMER_TEMPLATE);
    if (searchInputRef.current) searchInputRef.current.value = '';
    setState(INITIAL_STATE);
    onCloseInviteCustomersModal();
  };

  const handleSearch = useMemo(
    () =>
      debounceEvent(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        setState((prevState) => ({ ...prevState, searchTerm, searchedUsers: [], isLoading: true }));
        const usersMatchingTerm = await get<PaginatedResponseV2<UserData>>(
          `/fleet/v2/users?search_term=${searchTerm}&limit=5`
        );
        setState((prevState) => ({ ...prevState, searchedUsers: usersMatchingTerm.results, isLoading: false }));
      }, 500),
    [searchTerm]
  );

  const handleSubmit = async ({ firstName, lastName, email, phoneNumber }: Customer) => {
    try {
      await postNewUser({
        user_first_name: firstName,
        user_last_name: lastName,
        user_email: email.trim().toLowerCase(),
        user_phone: phoneNumber,
        clipsal_solar_id: siteId,
        role: 'SYSTEM_OWNER',
      }).unwrap();

      toast({ status: 'info', description: 'Customer invited successfully' });

      // Open commissioning complete modal if no users had been invited yet
      if (!hasInvitedUsers) onOpenCommissioningCompleteModal();
      handleClose();
    } catch (e) {
      const error = e as AxiosError<ErrorResponse>;
      toast({ status: 'error', description: error.message ?? 'Failed to invite customer!' });
    }
  };

  function getUsersListJSX() {
    return searchedUsers.length ? (
      searchedUsers.map((user, userIndex) => (
        <Box
          borderBottom={userIndex === searchedUsers.length - 1 ? '1px solid grey' : undefined}
          borderTop={'1px solid grey'}
          key={`existing-user-${userIndex}`}
          _hover={{ background: hoverBgColor }}
          onClick={async () => setState((prevState) => ({ ...prevState, selectedUser: user }))}
          cursor={'pointer'}
          p={2}
          data-testid={`customer-searchTermResults-${userIndex}`}
        >
          <Flex align={'center'} as={'button'} type={'button'}>
            <Box mr={2}>
              <FaUserAlt />
            </Box>
            <Text fontWeight={600} fontSize={'sm'} data-private noOfLines={1} textAlign={'left'}>
              {user.user_full_name} ({user.user_email})
            </Text>
          </Flex>
        </Box>
      ))
    ) : (
      <Box>No results</Box>
    );
  }

  return (
    <Box>
      <CustomButton
        onClick={onOpenInviteCustomersModal}
        my={0}
        p={4}
        minW={75}
        h={35}
        leftIcon={<Icon as={BsPlusCircle} />}
        isDisabled={isDisabled}
        data-testid="add-customer-btn"
      >
        Add
      </CustomButton>

      <CommissioningCompleteModal
        isOpen={isOpenCommissioningCompleteModal}
        onClose={onCloseCommissioningCompleteModal}
        onInviteMoreUsers={() => {
          onCloseCommissioningCompleteModal();
          onOpenInviteCustomersModal();
        }}
      />

      <Modal isOpen={isOpenInviteCustomersModal} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent minH={250} mx={4}>
          <ModalHeader fontWeight={700}>Invite Customer</ModalHeader>
          <ModalCloseButton data-testid="close-invite-customers-form" />
          <ModalBody>
            <Flex>
              <Heading size={'sm'} fontWeight={500}>
                Is this an existing customer?
              </Heading>
              <Switch
                ml={2}
                colorScheme={'primaryBranding'}
                data-testid={`is-existing-customer-btn`}
                onChange={(e) => {
                  if (searchInputRef.current) searchInputRef.current.value = '';
                  reset(EMPTY_CUSTOMER_TEMPLATE);
                  setState((prevState) => ({
                    ...prevState,
                    isExisingCustomer: e.target.checked,
                    searchedUsers: [],
                    searchTerm: '',
                    selectedUser: null,
                  }));
                }}
              />
            </Flex>

            {isExisingCustomer ? (
              <Box mt={4} data-testid="invite-exisiting-user-form">
                {selectedUser ? (
                  <UserDetails
                    userId={selectedUser.user_id}
                    username={selectedUser.user_name}
                    email={selectedUser.user_email}
                    firstName={selectedUser.user_first_name}
                    lastName={selectedUser.user_last_name}
                    fullName={selectedUser.user_full_name}
                    invitiationStatus="UNINVITED"
                    index={0}
                    containerProps={{
                      border: '1px solid',
                      borderColor: 'gray.200',
                      borderRadius: 10,
                      position: 'relative',
                    }}
                  >
                    <IconButton
                      icon={<CloseIcon w={2.5} h={2.5} color="white" />}
                      _hover={{ bg: 'gray.500' }}
                      position={'absolute'}
                      bg={'gray.600'}
                      borderRadius={50}
                      top={-3}
                      right={-2}
                      minW={6}
                      h={6}
                      data-testid="remove-selected-user"
                      aria-label="Remove user"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedUser: null,
                          searchedUsers: [],
                          searchTerm: '',
                        }))
                      }
                    />
                  </UserDetails>
                ) : (
                  <>
                    <FormControl my={3}>
                      <FormLabel>Search by name or email</FormLabel>
                      <Input
                        data-private
                        ref={searchInputRef}
                        placeholder={'Search name or email'}
                        onChange={handleSearch}
                        type={'text'}
                        data-testid={`customer-searchTermInput`}
                      />
                    </FormControl>

                    {searchTerm && (
                      <Box my={4}>
                        <Heading size="md" mb={2}>
                          Select a customer
                        </Heading>

                        {!isLoading ? getUsersListJSX() : <CenteredLoader />}
                      </Box>
                    )}
                  </>
                )}
                <CustomButton
                  isDisabled={!selectedUser}
                  isLoading={isAssociating}
                  minW={180}
                  data-testid="invite-existing-user-button"
                  onClick={async () => {
                    if (!selectedUser?.user_id) return;
                    const response = await associateUser({ user_id: selectedUser.user_id, clipsal_solar_id: siteId });
                    const isError = 'error' in response;
                    const status = isError ? 'error' : 'info';
                    const description = isError ? response.error?.message : 'Customer invited successfully';
                    toast({ status, description });
                    handleClose();
                  }}
                >
                  Invite
                </CustomButton>
              </Box>
            ) : (
              <Box as="form" onSubmit={handleFormSubmit(handleSubmit)} data-testid="invite-new-user-form">
                {FIELD_TEMPLATES.map((fieldTemplate) => (
                  <FormControl
                    isInvalid={!!errors?.[fieldTemplate.name]}
                    key={fieldTemplate.name}
                    id={fieldTemplate.name}
                    my={3}
                  >
                    <FormLabel>{fieldTemplate.label}</FormLabel>
                    {fieldTemplate.name === 'phoneNumber' ? (
                      <Box data-private data-testid={`${fieldTemplate.name}`} className={phoneInputClassName}>
                        <PhoneInput
                          isValid={!errors?.[fieldTemplate.name]}
                          placeholder="+XX 0XXX XXX XXX"
                          country="au"
                          autoFormat
                          masks={{ au: '.... ... ...' }}
                          preferredCountries={['au', 'nz', 'us']}
                          enableSearch
                          enableAreaCodeStretch
                          value={getValues().phoneNumber}
                          onChange={(phone) => {
                            setValue(`phoneNumber` as any, '+' + phone, {
                              shouldDirty: !!errors?.[fieldTemplate.name],
                              shouldValidate: !!errors?.[fieldTemplate.name],
                            });
                          }}
                        />
                      </Box>
                    ) : (
                      <InputGroup>
                        <Input
                          data-private
                          data-testid={`${fieldTemplate.name}`}
                          placeholder={fieldTemplate.placeholder ?? fieldTemplate.label}
                          {...register(`${fieldTemplate.name}` as const)}
                          type={fieldTemplate.type}
                        />

                        {fieldTemplate.icon && fieldTemplate.icon.position === 'left' && (
                          <InputLeftElement>{fieldTemplate.icon.icon}</InputLeftElement>
                        )}
                      </InputGroup>
                    )}
                    <FormErrorMessage>{errors?.[fieldTemplate.name]?.message}</FormErrorMessage>
                  </FormControl>
                ))}
                <CustomButton minW={180} isLoading={isInviting} data-testid="invite-new-user-button">
                  Invite
                </CustomButton>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
