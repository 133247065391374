import './../dashboard/dashboard.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BatteryUpgradeOpportunity, BatteryUpsellInvitationStatus } from '../../api/api-battery-upsell';
import { MultiToggleSwitch } from '../../common/components/MultiToggleSwitch';
import { Table, TablePagination } from '../../common/components/Table';
import { BOTTOM_NAV_HEIGHT, TOP_NAV_SPACING_AFFORDANCE } from '../../common/constants';
import { SearchIcon } from '../../styles/custom-icons';
import { debounceEvent } from '../../utils/component-helpers';
import { useTableColumns } from './battery-upsell-columns';
import {
  BATTERY_INTEREST_STATUS_CONFIG,
  BatteryUpgradeOpportunitiesQueryParams,
  getTableHeader,
  getTableSubHeader,
  MOBILE_COLUMN_DISPLAY_TYPES,
} from './battery-upsell-helpers';
import { useGetBatteryUpgradeOpportunities, useTotalBatteryUpsellSites } from './batteryUpsellApi';

export function BatteryUpsellSitesTable() {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  );

  const defaultTablePageSize = isMobileViewport ? 5 : 10;

  const [columns, [selectedColumnDisplayType, setSelectedColumnDisplayType]] = useTableColumns();
  const clickedRowData = useRef<BatteryUpgradeOpportunity | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [{ pageIndex, pageSize, orderBy, sortOrder, searchTerm, invitationStatus }, setState] = useState({
    invitationStatus: (urlParams.get('invitationStatus') || 'all') as BatteryUpsellInvitationStatus | 'all',
    pageSize: Number(urlParams.get('pageSize')) || (isMobileViewport ? 5 : 10),
    pageIndex: Number(urlParams.get('pageIndex')) || 0,
    orderBy: urlParams.get('orderBy') || 'best_simulation__savings_yearly_dollars',
    sortOrder: urlParams.get('sortOrder') || 'DESC',
    searchTerm: urlParams.get('searchTerm') || '',
  });

  const queryParams = useMemo<BatteryUpgradeOpportunitiesQueryParams>(() => {
    // pagination params are required
    const params: BatteryUpgradeOpportunitiesQueryParams = {
      limit: pageSize,
      offset: pageSize * pageIndex,
    };
    if (searchTerm) params.search_term = searchTerm;
    const orderDirection = sortOrder === 'ASC' ? '' : '-';
    if (orderBy) params.ordering = `${orderDirection}${orderBy}`;
    if (invitationStatus !== 'all') params.invitation_status = invitationStatus;

    return params;
  }, [pageIndex, pageSize, searchTerm, orderBy, sortOrder, invitationStatus]);

  useEffect(() => {
    // update url params to make url shareable
    // eslint-disable-next-line max-len
    let newUrl = `/upsells/home?pageSize=${pageSize}&pageIndex=${pageIndex}&invitationStatus=${invitationStatus}`;
    if (orderBy) newUrl += `&orderBy=${orderBy}&sortOrder=${sortOrder}`;
    if (searchTerm) newUrl += `&searchTerm=${searchTerm}`;
    navigate(newUrl);
  }, [pageIndex, pageSize, searchTerm, orderBy, sortOrder, invitationStatus]);

  const { sites, isLoading, isFetching, totalPages } = useGetBatteryUpgradeOpportunities(queryParams, pageSize);
  const { isFetchingTotalSites, total } = useTotalBatteryUpsellSites();

  const { backgroundColor, iconColor, buttonHoverColor, switchClassName } = useColorModeValue(
    {
      backgroundColor: 'white',
      iconColor: 'dusk100.500',
      buttonHoverColor: 'gray.200',
      switchClassName: 'light-mode-toggle-switch',
    },
    {
      backgroundColor: 'gray.900',
      iconColor: 'dusk100.200',
      buttonHoverColor: 'whiteAlpha.300',
      switchClassName: 'dark-mode-toggle-switch',
    }
  );

  const handleBateryInterestStatusChange = (newinvitationStatus: BatteryUpsellInvitationStatus | 'all') => {
    if (newinvitationStatus === invitationStatus) return;
    if (searchInputRef.current?.value) searchInputRef.current.value = '';
    if (clickedRowData.current) clickedRowData.current = null;
    setState((prevProps) => {
      return {
        ...prevProps,
        invitationStatus: newinvitationStatus,
        orderBy: 'best_simulation__savings_yearly_dollars',
        sortOrder: 'DESC',
        searchTerm: '',
        pageIndex: 0,
      };
    });
  };

  const fetchSitesData = useCallback(
    ({ orderBy = 'best_simulation__savings_yearly_dollars', sortOrder = 'DESC' }) => {
      setState((prevProps) => ({
        ...prevProps,
        orderBy,
        sortOrder,
      }));
    },
    [defaultTablePageSize]
  );

  const handleSearch = debounceEvent((e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, searchTerm: e.target.value, pageIndex: 0 }));
  });

  useEffect(() => {
    // If there are no sites in the list, we need to go back one page
    if (!isLoading && !isFetching && sites.length === 0 && pageIndex > 0) {
      setState((prevState) => ({ ...prevState, pageIndex: prevState.pageIndex - 1 }));
    }
  }, [isLoading, isFetching, sites.length, pageIndex]);

  return (
    <Box
      className="dashboard"
      px={isMobileViewport ? 0 : 10}
      pb={isMobileViewport ? BOTTOM_NAV_HEIGHT : 1}
      minHeight={isMobileViewport ? `calc(100vh - ${TOP_NAV_SPACING_AFFORDANCE})` : `initial`}
    >
      <Flex
        bg={isMobileViewport ? 'white' : 'initial'}
        w={'100%'}
        maxW={1000}
        cursor={'pointer'}
        borderTop={isMobileViewport ? '1px solid' : 'initial'}
        borderColor={'#F0F0F0'}
        _dark={{ bg: isMobileViewport ? 'gray.900' : 'initial', borderColor: '#606060' }}
      >
        {Object.entries(BATTERY_INTEREST_STATUS_CONFIG).map(([key, value]) => {
          const status = key as BatteryUpsellInvitationStatus | 'all';
          const isSelected = key === invitationStatus;
          return (
            <Box
              key={key}
              py={2.5}
              borderBottom="3px solid"
              fontWeight={500}
              borderColor={isSelected ? 'dusk100.500' : 'gray.200'}
              flexGrow={1}
              fontSize={'15px'}
              textAlign={'center'}
              onClick={() => handleBateryInterestStatusChange(status)}
              data-testid={`${status}-status-selector`}
              aria-selected={isSelected}
              roundedTop={10}
              _dark={{ borderColor: isSelected ? 'whiteAlpha.900' : 'whiteAlpha.200' }}
              _hover={{ bg: isMobileViewport ? 'transparent' : buttonHoverColor }}
            >
              {isMobileViewport ? value.mobileLabel : value.label}{' '}
              {!isMobileViewport && (
                <Box as="span" w={150}>
                  ({isFetchingTotalSites ? <Spinner color={'primaryBranding.500'} size={'xs'} /> : total[status]})
                </Box>
              )}
            </Box>
          );
        })}
      </Flex>
      <Box bg={backgroundColor} shadow="sm" borderRadius={8} overflow="hidden" pb={2} mb={20}>
        <Box padding="3">
          <InputGroup maxWidth="500">
            <InputLeftElement pointerEvents="none">
              {searchTerm && isFetching ? (
                <Box>
                  <Spinner size={'xs'} />
                </Box>
              ) : (
                <SearchIcon color={iconColor} />
              )}
            </InputLeftElement>
            <Input
              data-testid="upsell-site-search"
              name={'Search'}
              ref={searchInputRef}
              onChange={handleSearch}
              placeholder={'Search'}
              shadow="xs"
            />
          </InputGroup>
        </Box>

        {isMobileViewport && !!sites.length && (
          <Box className={switchClassName} maxW="100vw" overflowX={'auto'} data-testid="select-column-toggle">
            <MultiToggleSwitch
              mb={2}
              w={'100%'}
              fontWeight={500}
              fontSize={'14px'}
              switchOptions={MOBILE_COLUMN_DISPLAY_TYPES}
              onChange={(newValue) => setSelectedColumnDisplayType(newValue)}
              value={selectedColumnDisplayType}
              customClassName="container-padded"
            />
          </Box>
        )}

        <Box data-testid={`${invitationStatus}-status-table`}>
          <Table
            totalPageCount={totalPages}
            isLoaded={!isLoading}
            columns={columns ?? []}
            enablePagination={false}
            isRemotePagination
            data={sites}
            onFetchData={fetchSitesData}
            searchTerm={searchTerm}
            isFetchMoreDataLoaded={!isFetching}
            enableSorting
            defaultPageNumber={pageIndex}
            defaultPageSize={pageSize}
            emptyTableStatusConfig={{
              header: getTableHeader(invitationStatus),
              subHeader: getTableSubHeader(invitationStatus),
              minHeight: '40vh',
            }}
            loadingComponent={
              <Box as="tbody">
                {Array.from(Array(pageSize).keys()).map((key) => (
                  <tr key={key}>
                    <td colSpan={columns.length}>
                      <Skeleton height={isMobileViewport ? 62 : 51} mt={'1px'} key={key} />
                    </td>
                  </tr>
                ))}
              </Box>
            }
          />
          <TablePagination
            isLoaded={!isFetching}
            canNextPage={pageIndex < totalPages - 1}
            canPreviousPage={pageIndex > 0}
            gotoPage={(pageIndex) => setState((prevState) => ({ ...prevState, pageIndex }))}
            nextPage={() => setState((prevState) => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }))}
            pageCount={totalPages}
            pageIndex={pageIndex}
            pageOptions={[]}
            pageSize={pageSize}
            previousPage={() => setState((prevState) => ({ ...prevState, pageIndex: prevState.pageIndex - 1 }))}
            setPageSize={(pageSize) => setState((prevState) => ({ ...prevState, pageSize }))}
          />
        </Box>
      </Box>
    </Box>
  );
}
