import '../../../index.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Icon } from '@chakra-ui/icon';
import { Box, Center, useColorModeValue } from '@chakra-ui/react';
import { BsArrowClockwise } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';

import { DIRECTION, isTreeScrollable } from './is-scrollable';

interface PullToRefreshProps {
  children: JSX.Element;
  pullDownThreshold?: number;
  maxPullDownDistance?: number;
  resistance?: number;
}

/**
 * `PullToRefresh` is a component that attaches touch listeners to native
 * devices and performs window reload on pull down to specified threshold.
 *
 * @prop `children` - children wrapped in the component
 * @prop `pullDownThreshold` - pull distance in px to trigger reload
 * @prop `maxPullDownDistance` - max pull down distance in px
 * @prop `resistance` - manipulates pull down resistance
 */
export const PullToRefresh = ({
  children,
  pullDownThreshold = 180,
  maxPullDownDistance = 210,
  resistance = 1.8,
}: PullToRefreshProps) => {
  const childRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const pullDownContainerRef = useRef<HTMLDivElement>(null);
  const pullDownIconContainerRef = useRef<HTMLDivElement>(null);
  const pullDownIconRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef<boolean>(false);
  const startY = useRef<number>(0);
  const currentY = useRef<number>(0);
  const isNativeDevice = Capacitor.isNativePlatform();
  const [preventPullToRefresh, setPreventPullToRefresh] = useState(false);
  const { iconColor, iconBackgroundColor, shadowColor } = useColorModeValue(
    { iconColor: 'gray.800', iconBackgroundColor: 'white', shadowColor: 'rgba(100, 100, 111, 0.2)' },
    { iconColor: 'white', iconBackgroundColor: 'gray.800', shadowColor: 'rgba(199, 199, 199, 0.2)' }
  );

  const handlePreventPullToRefresh = useCallback((value: boolean) => {
    setPreventPullToRefresh(value);
  }, []);

  const initContainer = (): void => {
    requestAnimationFrame(() => {
      parentRef.current!.style.height = 'auto';
      parentRef.current!.style.overflowY = 'auto';
      pullDownContainerRef.current!.style.top = '-40px';
      pullDownIconContainerRef.current!.style.transform = 'rotate(0deg)';
    });
  };

  type SendHapticFeedback = {
    (): void;
    fired?: boolean;
  };

  const sendHapticFeedback: SendHapticFeedback = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
    sendHapticFeedback.fired = true;
  };

  const onTouchStart = (e: TouchEvent): void => {
    if (preventPullToRefresh) return;
    if (isDragging.current) isDragging.current = false;
    // if at the top of the page cancel
    if (e.type === 'touchstart' && isTreeScrollable(e.target as HTMLElement, DIRECTION.UP)) {
      return;
    }
    // Top is invisible so cancel
    if (childRef.current!.getBoundingClientRect().top < 0) return;

    startY.current = e.touches[0].pageY;
    currentY.current = startY.current;
    isDragging.current = true;
  };

  const onTouchMove = (e: TouchEvent): void => {
    if (!isDragging.current || preventPullToRefresh) return;

    // if scrolling down cancel
    if (currentY.current - startY.current < 0) {
      if (isDragging.current) isDragging.current = false;
      return;
    }

    currentY.current = e.touches[0].pageY;

    const yDistanceMoved = Math.min((currentY.current - startY.current) / resistance, maxPullDownDistance);

    // Send an impact haptic when pull down threshold has been met
    if (currentY.current - startY.current > pullDownThreshold && !sendHapticFeedback.fired) sendHapticFeedback();

    // maxPullDownDistance rreached, stop the animation
    if (yDistanceMoved >= maxPullDownDistance || yDistanceMoved < 0) return;

    // stop scroll behaviour on child through when the user is pulling down
    parentRef.current!.style.height = '100vh';
    parentRef.current!.style.overflowY = 'hidden';

    pullDownContainerRef.current!.style.top = `${yDistanceMoved - 40}px`;
    pullDownIconContainerRef.current!.style.transform = `rotate(${5 * yDistanceMoved}deg)`;
    pullDownIconRef.current!.style.opacity = `${yDistanceMoved / maxPullDownDistance + 0.2}`;
  };

  const onEnd = (): void => {
    sendHapticFeedback.fired = false;
    if (currentY.current - startY.current > pullDownThreshold && !preventPullToRefresh) {
      pullDownIconContainerRef.current!.style.animation = 'loading-animation 0.3s linear infinite';
      setTimeout(() => window.location.reload(), 500);
    } else {
      pullDownContainerRef.current!.style.transition = 'top 300ms';
      setTimeout(() => (pullDownContainerRef.current!.style.transition = 'none'), 350);
      initContainer();
    }
    if (isDragging.current) isDragging.current = false;
  };

  const removeEventListeners = (childrenEl: HTMLDivElement) => {
    childrenEl.removeEventListener('touchstart', onTouchStart);
    childrenEl.removeEventListener('touchmove', onTouchMove);
    childrenEl.removeEventListener('touchend', onEnd);
  };

  useEffect(() => {
    if (!isNativeDevice || !childRef.current) return;
    const childrenEl = childRef.current;

    if (!preventPullToRefresh) {
      childrenEl.addEventListener('touchstart', onTouchStart, { passive: true });
      childrenEl.addEventListener('touchmove', onTouchMove, { passive: true });
      childrenEl.addEventListener('touchend', onEnd, { passive: true });
      initContainer();
    } else {
      removeEventListeners(childrenEl);
    }

    return () => removeEventListeners(childrenEl);

    //eslint-disable-next-line
  }, [preventPullToRefresh]);

  if (!isNativeDevice) return children;

  return (
    <Box className="ptr-container" ref={parentRef}>
      <Center position="absolute" top="-40px" w="100%" ref={pullDownContainerRef} zIndex={9999999}>
        <Center
          ref={pullDownIconContainerRef}
          w={7}
          h={7}
          rounded="100%"
          bg={iconBackgroundColor}
          mx="auto"
          boxShadow={`${shadowColor} 0px 0px 29px 0px`}
        >
          <Box ref={pullDownIconRef} lineHeight={1}>
            <Icon as={BsArrowClockwise} color={iconColor} w={5} h={5} />
          </Box>
        </Center>
      </Center>
      <Box ref={childRef}>{children}</Box>
      <ReRenderOnLocationChange onPreventPullToRefresh={handlePreventPullToRefresh} />
    </Box>
  );
};

/**
 * This component exists to handle rerenders of useLocation.
 * This ensures we don't rerender the whole app unless our condition changes.
 * @param param0 onPreventPullToRefresh - a function to change preventPTR state
 * @returns JSX fragment
 */
function ReRenderOnLocationChange({ onPreventPullToRefresh }: { onPreventPullToRefresh: (val: boolean) => void }) {
  // we can't use useNavigate here as it does not re-render on location changes
  const pullToRefresh = useLocation().pathname.includes('site');

  useEffect(() => {
    onPreventPullToRefresh(pullToRefresh);
  }, [pullToRefresh, onPreventPullToRefresh]);

  return <></>;
}
