import React from 'react';
import { Box, Center, Icon, IconButton, Skeleton, useDisclosure } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import { BiExpandAlt } from 'react-icons/bi';

import { useAppSelector } from '../../../../app/hooks';
import { FullScreenMeterChartView } from './FullScreenMeterChartView';
import { MeterConfigurationChartSettings } from './MeterConfigurationChartSettings';
import { selectMeterConfiguration } from './meterConfigurationSlice';
import { useMeterChartOptions } from './use-meter-chart-options';

export function MeterConfigurationChart() {
  const {
    meterChartConfig: { selectedInterval },
    circuitDisplayConfigs,
  } = useAppSelector(selectMeterConfiguration);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isLoading, isFetching, refetch, seriesData, chartOptions, latestCommunicatedTime } = useMeterChartOptions();

  const getChartContents = () => {
    // if it is loaded and there is no data, show message
    const hasSeriesData = seriesData.data.some(({ data }) => data.length);
    if (!isLoading && !hasSeriesData)
      return (
        <Center w="100%" height={200}>
          There is no data for this device on this day
        </Center>
      );

    // if no filters are applied, show message
    if (!isLoading && circuitDisplayConfigs.every(({ isHidden }) => isHidden))
      return (
        <Center w="100%" h={200}>
          Use filters to show circuits
        </Center>
      );

    return (
      <Skeleton isLoaded={!isLoading} minHeight={'300px'}>
        {/* Do not render when in fullscreen mode for optimisation purpose */}
        {!isOpen && (
          <Box margin="1rem 0" data-testid={`chart-${selectedInterval}`} minH={300} position={'relative'}>
            <Chart highcharts={Highcharts} options={chartOptions} />
            <IconButton
              onClick={onOpen}
              position="absolute"
              bottom={'44px'}
              right={2}
              icon={<Icon as={BiExpandAlt} w={5} h={5} fill="white" />}
              aria-label="View full screen"
              fontWeight={400}
              fontSize={14}
              h={8}
              w={8}
              minW={8}
              borderRadius={'full'}
              background={'#06060680'}
              _dark={{ background: '#5b5b5b' }}
              _hover={{ background: '#060606' }}
              transform={'rotate(90deg)'}
              data-testid="full-screen-chart-button"
            />
          </Box>
        )}
      </Skeleton>
    );
  };

  return (
    <>
      <MeterConfigurationChartSettings
        isFetching={isFetching}
        onRefetch={refetch}
        latestCommunicatedTime={latestCommunicatedTime}
      />

      {getChartContents()}

      {isOpen && <FullScreenMeterChartView {...{ isOpen, onClose }} />}
    </>
  );
}
