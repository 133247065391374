import React from 'react';
import { Flex, Grid, Text, useColorModeValue, useTheme } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { BOTTOM_NAV_HEIGHT } from '../../common/constants';
import { selectUser } from '../user/userSlice';
import { SiteNavLink, useSelectedIndexFromLinks } from './custom-nav-hooks';

interface Props {
  links: SiteNavLink[];
}

export function BottomNav({ links }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const [selectedIndex, setSelectedIndex] = useSelectedIndexFromLinks(links);
  const shouldShowBottomNav = !(pathname.includes('site') && !pathname.includes('upsells')) && user.isLoggedIn;
  const theme = useTheme();
  const { backgroundColor, iconColor, borderColor } = useColorModeValue(
    { backgroundColor: 'white', iconColor: theme.colors.dusk100[500], borderColor: 'backgroundGrey.500' },
    { backgroundColor: 'gray.900', iconColor: theme.colors.white, borderColor: 'gray.800' }
  );

  function handleClick(redirectRoute: string, i: number) {
    window.scrollTo({ top: 0 });
    setSelectedIndex(i);
    navigate(redirectRoute, { replace: true });
  }

  if (!shouldShowBottomNav) return null;

  return (
    <Grid
      data-testid="bottom-nav"
      zIndex={10}
      position="fixed"
      height={BOTTOM_NAV_HEIGHT}
      width="100%"
      bottom="0px"
      templateColumns={`repeat(${links.length}, 1fr)`}
      bg={backgroundColor}
      borderTop={'1px'}
      borderColor={borderColor}
    >
      {links.map((link, i) => {
        const formattedRouteName = link.routeName.replace('/', '').toLowerCase();
        const icon = selectedIndex === i ? link.activeIcon : link.icon;

        return (
          <Flex
            data-testid={`${formattedRouteName}-bottom-link-button`}
            direction={'column'}
            alignItems="center"
            justifyContent="flex-start"
            pt="7px"
            pb="10px"
            rounded={0}
            onClick={() => {
              handleClick(link.route, i);
            }}
            height="100%"
            key={link.routeName}
            aria-label={link.ariaLabel}
          >
            <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
              {React.cloneElement(icon, { w: '22px', h: '22px', size: '22px', color: iconColor })}
              <Text mt={1} fontSize={'10px'}>
                {link.routeName}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Grid>
  );
}
