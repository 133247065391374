import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { Appliance } from '../../../api/api-appliance';
import { RootState } from '../../../app/store';
import { GRID_CIRCUIT_TYPES, SOLAR_CIRCUIT_TYPES } from './meter-setup-helpers';

export type MeterSetupState = {
  // Mapped by serial number, used to interact with the WW API
  rawMeterData: Record<string, WattwatchersMeter>;
  lastActiveMeterIndex: number;
  appliances: Appliance[];
};

export const initialState: MeterSetupState = {
  rawMeterData: {},
  appliances: [],
  lastActiveMeterIndex: 0,
};

export const meterSetupSlice = createSlice({
  name: 'meterSetup',
  initialState,
  reducers: {
    setAppliances: (state, action: PayloadAction<Appliance[]>) => {
      return { ...state, appliances: action.payload };
    },
    // Add a meter for configuration, by ID. Initialises status maps for device tests.
    addMeter: (state, action: PayloadAction<WattwatchersMeter>) => {
      state.rawMeterData[action.payload.ww_device_id] = action.payload;
    },
    // Remove a meter for configuration, by ID
    removeMeter: (state, action: PayloadAction<string>) => {
      const serialNumber = action.payload;
      delete state.rawMeterData[serialNumber];
    },
    addMeters: (state, action: PayloadAction<WattwatchersMeter[]>) => {
      // Add multiple meters. Initialises status maps for device tests.
      const rawMetersMap = action.payload.reduce<Record<string, WattwatchersMeter>>((meterMap, rawMeter) => {
        meterMap[rawMeter.ww_device_id] = rawMeter;
        return meterMap;
      }, {});

      state.rawMeterData = { ...state.rawMeterData, ...rawMetersMap };
    },
    resetMeters: () => {
      return initialState;
    },
    /**
     * End status updates for old meter test setup
     */
    setLastActiveMeterIndex: (state, action: PayloadAction<number>) => {
      state.lastActiveMeterIndex = action.payload;
    },
  },
});

export const { addMeter, removeMeter, addMeters, resetMeters, setAppliances, setLastActiveMeterIndex } =
  meterSetupSlice.actions;

export const selectRawMeter = (serialNumber: string) => (state: RootState) =>
  state.meterSetup.rawMeterData?.[serialNumber] ?? null;

export const selectRawMeters = (state: RootState) => state.meterSetup.rawMeterData;

export const selectAllCircuits = createSelector(
  [(state: RootState) => state.meterSetup.rawMeterData],
  (rawMeterData) => {
    return Object.values(rawMeterData)
      .map((m) => m.circuits)
      .flat();
  }
);

export const selectLastActiveMeterIndex = (state: RootState) => state.meterSetup.lastActiveMeterIndex;

export const selectLastActiveMeterNumber = (state: RootState) => state.meterSetup.lastActiveMeterIndex + 1;

export const selectAppliances = (state: RootState) => state.meterSetup.appliances;

export const selectGridAppliance = createSelector([(state: RootState) => state.meterSetup.appliances], (appliances) => {
  return appliances.find((a) => GRID_CIRCUIT_TYPES.includes(a.appliance_type));
});

export const selectHybridAppliance = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.find((a) => a.appliance_type === 'hybrid_inverter');
  }
);

export const selectBackupCircuitAppliance = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.find((a) => a.appliance_type === 'backup_circuit');
  }
);

export const selectBatteryAppliance = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.find((a) => a.appliance_type === 'battery_storage');
  }
);

export const selectSolarAppliances = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.filter((a) => SOLAR_CIRCUIT_TYPES.includes(a.appliance_type));
  }
);

export const selectLoadApplianceById = (id: number) => {
  return createSelector([(state: RootState) => state.meterSetup.appliances], (appliances) => {
    return appliances.find((a) => a.appliance_id === id);
  });
};

export const { reducer: meterSetupReducer } = meterSetupSlice;
