import React, { useMemo, useState } from 'react';
import { Alert, AlertIcon, Box, CloseButton, Text } from '@chakra-ui/react';

import { CATCH_POWER_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { useSiteMeters } from './meterSetupApi';

export const CatchPowerMeterAlert = () => {
  const { siteMeters } = useSiteMeters();
  const { catchPowerMeters, stringifiedCatchPowerMeterIdentifiers } = useMemo(() => {
    const catchPowerMeters = siteMeters.filter(
      ({ meta_data: metaData }) => metaData.manufacturer_id === CATCH_POWER_MANUFACTURER_ID
    );
    return {
      catchPowerMeters,
      stringifiedCatchPowerMeterIdentifiers: catchPowerMeters
        .map(({ site_identifier: siteIdentifier }) => siteIdentifier)
        .join(', '),
    };
  }, [siteMeters]);
  const [showCatchPowerMeterAlert, setShowCatchPowerMeterAlert] = useState(!!catchPowerMeters.length);

  return showCatchPowerMeterAlert ? (
    <Alert status="info" borderRadius={4} mb={4} variant="left-accent" data-testid="catch-power-meter-alert">
      <AlertIcon />
      <Box>
        <Text fontWeight={700}>CATCH Power meter detected!</Text>
        <Text fontSize={'sm'} my={1}>
          Meter Identifier: {stringifiedCatchPowerMeterIdentifiers}
        </Text>
        <Text fontSize={'sm'} opacity={0.8}>
          This site uses a CATCH Power meter, which is not supported by the Clipsal Cortex Fleet app yet. Please
          download the CATCH Configurator app to configure this site's meter.
        </Text>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={() => setShowCatchPowerMeterAlert(false)}
        data-testid="catch-power-meter-alert-close-btn"
      />
    </Alert>
  ) : null;
};
