import React from 'react';
import { Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/react';

import { createCustomCellularIcon, createCustomWifiIcon } from 'clipsal-cortex-icons/src/custom-dynamic-icons';
import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { getMinuteDifferenceBetweenTimeStamps } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { WIFI_ONLY_METER_MODELS } from 'clipsal-cortex-utils/src/constants/common-constants';

const SIGNAL_LEVEL_TO_LABEL: Record<number, string> = {
  4: 'Excellent',
  3: 'Good',
  2: 'Low',
  1: 'Poor',
  0: 'Offline',
};

const SIGNAL_QUALITY_TO_SIGNAL_LEVEL: Record<number, number> = {
  [-1]: 0,
  0: 1,
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 3,
  6: 3,
  7: 3,
  8: 4,
  9: 4,
};

const WIFI_SIGNAL_RANGES = [-40, -55, -70];
const CELL_SIGNAL_RANGES: Record<string, number[]> = {
  '3G': [-89, -95, -101],
  '4G': [-65, -75, -95],
};

const getSignalLevel = (rangeArray: number[], signalQualityDbm: number): number => {
  for (let index = 0; index < rangeArray.length; index++) {
    const currentValue = rangeArray[index];
    const signalLevel = rangeArray.length - index;

    // if last index reached, it means lowest signal
    if (index === rangeArray.length - 1) return signalLevel;

    // if first index and signal quality greater than first value means best signal
    if (index === 0 && signalQualityDbm > currentValue) return signalLevel + 1;

    // otherwise check if its between next and current value
    const nextValue = rangeArray[index + 1];
    if (signalQualityDbm < currentValue && signalQualityDbm >= nextValue) return signalLevel;
  }
  return 0;
};

const signalQualityToSignalLevel = (meter: WattwatchersMeter, isWifi: boolean): number => {
  const { signalQualityDbm, mode, wifi, cellular, type } = meter.comms;

  // For now Gen 2 meters have extra properties to get signal quality
  // Once WW API releases the signal quality for Gen 1 meters, we can remove this condition
  if (type === 'cellular,wifi') {
    const signalQuality = isWifi ? wifi?.signalQuality : cellular?.signalQuality;
    return SIGNAL_QUALITY_TO_SIGNAL_LEVEL[signalQuality ?? -1];
  } else if ((!isWifi && !mode) || (signalQualityDbm !== 0 && !signalQualityDbm)) {
    return 0;
  } else {
    const rangeArray = isWifi ? WIFI_SIGNAL_RANGES : CELL_SIGNAL_RANGES[mode ?? '4G'];
    return getSignalLevel(rangeArray, signalQualityDbm);
  }
};

type SignalIconProps = {
  meter: WattwatchersMeter;
  isWifi: boolean;
  isOffline: boolean;
  hideText?: boolean;
};

const SignalIcon = ({ meter, hideText, isWifi, isOffline }: SignalIconProps) => {
  const signalLevel = isOffline ? 0 : signalQualityToSignalLevel(meter, isWifi);
  const signalQualityLabel = SIGNAL_LEVEL_TO_LABEL[signalLevel];
  const signalIconColor = useColorModeValue('black', 'white');
  const SignalIcon = isWifi
    ? createCustomWifiIcon(signalLevel, signalIconColor)
    : createCustomCellularIcon(signalLevel, signalIconColor);

  return (
    <>
      <SignalIcon w={6} h={6} mr={1} />
      {!hideText && !isOffline && (
        <Text
          fontWeight={600}
          color={signalIconColor}
          mr={1}
          data-testid={`meter-comms-icon-label-${isWifi ? 'wifi' : 'cellular'}`}
        >
          {`${signalQualityLabel}`}
        </Text>
      )}
    </>
  );
};

type CustomSignalIconProps = {
  meter: WattwatchersMeter;
  hideText?: boolean;
  hideCellular?: boolean;
};
export const CustomSignalIcon = ({ meter, hideText, hideCellular }: CustomSignalIconProps) => {
  const { comms, model, timezone } = meter;
  const { mode, type, wifi, lastHeardAt } = comms;
  const lastHeardInMinutes = getMinuteDifferenceBetweenTimeStamps(lastHeardAt, timezone);
  const isOffline = lastHeardInMinutes === null || lastHeardInMinutes > 5;
  const isCellular = !hideCellular && !WIFI_ONLY_METER_MODELS.includes(model);
  // Gen 1 meters have type as Wi-Fi instead of mode
  const isWifi = hideCellular || mode === 'wifi' || type === 'wifi' || (type === 'cellular,wifi' && wifi?.ssid);
  return (
    <>
      {isCellular && <SignalIcon meter={meter} hideText={hideText} isWifi={false} isOffline={isOffline} />}
      {isWifi && <SignalIcon meter={meter} hideText={hideText} isWifi={true} isOffline={isOffline} />}
      {isOffline && (
        <Text fontWeight={600} color={'red.600'} mr={1}>
          Offline
        </Text>
      )}
    </>
  );
};
