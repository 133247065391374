import React, { useEffect, useState } from 'react';
import { useInterval } from '@chakra-ui/hooks';
import { useBreakpointValue, useToast } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import {
  ChannelData,
  CTRating,
  Polarity,
  VoltageReference,
  WattwatchersMeter,
} from 'clipsal-cortex-types/src/api/api-ww-meter';

import { get, patch } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import { TOP_NAV_SPACING_AFFORDANCE } from '../../../common/constants';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { CustomSwitchValue } from './ct-configuration/ct-configuration-form-types';
import { addMeter, addMeters, selectAllCircuits, selectRawMeters } from './meterSetupSlice';

// @TODO: the more I consider it, the more I think we don't need this at all
//        and can just rely on the actual Redux values inside the form... Simpler
//        that way too because we won't have to store state in 2 separate places and we can
//        avoid all the annoying logic we've had to implement to synchronize them. Refactor later.
export type CircuitsState = {
  values: {
    circuitId: string;
    polarity: CustomSwitchValue<Polarity>;
    voltageReference: CustomSwitchValue<VoltageReference>;
    ctRating: CustomSwitchValue<CTRating>;
    name: string;
  }[];
  pendingMeterIds: string[];
};

// function overloading to ensure that the correct type is passed to the function
export type OnCircuitChange = {
  (circuitId: string, value: VoltageReference, type: 'voltageReference'): Promise<any>;
  (circuitId: string, value: Polarity, type: 'polarity'): Promise<any>;
  (circuitId: string, value: CTRating, type: 'ctRating'): Promise<any>;
  (circuitId: string, value: string, type: 'name'): Promise<any>;
};

export type MeterSetupPollingSubRouteProps = {
  onCircuitChange: OnCircuitChange;
  circuitState: CircuitsState;
};

/**
 * Establishes initial state values for circuit phasing and polarity states.
 *
 * @param circuits - Array of all circuits.
 */
function getInitialCircuitState(circuits: ChannelData[]): CircuitsState {
  return {
    values: circuits.map((circuit) => {
      return {
        circuitId: circuit.ww_circuit_id,
        polarity: {
          value: !!circuit?.pending?.polarity ? circuit.pending?.polarity : circuit.polarity,
          isPending: !!circuit?.pending?.polarity,
        },
        voltageReference: {
          value: !!circuit?.pending?.voltageReference ? circuit.pending?.voltageReference : circuit.voltage_reference,
          isPending: !!circuit?.pending?.voltageReference,
        },
        ctRating: {
          value: !!circuit?.pending?.ctRating ? circuit.pending?.ctRating : circuit.ct_rating,
          isPending: !!circuit?.pending?.ctRating,
        },
        name: circuit.circuit_name,
      };
    }),
    pendingMeterIds: [],
  };
}

/**
 * This component houses common cross-route meter polling functionality to avoid repeating this logic in several places.
 */
export function MeterSetupPollingWrapper() {
  const allCircuits = useSelector(selectAllCircuits);
  const [circuitState, setCircuitState] = useState<CircuitsState>(getInitialCircuitState(allCircuits));
  const rawMeters = useSelector(selectRawMeters);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  async function checkPendingValuesFromAPI() {
    // Fetch pending meters from API
    const meters = await Promise.all(
      Object.values(rawMeters).map((meter) =>
        get<WattwatchersMeter>(`/fleet/wattwatchers/installed_meters/${meter.installed_device_id}`)
      )
    );

    // A list of pending meter IDs, to be populated by meters which still have pending circuit data.
    const pendingMeterIds: string[] = [];

    // Convert updated circuits to a map for fast lookup
    const circuitsForPendingMeters = meters.reduce<Record<string, ChannelData>>((circuitsMap, meter) => {
      const meterInstalledDeviceId = meter.installed_device_id as string;
      meter.circuits.forEach((circuit) => {
        if (circuit.pending && !pendingMeterIds.includes(meterInstalledDeviceId)) {
          pendingMeterIds.push(meterInstalledDeviceId);
        }

        circuitsMap[circuit.ww_circuit_id] = circuit;
      });

      return circuitsMap;
    }, {});

    setCircuitState({
      values: circuitState.values.map((switchValue) => {
        const circuitForSwitchValue = circuitsForPendingMeters[switchValue.circuitId];

        // Update switch value if we find a match
        if (circuitForSwitchValue) {
          const isPolarityPending = !!circuitForSwitchValue.pending?.polarity;
          const polarityValue = isPolarityPending
            ? (circuitForSwitchValue.pending?.polarity as Polarity)
            : circuitForSwitchValue.polarity;

          const isVoltagePending = !!circuitForSwitchValue.pending?.voltageReference;
          const voltageValue = isVoltagePending
            ? (circuitForSwitchValue.pending?.voltageReference as VoltageReference)
            : circuitForSwitchValue.voltage_reference;

          const isCtRatingPending = !!circuitForSwitchValue.pending?.ctRating;
          const ctRatingValue = isCtRatingPending
            ? (circuitForSwitchValue.pending?.ctRating as CTRating)
            : circuitForSwitchValue.ct_rating;

          return {
            ...switchValue,
            polarity: {
              value: polarityValue,
              isPending: isPolarityPending,
            },
            voltageReference: {
              value: voltageValue,
              isPending: isVoltagePending,
            },
            ctRating: {
              value: ctRatingValue,
              isPending: isCtRatingPending,
            },
          };
        }

        // Otherwise, just return the old switch value
        return switchValue;
      }),
      pendingMeterIds,
    });

    // Update meters in Redux
    dispatch(addMeters(meters));
  }

  useInterval(checkPendingValuesFromAPI, 5000);

  useEffect(() => {
    async function fetchInitialMeterStatuses() {
      const meters = Object.values(rawMeters);

      // Convert updated circuits to a map for fast lookup
      const pendingMeterIds = meters.reduce<string[]>((accPendingMeterIds, meter) => {
        const meterInstalledDeviceId = meter.installed_device_id as string;
        meter.circuits.forEach((circuit) => {
          if (circuit.pending && !accPendingMeterIds.includes(meterInstalledDeviceId)) {
            accPendingMeterIds.push(meterInstalledDeviceId);
          }
        });

        return accPendingMeterIds;
      }, []);

      setCircuitState({
        ...circuitState,
        pendingMeterIds,
      });
    }

    fetchInitialMeterStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleUpdateCircuit(
    circuitId: string,
    value: VoltageReference | Polarity | CTRating | string,
    type: 'voltageReference' | 'polarity' | 'ctRating' | 'name'
  ) {
    const wwMeterId = circuitId.split('_')[0];
    const clipsalMeterId = rawMeters[wwMeterId].installed_device_id;

    if (!clipsalMeterId) throw new Error(`Unable to find meter in store with ID ${wwMeterId}`);

    // Update state to display this circuit as pending
    setCircuitState((prevState) => {
      const pendingMeterIds = prevState.pendingMeterIds.includes(clipsalMeterId)
        ? prevState.pendingMeterIds
        : [...prevState.pendingMeterIds, clipsalMeterId];

      return {
        values: circuitState.values.map((circuitValues) => {
          if (circuitValues.circuitId === circuitId) {
            return {
              ...circuitValues,
              [type]: {
                isPending: true,
                value,
              },
            };
          }

          return circuitValues;
        }),
        pendingMeterIds,
      };
    });

    const circuitPropertyToUpdate = {
      voltageReference: 'voltage_reference',
      polarity: 'polarity',
      ctRating: 'ct_rating',
      name: 'circuit_name',
    }[type];

    // Update this meter in redux
    const meter = cloneDeep(rawMeters[wwMeterId]);
    meter.circuits = meter.circuits.map((c) => {
      if (c.ww_circuit_id === circuitId) {
        return {
          ...c,
          [circuitPropertyToUpdate]: value,
          pending: {
            [type]: value,
          },
        };
      }

      return c;
    });

    dispatch(addMeter(meter));

    try {
      // Update meter on the API
      await patch(`/fleet/wattwatchers/installed_meters/${meter.installed_device_id}`, meter);

      let title = 'Voltage Reference';
      let description = 'Please wait. This can take up to 60sec to update.';

      if (type === 'polarity') title = 'Polarity';
      if (type === 'ctRating') title = 'CT Rating';
      if (type === 'name') {
        title = 'Circuit Name';
        description = '';
      }

      toast.closeAll();
      toast({
        title: `${title} updated`,
        description,
        status: 'info',
        isClosable: true,
      });
    } catch (e) {
      console.error(e);

      toast({
        title: 'Something went wrong saving circuit data',
        description: 'Please try again. If this continues, please contact support.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  return (
    <WizardSubRouteCardWrapper
      containerProps={{ pt: `calc(env(safe-area-inset-top) + ${TOP_NAV_SPACING_AFFORDANCE})`, mt: 1 }}
      p={[0]}
      pt={isMobileViewport ? 2 : 4}
    >
      <Outlet context={{ onCircuitChange: handleUpdateCircuit, circuitState }} />
    </WizardSubRouteCardWrapper>
  );
}
