import React, { useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import { MdSort } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { InvitationStatus } from '../../../../api/api-user';
import { debounceEvent } from '../../../../utils/component-helpers';
import { selectUser } from '../../userSlice';
import { USER_ROLE_MAP, USER_ROLES } from '../account-helpers';
import { EditUserModal } from './EditUserModal';
import {
  CONFIRMED_USERS_ONLY_FILTERS,
  DEFAULT_STATE_VALUES,
  FILTER_TO_COLUMN_MAP,
  FILTER_TYPES,
  FILTERS_WITH_SORT_ORDERS,
  PAGE_SIZES,
  SearchUserQueryParams,
  SORT_ORDERS,
} from './manage-users-helpers';
import { useGetUsers } from './manageUsersApi';
import { UserList } from './UserList';

const COMMON_MENU_BUTTON_PROPS = {
  as: Button,
  variant: 'outline',
  fontWeight: 'normal',
  ml: [0, 2],
  mt: [2, 0],
  rightIcon: <ChevronDownIcon />,
  leftIcon: <Icon as={MdSort} />,
  textAlign: 'left',
} as ButtonProps;

type UserListWithFiltersProps = {
  status: InvitationStatus;
};

export function UserListWithFilters({ status }: UserListWithFiltersProps) {
  const [{ isEditUserModalOpen, pageIndex, pageSize, filterType, filterRole, sortOrder, searchTerm }, setState] =
    useState({
      pageSize: PAGE_SIZES[0],
      isEditUserModalOpen: false,
      ...DEFAULT_STATE_VALUES,
    });
  const user = useSelector(selectUser);
  const isSuperAdminUser = user.role === 'SUPER_ADMIN';
  const isConfirmedUsersTab = status === 'CONFIRMED';
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const queryParams = useMemo<SearchUserQueryParams>(() => {
    // pagination params are required
    const params: SearchUserQueryParams = { limit: pageSize, offset: pageSize * pageIndex };

    // role params
    if (filterType === 'Role') params.role__in = filterRole;

    // search params
    if (searchTerm) params.search_term = searchTerm;

    // only add orderby to certain filters - use orderby based on table columns
    const isSortableFitler = FILTERS_WITH_SORT_ORDERS.includes(filterType);
    const orderByColumn = isSortableFitler ? FILTER_TO_COLUMN_MAP[filterType] : '';
    const orderByDirection = sortOrder === 'Ascending' ? '' : '-';
    if (orderByColumn) params.ordering = `${orderByDirection}${orderByColumn}`;

    return params;
  }, [pageIndex, pageSize, searchTerm, filterType, filterRole, sortOrder]);

  const { users, isLoading, isFetching, totalPages, dataCount } = useGetUsers(queryParams, status, pageSize);

  const debouncedUpdateSearchTerm = debounceEvent((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm, pageIndex: 0 }));
  }, 500);

  return (
    <>
      <Flex py={2} px={2} flexDirection={['column', 'row']}>
        <InputGroup size="md">
          <InputLeftElement>
            {isFetching && !isLoading ? <Spinner size="sm" /> : <SearchIcon w={4} h={4} />}
          </InputLeftElement>
          <Input
            data-testid="search-email-name-input"
            placeholder="Search email or name..."
            ref={searchInputRef}
            onChange={(e) => debouncedUpdateSearchTerm(e.target.value || '')}
          />
        </InputGroup>
        {filterType === 'Role' && (
          <Menu isLazy>
            <MenuButton {...COMMON_MENU_BUTTON_PROPS} minW={180} data-testid="filter-select-role">
              {USER_ROLE_MAP[filterRole]}
            </MenuButton>
            <MenuList minW={180}>
              {USER_ROLES.map((role, index) => {
                if (!isSuperAdminUser && role === 'SUPER_ADMIN') return null;
                return (
                  <MenuItem
                    data-testid={`filter-${role.toLowerCase()}-role`}
                    key={index}
                    bg={filterRole === role ? 'primaryBranding.100' : undefined}
                    _dark={{ bg: filterRole === role ? 'primaryBranding.600' : undefined }}
                    onClick={() => {
                      setState((prevState) => ({ ...prevState, filterRole: role, pageIndex: 0 }));
                    }}
                  >
                    {USER_ROLE_MAP[role]}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}
        {FILTERS_WITH_SORT_ORDERS.includes(filterType) && (
          <Menu isLazy>
            <MenuButton {...COMMON_MENU_BUTTON_PROPS} minW={160} data-testid="filter-select-order">
              {sortOrder}
            </MenuButton>
            <MenuList minW={160}>
              {SORT_ORDERS.map((order, index) => (
                <MenuItem
                  data-testid={`filter-${order.toLowerCase()}-order`}
                  key={index}
                  bg={sortOrder === order ? 'primaryBranding.100' : undefined}
                  _dark={{ bg: sortOrder === order ? 'primaryBranding.600' : undefined }}
                  onClick={() => {
                    setState((prevState) => ({ ...prevState, sortOrder: order, pageIndex: 0 }));
                  }}
                >
                  {order}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
        <Menu isLazy>
          <MenuButton {...COMMON_MENU_BUTTON_PROPS} minW={140} data-testid="filter-select-type">
            {filterType}
          </MenuButton>
          <MenuList minW={140}>
            {FILTER_TYPES.map((type, index) => {
              // Some filters like `Name` is only available for confirmed users
              // The reason being that unconfirmed users don't have a name yet
              // as we require only email for sending invitation
              if (!isConfirmedUsersTab && CONFIRMED_USERS_ONLY_FILTERS.includes(type)) return null;

              // Format the filter type to be more readable
              let formattedFilterType: string = type;
              if (type === 'Date') formattedFilterType = `${isConfirmedUsersTab ? 'Joined' : 'Invited'} ${type}`;

              return (
                <MenuItem
                  data-testid={`filter-${type.toLowerCase()}-type`}
                  key={index}
                  bg={filterType === type ? 'primaryBranding.100' : undefined}
                  _dark={{ bg: filterType === type ? 'primaryBranding.600' : undefined }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      filterType: type,
                      sortOrder: 'Ascending',
                      pageIndex: 0,
                    }));
                  }}
                >
                  {formattedFilterType}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>
      <EditUserModal
        {...{
          isOpen: isEditUserModalOpen,
          onClose: () => setState((prevState) => ({ ...prevState, isEditUserModalOpen: false })),
          onOpen: () => setState((prevState) => ({ ...prevState, isEditUserModalOpen: true })),
        }}
      />
      {!isLoading ? (
        <UserList
          {...{
            users,
            isLoaded: !isLoading,
            isFetching,
            pageIndex,
            totalPages,
            pageSize,
            status,
            searchTerm,
            onPageIndexChange: (pageIndex: number) => setState((prevState) => ({ ...prevState, pageIndex })),
            onPageSizeChange: (pageSize: number) => {
              // for better UX, update total pages estimating new page count
              const estimatedTotalPages = Math.ceil(dataCount / pageSize);

              // if new page size causes overflow,
              // set to max estimated page index
              const isPageIndexOverflow = pageIndex > estimatedTotalPages - 1;
              let newPageIndex = pageIndex;
              if (isPageIndexOverflow) newPageIndex = estimatedTotalPages - 1;

              setState((prevState) => ({
                ...prevState,
                pageSize,
                pageIndex: newPageIndex,
                totalPages: estimatedTotalPages,
              }));
            },
          }}
        />
      ) : (
        <Box px={4}>
          {Array.from(Array(pageSize).keys()).map((key) => (
            <Skeleton height={62} my={5} borderRadius={4} key={key} />
          ))}
        </Box>
      )}
    </>
  );
}
