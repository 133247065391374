import React, { forwardRef } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { IconButton, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';

type Props = {
  isVisible: boolean;
  onToggleVisibility: (newValue: boolean) => void;
  inputDataTestID?: string;
  buttonDataTestID?: string;
  inputProps: InputProps;
};

export const ViewTogglePasswordInput = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {
    inputProps,
    isVisible,
    onToggleVisibility,
    inputDataTestID = 'password',
    buttonDataTestID = 'toggle-password-visibility',
  } = props;

  return (
    <InputGroup>
      <Input
        ref={ref}
        variant={'filled'}
        type={isVisible ? 'text' : 'password'}
        data-private
        data-testid={inputDataTestID}
        {...inputProps}
      />
      <InputRightElement data-testid={buttonDataTestID}>
        <IconButton
          aria-label="Toggle password visibility"
          variant={'ghost'}
          icon={
            isVisible ? (
              <ViewOffIcon w="22px" h="22px" onClick={() => onToggleVisibility(!isVisible)} />
            ) : (
              <ViewIcon w="22px" h="22px" onClick={() => onToggleVisibility(!isVisible)} />
            )
          }
        />
      </InputRightElement>
    </InputGroup>
  );
});

ViewTogglePasswordInput.displayName = 'ViewTogglePasswordInput';
