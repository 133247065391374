import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSupportDrawerContext } from '../../context/context-exports';
import { useNavigationState } from '../../context/NavigationProvider';
import { ConfirmGoBackDialog } from '../../features/site/Site';
import { TOP_NAV_HEIGHT } from '../constants';
import { AlertsNotificationIconButton } from './AlertsNotificationIconButton';

interface Props {
  title: string;
  backURL?: string;
  onGoBack?: () => void;
  icon?: React.ReactElement;
  children?: JSX.Element;
}

export function MobileTopNav({
  title,
  onGoBack,
  backURL = '/',
  children,
  icon = <ChevronLeftIcon w={6} h={6} />,
}: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setNavigationState } = useNavigationState();
  const shouldShowBackButton = backURL || !!onGoBack;
  const isOnSiteRoute = pathname.includes('site') && !pathname.includes('upsells');
  const isOnDashboard = pathname.includes('/dashboard');
  const isOnFleetDashboard = pathname.includes('fleet_dashboard');
  const isOnMeterSetupSubRoute = /\/meter_setup\/configure\//.test(pathname);
  const showNotificationIcon = isOnDashboard || isOnFleetDashboard;
  const showHelpIcon = isOnSiteRoute || isOnDashboard;
  const { onOpenSupportDrawer } = useSupportDrawerContext();
  const backgroundColor = useColorModeValue('white', 'gray.900');

  function handleGoBack() {
    if (onGoBack) {
      setNavigationState({ direction: 'backward' });
      onGoBack();
    } else {
      if (backURL === '/logout') {
        if (window.confirm('Are you sure you want to log out?')) {
          setNavigationState({ direction: 'backward' });
          navigate(backURL);
        }
      } else {
        setNavigationState({ direction: 'backward' });
        navigate(backURL);
      }
    }
  }

  return (
    <>
      <Box
        width={'100vw'}
        background={backgroundColor}
        zIndex={10}
        position={'fixed'}
        height={'env(safe-area-inset-top)'}
        top={'0px'}
        left={0}
      />
      <Flex
        width={'100vw'}
        background={backgroundColor}
        zIndex={10}
        left={0}
        position={'fixed'}
        height={TOP_NAV_HEIGHT + 'px'}
        top={'env(safe-area-inset-top)'}
        data-testid="mobile-top-nav"
      >
        <Flex width={'100vw'} alignItems={'center'} justify="center">
          {shouldShowBackButton && (!isOnSiteRoute || isOnMeterSetupSubRoute) && (
            <Box data-testid={'back-btn'} ml={4} left={0} position={'absolute'} onClick={handleGoBack} as={'button'}>
              {icon}
            </Box>
          )}
          {shouldShowBackButton && isOnSiteRoute && !isOnMeterSetupSubRoute && (
            <Box ml={[2, 4]} position={'absolute'} left={0}>
              <ConfirmGoBackDialog handleGoBack={handleGoBack} />
            </Box>
          )}

          {showNotificationIcon && (
            <Box left={4} position={'absolute'}>
              <AlertsNotificationIconButton />
            </Box>
          )}

          {showHelpIcon && (
            <Button
              variant="link"
              color="customLinkBlue.500"
              right={4}
              position={'absolute'}
              data-testid="help-button"
              onClick={onOpenSupportDrawer}
            >
              Help
            </Button>
          )}

          <Flex my={'12px'} height={'24px'} align={'center'}>
            <Heading mx={'auto'} size={'md'} maxW="65vw" noOfLines={1}>
              {title}
            </Heading>
            {children}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
