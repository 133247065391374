import React, { useMemo } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';

import { Appliance } from '../../../../api/api-appliance';
import { Assignment } from '../../../../api/api-assignments';
import { selectAssignments } from '../../system-details/systemDetailsSlice';
import { NON_LOAD_CIRCUIT_TYPES } from '../meter-setup-helpers';
import { selectRawMeters } from '../meterSetupSlice';
import { NOT_SET_ASSIGNMENT, NOT_SET_CATEGORY_ID, NOT_SET_CATEGORY_LABEL } from './form-mapping-helpers';
import { SwitchScheduleConfiguration } from './scheduler/SwitchScheduleConfiguration';
import { CommonFieldListProps } from './switch-config-form-types';
import { useSwitchAppliance } from './useSwitchAppliance';

export const SwitchConfiguration = (props: CommonFieldListProps) => {
  const { control } = props;
  const { fields } = useFieldArray({
    name: `meters` as const,
    control,
  });

  return (
    <>
      {fields.map(({ meterId, meterName }, index) => {
        return (
          <AccordionItem key={`${meterId}-${index}`} px={0}>
            <AccordionButton w="100%" pt={4}>
              <Flex wrap={'wrap'} align="center" rowGap={2}>
                {meterName && (
                  <Heading size="sm" mr={2}>
                    {meterName}
                  </Heading>
                )}
                <Badge
                  fontSize={14}
                  h="fit-content"
                  variant={'outline'}
                  rounded={20}
                  px={2}
                  data-testid={`meter-${index}-id`}
                >
                  {meterId}
                </Badge>
              </Flex>
              <AccordionIcon ml="auto" />
            </AccordionButton>
            <AccordionPanel p={0} px={2}>
              <SwitchList {...{ ...props, meterIndex: index, meterId }} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </>
  );
};

function SwitchList(props: CommonFieldListProps & { meterIndex: number; meterId: string }) {
  const { control, meterIndex, meterId } = props;
  const { fields } = useFieldArray({
    name: `meters.${meterIndex}.switches` as const,
    control,
  });

  return (
    <>
      {fields.map((_, index) => {
        return <SwitchApplianceConfiguration key={index} {...{ ...props, switchIndex: index, meterId }} />;
      })}
    </>
  );
}

const SWITCH_CONTRACTOR_TYPES = [
  { label: 'Nominally Open', value: 'NO' },
  { label: 'Nominally Closed', value: 'NC' },
];

function SwitchApplianceConfiguration(
  props: CommonFieldListProps & { meterIndex: number; switchIndex: number; meterId: string }
) {
  const { control, errors, register, setValue, meterIndex, meterId, switchIndex, getValues } = props;
  const appliances = useSwitchAppliance();
  const assignments = useSelector(selectAssignments);
  const switches = useWatch({ name: `meters.${meterIndex}.switches`, control });
  const rawMeters = useSelector(selectRawMeters);
  const selectedSwitchCategoryId = switches[switchIndex].categoryId;
  const selectedSwitchCircuitId = switches[switchIndex].circuitId;
  const switchCustomLabel = switches[switchIndex].label;
  const isConnected = switches[switchIndex].active;

  const applianceList = useMemo(() => {
    const applianceDropdownList = appliances
      .filter(({ appliance_type: applianceType, control_device_id: controlDeviceId, circuits }) => {
        const isNonLoadCircuitType = NON_LOAD_CIRCUIT_TYPES.includes(applianceType);
        const isAssigned = assignments.find(({ assignment }) => assignment === applianceType);
        // Check if the circuit is controlled load
        // If the circuit is controlled load, it should not be included in the list
        const isControlledLoad = rawMeters[meterId]?.circuits.some(
          ({ clipsal_circuit_id: clipsalCircuitId, controlled_load_yn: controlledLoadYN }) => {
            // ensure the circuit is in the list of appliance circuits
            return circuits.includes(clipsalCircuitId) && controlledLoadYN === 'Y';
          }
        );
        const isAssociatedWithAnotherDevice = controlDeviceId != null && controlDeviceId !== switches[switchIndex].id;

        return !isControlledLoad && !isNonLoadCircuitType && !isAssociatedWithAnotherDevice && isAssigned;
      })
      .map(({ appliance_name: applianceName, appliance_type: applianceType, circuits }) => {
        const {
          id: assignmentId,
          assignment,
          display_name: displayName,
        } = assignments.find(({ assignment }) => assignment === applianceType) as Assignment;

        const circuitId = circuits[0] || null;
        const label = applianceName || displayName;
        return { assignmentId, label, value: assignment, circuitId: circuitId };
      });

    // Always add not set as an option
    const hasNotSetOption = applianceDropdownList.some(({ assignmentId }) => assignmentId === NOT_SET_CATEGORY_ID);
    if (!hasNotSetOption)
      applianceDropdownList.push({
        assignmentId: NOT_SET_CATEGORY_ID,
        label: NOT_SET_CATEGORY_LABEL,
        value: NOT_SET_ASSIGNMENT,
        circuitId: null,
      });

    return applianceDropdownList;
  }, [appliances, switches, assignments, rawMeters]);

  const { selectedAssignment, reservedCircuitIds } = useMemo(() => {
    const { display_name: displayName, assignment } = assignments.find(
      ({ id }) => selectedSwitchCategoryId === id
    ) as Assignment;

    const selectedAppliance = appliances.find((a) => a.circuits[0] === selectedSwitchCircuitId) as Appliance;

    const selectedAssignment = {
      label: selectedAppliance?.appliance_name || displayName,
      value: assignment,
      icon: CIRCUIT_TYPE_TO_ICON[assignment],
    };

    const reservedCircuitIds = switches.filter(({ circuitId }) => !!circuitId).map(({ circuitId }) => circuitId);

    return { selectedAssignment, reservedCircuitIds };
  }, [selectedSwitchCategoryId, switches, appliances, assignments]);

  const SelectedAssignmentIcon = selectedAssignment.icon;

  return (
    <Box my={3} borderRadius={4} border="1px solid" borderColor="gray.100">
      <Flex bg="gray.100" _dark={{ bg: 'gray.700' }} p={2} borderRadius={4}>
        <Box
          data-testid={`meters-${meterIndex}-switches-${switchIndex}-header-title`}
          as="span"
          flex="1"
          textAlign="left"
          fontWeight={'500'}
        >
          Switch {switchIndex + 1} {isConnected ? `- ${switchCustomLabel}` : ''}
        </Box>
        <Flex align="center" ml={2}>
          <Text
            data-testid={`meters-${meterIndex}-switches-${switchIndex}-status-text`}
            color={isConnected ? 'primaryBranding.500' : 'gray.400'}
          >
            {isConnected ? 'Connected' : 'Not Connected'}
          </Text>

          <Switch
            data-testid={`meters-${meterIndex}-switches-${switchIndex}-status-toggle`}
            ml={2}
            colorScheme="primaryBranding"
            size="lg"
            isChecked={isConnected}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setValue(`meters.${meterIndex}.switches.${switchIndex}.active`, isChecked, { shouldDirty: true });

              // set to default values when turned off
              if (!isChecked) {
                setValue(`meters.${meterIndex}.switches.${switchIndex}.categoryLabel`, NOT_SET_CATEGORY_LABEL);
                setValue(`meters.${meterIndex}.switches.${switchIndex}.categoryId`, NOT_SET_CATEGORY_ID);
                setValue(`meters.${meterIndex}.switches.${switchIndex}.label`, NOT_SET_CATEGORY_LABEL);
                setValue(`meters.${meterIndex}.switches.${switchIndex}.circuitId`, null);
                setValue(`meters.${meterIndex}.switches.${switchIndex}.contactorType`, 'NO');
              }
            }}
          />
        </Flex>
      </Flex>

      {isConnected && (
        <Box p={3}>
          <Box mb={3}>
            <FormLabel>Appliance</FormLabel>
            <Menu matchWidth>
              <MenuButton
                data-testid={`meters-${meterIndex}-switches-${switchIndex}-appliance-association-select`}
                as={Button}
                rightIcon={<ChevronDownIcon w={5} h={5} />}
                width={'100%'}
                textAlign={'left'}
                variant={'outline'}
              >
                <Flex>
                  <SelectedAssignmentIcon w={5} h={5} />
                  <Text ml={2}>{selectedAssignment.label}</Text>
                </Flex>
              </MenuButton>
              <MenuList data-testid="switch-appliance-select">
                {applianceList.map(({ label, value, assignmentId, circuitId }, i) => {
                  const AssignmentIcon = CIRCUIT_TYPE_TO_ICON[value];
                  const selectedSwitchIndex = reservedCircuitIds.findIndex((id) => id === circuitId);
                  const isSwitchTaken = reservedCircuitIds.includes(circuitId);
                  const isSelected = circuitId === selectedSwitchCircuitId;
                  const isReserved = isSwitchTaken && !isSelected;
                  return (
                    <MenuItem
                      isDisabled={isReserved}
                      bg={isSelected ? 'customGreen.100' : undefined}
                      _dark={{ bg: isSelected ? 'customGreen.600' : undefined }}
                      key={`switch-categoryLabel-option-${i}`}
                      data-testid={`switch-appliance-association-option-${i}`}
                      onClick={() => {
                        // Auto populate switch details when selected
                        setValue(`meters.${meterIndex}.switches.${switchIndex}.categoryLabel`, value);
                        setValue(`meters.${meterIndex}.switches.${switchIndex}.categoryId`, assignmentId, {
                          shouldDirty: true,
                        });
                        setValue(`meters.${meterIndex}.switches.${switchIndex}.label`, label);
                        setValue(`meters.${meterIndex}.switches.${switchIndex}.circuitId`, circuitId);

                        // Ensure smart scheduling type is not enabled if a user selects a non-hot water load.
                        if (
                          getValues(`meters.${meterIndex}.switches.${switchIndex}.schedulingType`) === 'AUTO' &&
                          value !== 'load_hot_water'
                        ) {
                          setValue(`meters.${meterIndex}.switches.${switchIndex}.schedulingType`, 'TIMED');
                        }
                      }}
                    >
                      <AssignmentIcon w={5} h={5} />
                      <Text ml={2}>
                        {label} {isReserved ? `(Switch ${selectedSwitchIndex + 1})` : ''}
                      </Text>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <Text color="customSelectBlue.500" fontSize={14}>
              *Appliances with more than 1 circuit are not included. Controlled loads are not included.
            </Text>
          </Box>

          <FormControl isInvalid={!!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.label}>
            <FormLabel>Label</FormLabel>
            <Input
              data-testid={`meters-${meterIndex}-switches-${switchIndex}-label`}
              {...register(`meters.${meterIndex}.switches.${switchIndex}.label` as const)}
              type="text"
              placeholder={'Enter Label'}
            />
            <FormErrorMessage>
              {!!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.label?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.contactorType} mt={3}>
            <FormLabel>Contactor Type</FormLabel>
            <Select
              data-testid={`meters-${meterIndex}-switches-${switchIndex}-contactor-type`}
              {...register(`meters.${meterIndex}.switches.${switchIndex}.contactorType`)}
            >
              {SWITCH_CONTRACTOR_TYPES.map(({ label, value }, i) => (
                <option key={`switch-contactorType-option-${i}`} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {!!errors?.meters?.[meterIndex]?.switches?.[switchIndex]?.contactorType?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl my={3}>
            <FormLabel>Fallback State (in case of a loss of comms)</FormLabel>
            <Controller
              control={control}
              name={`meters.${meterIndex}.switches.${switchIndex}.fallbackState`}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <RadioGroup
                    data-testid={`meters-${meterIndex}-switches-${switchIndex}-fallback-state`}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  >
                    <Flex>
                      <Radio data-testid="aiugbeasogibes" colorScheme="primaryBranding" mr={4} value="open">
                        Open
                      </Radio>
                      <Radio colorScheme="primaryBranding" mr={4} value="closed">
                        Closed
                      </Radio>
                      <Radio colorScheme="primaryBranding" value="ignore">
                        Ignore
                      </Radio>
                    </Flex>
                  </RadioGroup>
                );
              }}
            />
          </FormControl>

          <SwitchScheduleConfiguration {...props} />
        </Box>
      )}
    </Box>
  );
}
