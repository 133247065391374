import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, IconButton, useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { Appliance } from '../../../../../api/api-appliance';
import { removeAppliance } from '../circuitApplianceConfigSlice';

export function DeleteApplianceButton({ appliance }: { appliance: Appliance }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  return (
    <Box>
      <IconButton
        aria-label="Delete Appliance"
        icon={<CloseIcon w={2.5} h={2.5} />}
        variant="ghost"
        h={'fit-content'}
        minW="fit-content"
        fontSize={'sm'}
        color="gray.500"
        _hover={{ color: 'gray.800' }}
        _dark={{ color: 'gray.300', _hover: { color: 'gray.100' } }}
        onClick={onOpen}
        data-testid={`delete-appliance-${appliance.appliance_id}-button`}
      />

      <AlertDialogModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => dispatch(removeAppliance(appliance.appliance_id))}
        onCancel={onClose}
        header={`Delete Appliance - ${appliance.appliance_name}?`}
        subHeader={'Are you sure you want to delete this appliance?'}
        confirmButtonName="Confirm"
      />
    </Box>
  );
}
