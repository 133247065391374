import React from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import { CustomButton } from '../../../common/components/CustomButton';
import { openCortexAppAtRoute } from '../../dashboard/dashboard-helpers';
import { selectSite } from '../siteSlice';

export const CommissioningCompleteModal = ({
  isOpen,
  onClose,
  onInviteMoreUsers,
}: DialogProps & {
  onInviteMoreUsers: () => void;
}) => {
  const site = useSelector(selectSite);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Commissioning Complete</ModalHeader>

        <ModalCloseButton mt={1} />
        <ModalBody
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx={5}
          my={4}
          data-testid="meter-commissioning-complete-modal"
        >
          <Center p={4} mx="auto" background={'primaryBranding.500'} borderRadius="50%">
            <CheckIcon color={'white'} w={6} h={6} />
          </Center>
          <Text textAlign={'center'} fontWeight={500} mt={4}>
            Site commissioning is now complete!
          </Text>
          <Text textAlign={'center'} mt={2} fontSize={'sm'} color={'gray.600'} _dark={{ color: 'gray.400' }}>
            You can now view the live page in Cortex. All you have to do is follow the instructions you receive by email
            and pay for the subscription.
          </Text>
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column" alignItems="flex-start">
          <CustomButton
            type="button"
            mx="auto"
            w="100%"
            maxW={250}
            data-testid="view-live-page-button"
            px={10}
            py={4}
            my={0}
            // istanbul ignore next -- @preserve
            onClick={() => openCortexAppAtRoute(site.clipsal_solar_id, 'live')}
          >
            View live page
          </CustomButton>
          <CustomButton
            type="button"
            variant={'outline'}
            mx="auto"
            mt={3}
            w="100%"
            maxW={250}
            data-testid="invite-more-users-button"
            px={10}
            py={4}
            my={0}
            onClick={onInviteMoreUsers}
          >
            Invite more users
          </CustomButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
