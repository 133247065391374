import React, { useMemo } from 'react';
import { Box, HStack, Input, Select, Text, useBreakpointValue } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { useSiteMeters } from '../../meterSetupApi';
import { selectSelectedMeterId, setSelectedMeterId } from '../circuitApplianceConfigSlice';

export const SelectMeter = ({ isDisabled }: { isDisabled: boolean }) => {
  const { siteMeters } = useSiteMeters();
  const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const selectedMeterId = useSelector(selectSelectedMeterId);
  const dispatch = useDispatch();

  const selectedManufacturerName = useMemo(() => {
    const selectedMeter = siteMeters.find((meter) => meter.site_identifier === selectedMeterId);
    return selectedMeter?.meta_data.manufacturer.manufacturer_name ?? '';
  }, [siteMeters, selectedMeterId]);

  return (
    <HStack mb={4} spacing={4} flexDir={isMobileViewport ? 'column' : 'row'} w="100%" maxW={600}>
      <Box w="100%">
        <Text fontSize="md" fontWeight="bold">
          Select Meter
        </Text>
        <Select
          w="100%"
          maxW={250}
          data-testid="select-meter"
          placeholder=""
          disabled={isDisabled}
          value={selectedMeterId}
          onChange={(e) => dispatch(setSelectedMeterId(e.target.value))}
        >
          {siteMeters.map((meter) => (
            <option key={meter.site_identifier} value={meter.site_identifier ?? ''}>
              {meter.site_identifier}
            </option>
          ))}
        </Select>
      </Box>
      <Box w="100%">
        <Text fontSize="md" fontWeight="bold">
          Manufacturer
        </Text>
        <Input value={selectedManufacturerName} isReadOnly w="100%" maxW={250} variant={'filled'} />
      </Box>
    </HStack>
  );
};
