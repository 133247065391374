import React, { useCallback, useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { decode } from 'base64-arraybuffer';
import { useDropzone } from 'react-dropzone';

import siteImageUploadImg from '../../../assets/images/onboarding_upload_site_image.svg';

export type UploadedFile = {
  url: string;
  isPersisted: boolean;
  fileData: ArrayBuffer | null; // Only relevent for non-persisted images
  extension: string;
};

type UploadBusinessLogoProps = {
  onUploadImage: (fileDataArrayBuffer: ArrayBuffer, extension: string) => void | Promise<void>;
  onDeleteFile: (file: UploadedFile) => void | Promise<void>;
  logoFile: UploadedFile | null;
  isUploading?: boolean;
};

const INITIAL_MODAL_CONFIG = { isModalOpen: false, imageUrl: '', isImageLoaded: false };

export function UploadBusinessLogo({ onUploadImage, logoFile, onDeleteFile, isUploading }: UploadBusinessLogoProps) {
  const [state, setState] = useState(INITIAL_MODAL_CONFIG);
  const toast = useToast();
  const { iconColor, iconBackgroundColor } = useColorModeValue(
    { iconColor: 'black', iconBackgroundColor: 'white' },
    { iconColor: 'white', iconBackgroundColor: 'black' }
  );
  const handleFileUpload = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading was aborted');
        };
        reader.onerror = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading has failed');
        };
        reader.onload = async () => {
          const fileData = reader.result as ArrayBuffer;
          const extension = file.name.split('.').pop() as string;
          onUploadImage(fileData, extension);
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [toast, onUploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif', 'image/apng', 'image/avif'],
    multiple: false,
  });

  function getConditionalRootProps() {
    if (!Capacitor.isNativePlatform()) return getRootProps();

    // If on a native platform, let the user take or select a photo themselves.
    return {
      onClick: async () => {
        const { format, base64String } = await Camera.getPhoto({
          allowEditing: true,
          resultType: CameraResultType.Base64,
        });

        const fileData = decode(base64String as string);
        onUploadImage(fileData, format);
      },
    };
  }

  const noFilesContents = (
    <Center
      my={3}
      justifySelf={'center'}
      flexDirection="column"
      border="2px"
      borderStyle="dashed"
      rounded={8}
      borderColor="day.500"
      bg="rgba(134, 181, 209, 0.1)"
      minH="155px"
      data-testid="empty-logo-container"
      {...getConditionalRootProps()}
    >
      {!Capacitor.isNativePlatform() && <input data-testid="upload-input" {...getInputProps()} />}
      <Image mb={4} src={siteImageUploadImg} alt="Upload icon" w={65} />
      <Text px={2} textAlign="center" mb={2} fontSize="sm" color="dusk050.500">
        Transparent PNG with 4/1 aspect ratio recommended
      </Text>
    </Center>
  );

  const existingFilesContents = (
    <Box my={4} minH="155px">
      <Modal
        isOpen={state.isModalOpen}
        onClose={() => {
          setState(INITIAL_MODAL_CONFIG);
        }}
        allowPinchZoom
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded={10} overflow="hidden" mx={4}>
          <ModalCloseButton data-testid="close-image-viewer" bg={iconBackgroundColor} color={iconColor} rounded={20} />
          <ModalBody p={0}>
            <Center minH="60vh" data-testid="image-viewer">
              {!state.isImageLoaded && (
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
              )}
              <Image
                shadow="md"
                display={state.isImageLoaded ? 'initial' : 'none'}
                w="100%"
                h="100%"
                objectFit={'cover'}
                src={state.imageUrl}
                alt={'file'}
                onLoad={() => setState((prevProps) => ({ ...prevProps, isImageLoaded: true }))}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      {logoFile && (
        <Flex>
          <Center w={200} position="relative">
            <IconButton
              icon={isUploading ? <Spinner color="white" size="sm" /> : <CloseIcon fontSize="xs" color="white" />}
              aria-label="Cancel"
              isDisabled={isUploading}
              onClick={() => onDeleteFile(logoFile)}
              minW={8}
              h={8}
              variant="solid"
              bg="#333333"
              _hover={{ bg: 'black' }}
              shadow={'lg'}
              rounded={100}
              position="absolute"
              top={-3}
              right={-3}
              zIndex={2}
              data-testid={'delete-image'}
            />
            <Image
              data-testid="logo-preview"
              cursor={'pointer'}
              rounded={2}
              objectFit={'contain'}
              src={logoFile.url}
              alt={'file'}
              onClick={() => setState({ isModalOpen: true, imageUrl: logoFile.url, isImageLoaded: false })}
            />
          </Center>
        </Flex>
      )}
    </Box>
  );

  return <Box>{logoFile ? existingFilesContents : noFilesContents}</Box>;
}
