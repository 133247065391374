import React from 'react';
import { Box, Grid, Heading, Skeleton, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import { ApplianceCircuitsDropArea } from './ApplianceCircuitsDropArea';
import { DragAndDropContext } from './DragAndDropContext';

export const ApplianceConfiguration = ({ isLoading }: { isLoading: boolean }) => {
  const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Box px={isMobileViewport ? 0 : 2} mt={12}>
      <Heading size={'md'} mb={2}>
        Appliance Association
      </Heading>
      <Text
        fontSize="md"
        color="gray.600"
        _dark={{
          color: 'gray.400',
        }}
        mb={4}
      >
        Use the below drag and drop UI to associate specific circuits with specific appliances, then click the{' '}
        <strong>Save</strong> button.
      </Text>

      {isLoading ? (
        <VStack w="100%" overflow={'auto'} spacing={2}>
          <Skeleton h={150} w="100%" />
          <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4} w="100%">
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton h={150} w="100%" key={index} />
            ))}
          </Grid>
        </VStack>
      ) : (
        <DragAndDropContext>
          <ApplianceCircuitsDropArea />
        </DragAndDropContext>
      )}
    </Box>
  );
};
