import React from 'react';
import { Box, Button, Center, Divider, Heading, useBreakpointValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { MobileTopNav } from '../../../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT, TOP_NAV_SPACING_AFFORDANCE } from '../../../../common/constants';
import { DataCorrectionForm } from './DataCorrectionForm';

export const DataCorrection = () => {
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });

  return (
    <Box px={isMobileViewport ? 0 : 10} pb={BOTTOM_NAV_HEIGHT}>
      {isMobileViewport ? (
        <MobileTopNav title={'Data Correction'} backURL={'/account/home'} />
      ) : (
        <Button as={Link} to={'/account/home'} mt={3}>
          Back
        </Button>
      )}
      <Center>
        <Box
          w={isMobileViewport ? '100%' : '90%'}
          minH={isMobileViewport ? `calc(100vh - ${BOTTOM_NAV_HEIGHT} - ${TOP_NAV_SPACING_AFFORDANCE})` : '60vh'}
          bg={'white'}
          _dark={{ bg: 'gray.900' }}
          borderRadius={isMobileViewport ? 0 : 10}
          overflow="hidden"
        >
          {!isMobileViewport && (
            <Heading mb={5} size={'lg'} paddingLeft={isMobileViewport ? 0 : 6} paddingTop={isMobileViewport ? 0 : 6}>
              Data Correction
            </Heading>
          )}
          <Divider />
          <DataCorrectionForm />
        </Box>
      </Center>
    </Box>
  );
};
