import React from 'react';
import { Capacitor } from '@capacitor/core';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import packageJSON from '../../../../package.json';
import { MobileTopNav } from '../../../common/components/MobileTopNav';
import { ANDROID_PLAY_STORE_URL, APPLE_APP_STORE_URL, PRIVACY_POLICY_URL } from '../../../common/constants';
import { TermsAndConditionsModal } from '../../authentication/TermsAndConditionsModal';

export function AccountAbout() {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const { background, hoverBackground } = useColorModeValue(
    { background: 'white', hoverBackground: 'gray.50' },
    { background: 'gray.900', hoverBackground: 'gray.800' }
  );

  return (
    <Box px={[0, 0, 10]}>
      {isMobileViewport ? (
        <MobileTopNav title={'About'} backURL={'/account/home'} />
      ) : (
        <Button as={Link} to={'/account/home'} mt={3}>
          Back
        </Button>
      )}

      <Center mx={[0, 0, 10]}>
        <Box w={['100%', '100%', '50%']} bg={background} borderRadius={[0, 0, 10]} overflow="hidden">
          {isMobileViewport || (
            <Heading mb={5} size={'lg'} paddingLeft={[0, 0, 6]} paddingTop={[0, 0, 6]}>
              About
            </Heading>
          )}

          <Box
            h={'96px'}
            borderTop={`1px solid ${theme.colors.dusk005[500]}`}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            p={6}
          >
            <Box justifyContent={'center'}>
              <Text fontWeight={'bold'}>Build Version</Text>
              <Text>Version {packageJSON.version}</Text>
            </Box>
          </Box>

          <Grid
            onClick={() =>
              window.open(Capacitor.getPlatform() === 'ios' ? APPLE_APP_STORE_URL : ANDROID_PLAY_STORE_URL)
            }
            role={'button'}
            _hover={{ background: hoverBackground }}
            cursor={'pointer'}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            p={6}
            gridTemplateColumns={'1fr 25px'}
            h={'96px'}
          >
            <Box justifyContent={'center'}>
              <Text fontWeight={'bold'}>Rate us</Text>
              <Text>Rate us on the App Store</Text>
            </Box>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>

          <Grid
            onClick={() => window.open(PRIVACY_POLICY_URL)}
            role={'button'}
            _hover={{ background: hoverBackground }}
            cursor={'pointer'}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            p={6}
            gridTemplateColumns={'1fr 25px'}
            h={'96px'}
          >
            <Flex align={'center'}>
              <Text fontWeight={'bold'}>Privacy Policy</Text>
            </Flex>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>

          <Grid
            onClick={onOpen}
            role={'button'}
            _hover={{ background: hoverBackground }}
            cursor={'pointer'}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            p={6}
            gridTemplateColumns={'1fr 25px'}
            h={'96px'}
          >
            <Flex align={'center'}>
              <Text fontWeight={'bold'}>Terms & Conditions</Text>
            </Flex>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>

          <TermsAndConditionsModal isOpen={isOpen} onClose={onClose} />
        </Box>
      </Center>
    </Box>
  );
}
