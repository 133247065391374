import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAllAppliances, selectAllCircuits } from '../circuitApplianceConfigSlice';

// Using negative number to represent the drop area id for available circuits
// so that it does not conflict with the appliance id.
export const AVAILABLE_CIRCUITS_DROP_AREA_ID = -1000;

export const useAvailableCircuits = () => {
  const allCircuits = useSelector(selectAllCircuits);
  const allAppliances = useSelector(selectAllAppliances);
  return useMemo(() => {
    return allCircuits.filter((circuit) => !circuit.appliance_id);
  }, [allCircuits, allAppliances]);
};

export const useApplianceCircuits = (applianceId: number) => {
  const allCircuits = useSelector(selectAllCircuits);
  const allAppliances = useSelector(selectAllAppliances);
  return useMemo(() => {
    return allCircuits.filter((circuit) => circuit.appliance_id === applianceId);
  }, [allCircuits, allAppliances]);
};
