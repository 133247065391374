import React from 'react';
import { CellProps, Column } from 'react-table';

import { Circuit } from '../../../../../api/api-device';
import { CircuitId, CircuitName, ControlledLoad, ControlledLoad2, SelectMonitor } from './CircuitTableComponents';

export const CIRCUIT_TABLE_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'oem_circuit_id',
    id: 'oem_circuit_id',
    Cell: (cellProps: CellProps<Circuit>) => <CircuitId {...cellProps} />,
  },
  {
    Header: 'Monitor',
    id: 'clipsal_monitors',
    accessor: 'clipsal_monitors',
    Cell: (cellProps: CellProps<Circuit>) => <SelectMonitor {...cellProps} />,
  },
  {
    Header: 'Name',
    id: 'circuit_name',
    accessor: 'circuit_name',
    Cell: (cellProps: CellProps<Circuit>) => <CircuitName {...cellProps} />,
  },
  {
    Header: 'Controlled Load',
    id: 'controlled_load_yn',
    accessor: 'controlled_load_yn',
    Cell: (cellProps: CellProps<Circuit>) => <ControlledLoad {...cellProps} />,
  },
  {
    Header: 'Controlled Load 2',
    id: 'controlled_load2_yn',
    accessor: 'controlled_load2_yn',
    Cell: (cellProps: CellProps<Circuit>) => <ControlledLoad2 {...cellProps} />,
  },
] as Column<Circuit>[];
