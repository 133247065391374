import React from 'react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  IconButton,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoDark } from '../../assets/images/clipsal_logo_dark.svg';
import { ReactComponent as Logo } from '../../assets/images/clipsal_logo.svg';
import { SIDENAV_WIDTH } from '../../common/constants';
import { LogoutIcon } from '../../styles/custom-icons';
import { selectUser } from '../user/userSlice';
import { SiteNavLink, useSelectedIndexFromLinks } from './custom-nav-hooks';

interface Props {
  links: SiteNavLink[];
}

const COMMON_NAV_LINK_BUTTON_PROPS = {
  py: 6,
  my: 1,
  mx: 2,
  rounded: 4,
  justifyContent: 'start',
  variant: 'ghost',
  size: 'md',
};

export function SideNav({ links }: Props) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const tenantLogo = user.tenant.logo_url;
  const [selectedIndex, setSelectedIndex] = useSelectedIndexFromLinks(links);
  const { toggleColorMode } = useColorMode();
  const { backgroundColor, colorModeIcon, buttonBackgroundColor, LogoIcon } = useColorModeValue(
    {
      backgroundColor: 'white',
      colorModeIcon: <SunIcon />,
      buttonBackgroundColor: 'customBodyBackground.500',
      LogoIcon: <Logo />,
    },
    {
      backgroundColor: 'gray.800',
      colorModeIcon: <MoonIcon />,
      buttonBackgroundColor: 'gray.900',
      LogoIcon: <LogoDark />,
    }
  );

  function handleClick(redirectRoute: string, i: number) {
    window.scrollTo({ top: 0 });
    setSelectedIndex(i);
    navigate(redirectRoute, { replace: true });
  }

  return (
    <Flex
      direction="column"
      background={backgroundColor}
      minHeight="100vh"
      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      position="fixed"
      width={SIDENAV_WIDTH}
      zIndex={100}
    >
      <Flex mx={5} minWidth={180} mt={2}>
        {tenantLogo ? (
          <Image src={tenantLogo} maxHeight={100} objectFit={'contain'} />
        ) : (
          <Box ml={5} width={150}>
            {LogoIcon}
          </Box>
        )}
      </Flex>
      <Box borderBottom="1px" borderColor="customBodyBackground.500" w="100%" my={2} />

      {links.map((link, i) => {
        const formattedRouteName = link.routeName.replace('/', '').replaceAll(' ', '-').toLowerCase();
        const icon = selectedIndex === i ? link.activeIcon : link.icon;

        return (
          <Button
            {...COMMON_NAV_LINK_BUTTON_PROPS}
            data-testid={`${formattedRouteName}-side-nav-button`}
            key={link.routeName}
            aria-label={link.ariaLabel}
            bg={selectedIndex === i ? buttonBackgroundColor : 'none'}
            onClick={() => handleClick(link.route, i)}
          >
            {React.cloneElement(icon, { w: '28px', h: '28px' })}
            <Text ml={2}>{link.routeName}</Text>
            {link.isNew && (
              <Badge
                background="#308F6D"
                variant="solid"
                ml="auto"
                px={1.5}
                py={0.5}
                fontWeight="normal"
                textTransform={'capitalize'}
                letterSpacing={1}
                fontSize={14}
              >
                New
              </Badge>
            )}
          </Button>
        );
      })}
      <Box ml={4} mb={2} mt={'auto'}>
        <IconButton onClick={toggleColorMode} aria-label="Change color scheme" icon={colorModeIcon} />
      </Box>
      <Button
        {...COMMON_NAV_LINK_BUTTON_PROPS}
        leftIcon={<LogoutIcon />}
        onClick={() => {
          if (window.confirm('Are you sure you want to log out?')) navigate('/logout');
        }}
      >
        Logout
      </Button>
      {tenantLogo && (
        <>
          <Divider />
          <Center flexDirection={'column'} w="60%" mx="auto" py={4}>
            <Text fontStyle="italic" color="fixedCostGrey.500" mb={2}>
              Powered by
            </Text>
            {LogoIcon}
          </Center>
        </>
      )}
    </Flex>
  );
}
