import React, { useMemo, useState } from 'react';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format, isToday, isYesterday } from 'date-fns';
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { getMinuteDifferenceBetweenTimeStamps } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';
import { WW_MODEL_TO_CLIPSAL_MODEL_MAP } from 'clipsal-cortex-utils/src/constants/common-constants';

import { patch, post } from '../../../../api/api-helpers';
import { useAppDispatch } from '../../../../app/hooks';
import { CustomButton } from '../../../../common/components/CustomButton';
import { GenericError } from '../../../../common/types/types';
import { ScanBarcodeIcon } from '../../../../styles/custom-icons';
import { selectSite } from '../../siteSlice';
import { fetchAssignments, selectAssignments } from '../../system-details/systemDetailsSlice';
import { mapMeterToForm } from '../form-mapping-helpers';
import { checkMeterPhasing } from '../meter-phasing-helpers';
import { MeterSetupFormData } from '../meter-setup-form-types';
import { addMeter, selectRawMeters } from '../meterSetupSlice';

type Props = Readonly<{
  reset: UseFormReset<MeterSetupFormData>;
  control: Control<MeterSetupFormData>;
  setError: UseFormSetError<MeterSetupFormData>;
  register: UseFormRegister<MeterSetupFormData>;
  errors: FieldErrors<MeterSetupFormData>;
  setValue: UseFormSetValue<MeterSetupFormData>;
  getValues: UseFormGetValues<MeterSetupFormData>;
  field: FieldArrayWithId<MeterSetupFormData>;
  meterIndex: number;
}>;

const WW_GEN_2_QR_CODE_PREFIX = 'https://wattsup.at/1/';

export function MeterDetailsFieldSet(props: Props) {
  const { field, meterIndex, setError, setValue, register, errors, reset, getValues } = props;
  const [isMeterButtonLoading, setIsMeterButtonLoading] = useState(false);
  const [isFetchingSerialNumber, setIsFetchingSerialNumber] = useState(false);
  const dispatch = useAppDispatch();
  const assignments = useSelector(selectAssignments);
  const toast = useToast();
  const site = useSelector(selectSite);
  const allMeters = useSelector(selectRawMeters);
  const meter = allMeters[field.serialNumber];

  async function associateMeter(serialNumber: string) {
    const meterData = await post<WattwatchersMeter>(`/fleet/sites/${site.clipsal_solar_id}/register_ww_device`, {
      ww_device_id: serialNumber,
    });

    if (!assignments.length) await dispatch(fetchAssignments());

    const { installed_device_id: installedDeviceID } = meterData;
    const label = getValues().meters[meterIndex].label;
    if (installedDeviceID) {
      // update phasing in meters if required as it needs to match site phasing
      const { isCorrectlyPhased, updatedMeters } = checkMeterPhasing(site.electrical_config, [meterData]);
      const patchedMeterData = await patch<WattwatchersMeter>(
        `/fleet/wattwatchers/installed_meters/${installedDeviceID}`,
        !isCorrectlyPhased ? { ...updatedMeters[0], label } : { label }
      );
      dispatch(addMeter(patchedMeterData));
    } else {
      dispatch(addMeter(meterData));
    }

    // Map circuits into the form
    const newMeters = getValues().meters.map((meter, idx) => {
      if (idx === meterIndex) {
        // Bit yuck, but because we don't have any circuits in our system for this meter as of yet, just default them.
        return mapMeterToForm(
          {
            ...meterData,
            ww_device_id: meter.serialNumber,
          },
          Number(meterData.installed_device_id)
        );
      }

      return meter;
    });

    reset({
      meters: newMeters,
    });
  }

  async function handleGetMeterData() {
    const serialNumber = getValues().meters[meterIndex].serialNumber;

    if (!serialNumber) {
      setError(`meters.${meterIndex}.serialNumber` as const, { message: 'Serial number is required' });
    } else {
      try {
        setIsMeterButtonLoading(true);
        await associateMeter(serialNumber);
        setIsMeterButtonLoading(false);
      } catch (e) {
        console.error(e);
        setIsMeterButtonLoading(false);

        let errorMessage =
          (e as GenericError).response.data.message ?? 'Something went wrong, please check the device serial number.';

        // Handling this here until BE fixes the error message
        const isDeviceInUse = (e as GenericError).response?.status === 409;
        if (isDeviceInUse) errorMessage = 'This device is already in use.';

        toast({
          title: 'Error loading meter',
          description: errorMessage,
          status: 'error',
          isClosable: true,
        });
      }
    }
  }

  async function handleStartBarcodeScan() {
    const hasPermission = await didUserGrantBarcodePermission();

    if (hasPermission) {
      await BarcodeScanner.hideBackground();
      document.body.classList.add('qrscanner');

      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        const { content } = result;
        // WW Gen 2 meters will have a QR code that starts with `https://wattsup.at/1/:hash`
        // This is a special case where we need to fetch the serial number from the server using the hash
        if (content.includes(WW_GEN_2_QR_CODE_PREFIX)) {
          // Reset the serial number to empty string to avoid confusion
          setIsFetchingSerialNumber(true);
          setValue(`meters.${meterIndex}.serialNumber`, '');
          document.body.classList.remove('qrscanner');
          try {
            const qrHash = content.split(WW_GEN_2_QR_CODE_PREFIX)[1];
            const serialNumber = await post<string>('/fleet/wattwatchers/oem_device_id', { ww_qr_hash: qrHash });
            setValue(`meters.${meterIndex}.serialNumber`, serialNumber);
            setIsFetchingSerialNumber(false);
            toast({
              title: 'Barcode successfully scanned',
              status: 'success',
              isClosable: true,
            });
          } catch (error) {
            setIsFetchingSerialNumber(false);
            console.error(error);
            toast({
              title: 'Error fetching serial number',
              description: 'Something went wrong, please try again.',
              status: 'error',
              isClosable: true,
            });
          }
        } else {
          setValue(`meters.${meterIndex}.serialNumber`, content);
          toast({
            title: 'Barcode successfully scanned',
            status: 'success',
            isClosable: true,
          });
          document.body.classList.remove('qrscanner');
        }
      }
    }
  }

  return (
    <>
      <FormControl mt={3} mb={1} isInvalid={!!errors?.meters?.[meterIndex]?.label} id="label">
        <FormLabel>Meter Name</FormLabel>
        <Input
          data-testid={`meter-${meterIndex}-label`}
          defaultValue={field.label}
          placeholder={'Enter name'}
          {...register(`meters.${meterIndex}.label` as const)}
          type="text"
          onChange={(e) => {
            setValue(`meters.${meterIndex}.label` as any, e.target.value);
          }}
          onBlur={async (e) => {
            if (field.meterId && e.target.value)
              await patch(`/fleet/wattwatchers/installed_meters/${field.meterId}`, { label: e.target.value });
          }}
        />
        <FormErrorMessage>Meter name is required.</FormErrorMessage>
      </FormControl>
      <FormControl mt={3} mb={1} isInvalid={!!errors?.meters?.[meterIndex]?.serialNumber} id="serialNumber">
        <FormLabel>Serial Number</FormLabel>
        <InputGroup>
          <Input
            data-private
            data-testid={`meter-${meterIndex}-serial-number`}
            defaultValue={field.serialNumber}
            isDisabled={isFetchingSerialNumber}
            placeholder={isFetchingSerialNumber ? 'Fetching serial number...' : '######'}
            {...register(`meters.${meterIndex}.serialNumber` as const)}
            type="text"
            //This overrides the register function's onChange handler, implementing here as it reduces boilerplate
            onChange={(e) => {
              setValue(`meters.${meterIndex}.serialNumber` as any, e.target.value.toUpperCase());
            }}
          />
          {Capacitor.isNativePlatform() && (
            <InputRightElement>
              {isFetchingSerialNumber ? (
                <Spinner color={'customBlue.500'} size="sm" />
              ) : (
                <IconButton
                  aria-label={'Scan barcode'}
                  size={'sm'}
                  colorScheme={'customBlue'}
                  variant={'ghost'}
                  icon={<ScanBarcodeIcon w={6} h={6} />}
                  onClick={handleStartBarcodeScan}
                />
              )}
            </InputRightElement>
          )}
        </InputGroup>
        <FormErrorMessage>Serial number is required.</FormErrorMessage>
      </FormControl>
      {field.meterId && meter && <MeterInfo meter={meter} />}
      {!field.meterId && (
        <CustomButton
          data-testid={`meter-${meterIndex}-get-meter-data-btn`}
          loadingText={'Fetching meter data...'}
          isLoading={isMeterButtonLoading}
          onClick={handleGetMeterData}
          type={'button'}
        >
          Get Meter Data
        </CustomButton>
      )}
    </>
  );
}

const MeterInfo = ({ meter }: { meter: WattwatchersMeter }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const { formattedDate, shouldSuggestExternalAntenna } = useMemo(() => {
    let lastHeardAtTimeStamp = meter.comms.lastHeardAt;
    let isInWifiMode = meter.comms.type === 'wifi';
    const isGen2Meter = meter.comms.type === 'cellular,wifi';
    if (isGen2Meter) {
      isInWifiMode = meter.comms.mode === 'wifi';
      lastHeardAtTimeStamp = (isInWifiMode ? meter.comms.wifi?.lastHeardAt : meter.comms.cellular?.lastHeardAt) ?? 0;
    }

    let formattedDate = 'N/A';
    let shouldSuggestExternalAntenna = false;

    // If no lastHeardAtTimeStamp, it means the meter has never been heard from
    if (!lastHeardAtTimeStamp) return { formattedDate, shouldSuggestExternalAntenna };

    // Convert lastHeardAtTimeStamp to a formatted readable date string
    const lastHeardAtDate = new Date(lastHeardAtTimeStamp * 1000);
    const formattedDateString = format(lastHeardAtDate, 'PP');
    const formattedTimeString = format(lastHeardAtDate, 'pp');
    if (isToday(lastHeardAtDate)) {
      formattedDate = `Today, ${formattedTimeString}`;
    } else if (isYesterday(lastHeardAtDate)) {
      formattedDate = `Yesterday, ${formattedTimeString}`;
    } else {
      formattedDate = `${formattedDateString}, ${formattedTimeString}`;
    }

    // Check if the meter should be suggested an external antenna
    const lastHeardInMinutes = getMinuteDifferenceBetweenTimeStamps(meter.comms.lastHeardAt, meter.timezone);
    if (isInWifiMode) {
      shouldSuggestExternalAntenna = false; // No need to suggest external antenna when in wifi mode
    } else if (!lastHeardInMinutes || lastHeardInMinutes > 5) {
      shouldSuggestExternalAntenna = true; //  show the alert if the meter has been offline
    } else {
      shouldSuggestExternalAntenna = meter.comms.signalQualityDbm < -90;
    }

    return { formattedDate, shouldSuggestExternalAntenna };
  }, [meter.comms]);

  const meterModel = useMemo(() => {
    return WW_MODEL_TO_CLIPSAL_MODEL_MAP[meter.model] ?? meter.model;
  }, [meter]);

  return (
    <Box data-testid="meter-info">
      <Flex wrap={'wrap'} columnGap={4} justify={'space-between'}>
        <Flex minW="fit-content">
          <Box as="span" mr={1} color="gray.400">
            Model:
          </Box>
          {meterModel}
        </Flex>
        <Flex minW="fit-content">
          <Box as="span" mr={1} color="gray.400">
            Firmware:
          </Box>
          {meter.firmwareVersion}
        </Flex>
        <Flex minW="fit-content">
          <Box as="span" mr={1} color="gray.400">
            Last Updated:
          </Box>
          {formattedDate}
        </Flex>
      </Flex>
      {isOpen && shouldSuggestExternalAntenna && (
        <Alert status="info" borderRadius={4} mt={2} variant="left-accent" data-tesid="poor-signal-alert">
          <AlertIcon />
          <AlertDescription>
            The signal quality of the meter is poor. Please check the antenna connections or install an external antenna
            to ensure reliable operation of the device.
          </AlertDescription>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={onClose}
            data-tesid="poor-signal-alert-close-btn"
          />
        </Alert>
      )}
    </Box>
  );
};
