import React from 'react';
import { Box, Heading, Skeleton, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import { CircuitConfigurationContents } from './CircuitConfigurationContents';
import { SelectMeter } from './SelectMeter';

export const CircuitConfiguration = ({ isLoading }: { isLoading: boolean }) => {
  const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Box>
      <Heading size={'md'} mb={2}>
        Circuit Configuration
      </Heading>
      <Text
        fontSize="md"
        color="gray.600"
        _dark={{
          color: 'gray.400',
        }}
        mb={4}
      >
        Update values of circuit in the table and then click the <strong>Save</strong> button.
      </Text>
      <SelectMeter isDisabled={isLoading} />

      {isLoading ? (
        <VStack w="100%" overflow={'auto'} spacing={2}>
          {Array.from({ length: isMobileViewport ? 5 : 7 }).map((_, index) => (
            <Skeleton h={isMobileViewport ? '80px' : '45px'} w="100%" key={index} />
          ))}
        </VStack>
      ) : (
        <CircuitConfigurationContents isLoaded={!isLoading} />
      )}
    </Box>
  );
};
