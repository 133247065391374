import React, { useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import { CustomButton } from '../../../../../common/components/CustomButton';
import { WEEKDAYS } from '../../../../../common/constants';

type EditDayIntervalModalProps = {
  onAccept: (selectedWeekdayIndexes: number[]) => void;
  initialValue: number[];
} & DialogProps;

export function EditDayIntervalModal({ isOpen, onClose, onAccept, initialValue }: EditDayIntervalModalProps) {
  const [selectedWeekdayIndexes, setSelectedWeekdayIndexes] = useState<number[]>(initialValue);

  function handleUpdateSelectedWeekdayIndexes(weekdayIndex: number) {
    let newWeekdayIndexes;

    if (selectedWeekdayIndexes.includes(weekdayIndex)) {
      newWeekdayIndexes = selectedWeekdayIndexes.filter(
        (selectedWeekdayIndex) => selectedWeekdayIndex !== weekdayIndex
      );
    } else {
      newWeekdayIndexes = [...selectedWeekdayIndexes, weekdayIndex];
    }

    setSelectedWeekdayIndexes(newWeekdayIndexes);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">Select day interval</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody
          data-testid="edit-day-interval-container"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx={5}
        >
          <Heading mb={3} size="md">
            Repeats
          </Heading>
          {WEEKDAYS.map((day, index) => {
            const isSelected = selectedWeekdayIndexes.includes(index);
            return (
              <Flex
                px={2}
                align={'center'}
                py={3}
                borderTop={index === 0 ? '1px solid' : undefined}
                borderBottom={'1px solid'}
                borderColor={'#C5C5C5'}
                justify="space-between"
                as="button"
                w="100%"
                key={day}
                data-is-selected={isSelected ? true : undefined}
                data-testid={`day-${index}-toggle-btn`}
                onClick={() => handleUpdateSelectedWeekdayIndexes(index)}
              >
                <Text>{day}</Text> {isSelected && <CheckIcon color={'green.500'} />}
              </Flex>
            );
          })}
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column" alignItems="flex-start">
          <CustomButton
            type="button"
            mx="auto"
            w="fit-content"
            data-testid="edit-day-interval-save-btn"
            px={10}
            py={4}
            my={0}
            onClick={() => {
              onAccept(selectedWeekdayIndexes);
            }}
          >
            Save
          </CustomButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
