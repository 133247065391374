import React from 'react';
import { Input, Select, Text } from '@chakra-ui/react';
import { CellProps } from 'react-table';

import { Circuit } from '../../../../../api/api-device';
import { SelectSortedAssignments } from './SelectSortedAssignments';
import { useUpdateCircuit } from './use-update-circuit';

export function CircuitId({ row }: CellProps<Circuit>) {
  const { shortenedCircuitId } = useUpdateCircuit(row.original);
  return <Text>{shortenedCircuitId}</Text>;
}

export function SelectMonitor({ row }: CellProps<Circuit>) {
  const { handleSelectMonitor } = useUpdateCircuit(row.original);

  return (
    <SelectSortedAssignments
      value={row.original.clipsal_monitors}
      onChange={handleSelectMonitor}
      dataTestId={`select-monitor-${row.index}`}
    />
  );
}

export function CircuitName({ row }: CellProps<Circuit>) {
  const { handleChangeCircuitName } = useUpdateCircuit(row.original);
  return (
    <Input
      value={row.original.circuit_name}
      onChange={handleChangeCircuitName}
      data-testid={`input-circuit-name-${row.index}`}
    />
  );
}

export function ControlledLoad({ row }: CellProps<Circuit>) {
  const { handleSelectControlledLoad } = useUpdateCircuit(row.original);

  return (
    <Select
      placeholder=""
      value={row.original.controlled_load_yn}
      onChange={handleSelectControlledLoad}
      data-testid={`select-controlled-load-${row.index}`}
    >
      <option value={'Y'}>Y</option>
      <option value={'N'}>N</option>
    </Select>
  );
}

export function ControlledLoad2({ row }: CellProps<Circuit>) {
  const { handleSelectControlledLoad2 } = useUpdateCircuit(row.original);

  return (
    <Select
      placeholder=""
      value={row.original.controlled_load2_yn}
      onChange={handleSelectControlledLoad2}
      data-testid={`select-controlled-load2-${row.index}`}
    >
      <option value={'Y'}>Y</option>
      <option value={'N'}>N</option>
    </Select>
  );
}
