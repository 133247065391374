import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StepProgressData, StepProgressName } from '../../api/api-step-progress';
import { RootState } from '../../app/store';

type WizardStepComponentName = 'SiteDetails' | 'CustomerDetails' | 'SystemDetails' | 'MeterSetup';

const STEPS: StepState[] = [
  {
    number: 1,
    title: 'Site',
    componentName: 'SiteDetails',
    isComplete: false,
    isRequired: true,
    route: 'start',
  },
  {
    number: 2,
    title: 'System',
    componentName: 'SystemDetails',
    isComplete: false,
    isRequired: true,
    route: 'system_details',
  },
  {
    number: 3,
    title: 'Meter',
    componentName: 'MeterSetup',
    isComplete: false,
    isRequired: true,
    route: 'meter_setup/meters',
  },
  {
    number: 4,
    title: 'Finish',
    componentName: 'CustomerDetails',
    isComplete: false,
    isRequired: true,
    route: 'customer_details',
  },
];

export const STEP_PROGRESS_MAP_TO_COMPONENT_NAME: Record<
  Exclude<StepProgressName, 'Invite Customer'>,
  WizardStepComponentName
> = {
  'Site Details': 'SiteDetails',
  'System Details': 'SystemDetails',
  'Meter Setup': 'MeterSetup',
  'Customer Details': 'CustomerDetails',
};

type WizardState = {
  steps: StepState[];
  isLoaded: boolean;
  currentStepIndex: number;
  direction: 1 | -1;
  isSlidingEnabled: boolean;
  hasUnsavedChanges: boolean;
};

export type StepState = {
  number: number;
  title: string;
  isComplete: boolean;
  route: string;
  componentName: WizardStepComponentName;
  isRequired: boolean; // All steps are required, unless specified otherwise.
};

const initialState: WizardState = {
  steps: STEPS,
  isLoaded: false,
  currentStepIndex: 0,
  direction: 1,
  isSlidingEnabled: false,
  hasUnsavedChanges: false,
};

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  // Note: Validation is a part of form state. Saved state on moving steps
  reducers: {
    moveForward: (state) => {
      state.currentStepIndex += 1;
      state.direction = 1;
      state.isSlidingEnabled = true;
    },
    moveBack: (state) => {
      state.currentStepIndex -= 1;
      state.direction = -1;
      state.isSlidingEnabled = true;
    },
    setIsSlidingEnabled: (state, action: PayloadAction<boolean>) => {
      state.isSlidingEnabled = action.payload;
    },
    selectStep: (state, action: PayloadAction<{ stepIndex: number; isInitialLoad?: boolean }>) => {
      if (action.payload.stepIndex >= state.currentStepIndex) {
        state.direction = 1;
      } else {
        state.direction = -1;
      }
      state.isSlidingEnabled = !action.payload.isInitialLoad;
      state.currentStepIndex = action.payload.stepIndex;
    },
    setStepOptional: (state, action: PayloadAction<number>) => {
      state.steps[action.payload].isRequired = false;
    },
    updateStepsFromProgress: (state, action: PayloadAction<StepProgressData[]>) => {
      state.steps = action.payload.map<StepState>((progressData, i) => {
        const component = state.steps.find(
          (step) =>
            progressData.name !== 'Invite Customer' &&
            step.componentName === STEP_PROGRESS_MAP_TO_COMPONENT_NAME[progressData.name]
        ) as StepState;
        return {
          number: progressData.step,
          title: component.title,
          componentName: component.componentName,
          isComplete: progressData.status === 'COMPLETE',
          route: state.steps[i].route,
          isRequired: true,
        };
      });
    },
    completeCurrentStep: (state) => {
      state.steps[state.currentStepIndex].isComplete = true;
      state.currentStepIndex += 1;
      state.direction = 1;
      state.isSlidingEnabled = true;
    },
    setLoaded: (state) => {
      state.isLoaded = true;
    },
    setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  moveForward,
  moveBack,
  setIsSlidingEnabled,
  selectStep,
  completeCurrentStep,
  setLoaded,
  reset,
  updateStepsFromProgress,
  setStepOptional,
  setHasUnsavedChanges,
} = wizardSlice.actions;

export const selectWizard = (state: RootState) => {
  return state.wizard;
};

export const { reducer: wizardReducer } = wizardSlice;
