import '../../styles/react-multi-toggle.css';

import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/layout';
import { ChakraProps } from '@chakra-ui/system';
import { Control, Controller } from 'react-hook-form';

import { SearchableDropdown } from './SearchableDropdown';

type Option = Record<string | number, any>;

interface SelectProps {
  options: Option[] | undefined;
  valueLabelOptions?: [keyof Option, keyof Option];
  style?: React.CSSProperties & ChakraProps;
  fieldName: string;
  control: Control<any>;
  placeholder?: string;
  defaultValue: string | number;
  isDisabled?: boolean;
  onDependentFieldAndStateUpdate?: () => void;
  isInvalid: boolean;
  isLoading?: boolean;
}

export const SearchableDropdownFormControl = ({
  options,
  valueLabelOptions = ['value', 'label'],
  isDisabled,
  fieldName,
  control,
  placeholder,
  defaultValue,
  onDependentFieldAndStateUpdate,
  isInvalid,
  isLoading,
  ...style
}: SelectProps) => {
  /* Transform select options to value-label format */
  const selectOptions = useMemo(
    () =>
      (options?.length &&
        [...options].map((obj) => ({
          value: obj?.[valueLabelOptions[0]] as string | number,
          label: obj?.[valueLabelOptions[1]] as string | number,
        }))) ||
      undefined,
    [options, valueLabelOptions]
  );

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => {
        return (
          <Box {...style}>
            <SearchableDropdown
              isInvalid={isInvalid}
              isLoading={isLoading}
              placeholder={placeholder}
              options={selectOptions}
              value={value}
              onChange={(newValue) => {
                onChange(newValue);
                //Change other dependencies if any
                //e.g: when manufacturer is selected, clear model value
                onDependentFieldAndStateUpdate && onDependentFieldAndStateUpdate();
              }}
              isDisabled={isDisabled}
            />
          </Box>
        );
      }}
    />
  );
};
