import React, { ChangeEvent, useState } from 'react';
import {
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { AWSError } from 'aws-sdk/lib/error';

import forgotPasswordImg from '../../../assets/images/forgot_password.svg';
import { CustomButton } from '../../../common/components/CustomButton';
import { LoginRoute } from './login-utils';

export interface ForgotPasswordProps {
  onChangeRoute: (route: LoginRoute) => void;
  setEmail: (email: string) => void;
}

export function ForgotPassword({ onChangeRoute, setEmail }: ForgotPasswordProps) {
  const [state, setState] = useState({ email: '', touched: false, isSubmitting: false });
  const toast = useToast({ duration: 3000, isClosable: true });

  return (
    <Flex
      as={'form'}
      data-testid="forgot-password-form"
      direction={'column'}
      justify={'center'}
      align={'center'}
      onSubmit={async (e) => {
        e.preventDefault();
        setState({ ...state, isSubmitting: true });

        try {
          await Auth.forgotPassword(state.email.toLowerCase());
        } catch (e) {
          console.error(e);

          if ((e as AWSError).code === 'LimitExceededException') {
            toast({
              title: 'Login request limit exceeded',
              description: 'Please wait 10-15 minutes then try again.',
              status: 'error',
            });
            onChangeRoute(LoginRoute.Login);
            return;
          }
        }

        setEmail(state.email);
        setState({ ...state, isSubmitting: false });
        onChangeRoute(LoginRoute.ConfirmForgotPassword);
      }}
    >
      <CloseButton
        data-testid="close-forgot-password-flow"
        alignSelf={'flex-end'}
        onClick={() => {
          onChangeRoute(LoginRoute.Login);
        }}
        rounded={50}
      />
      <Image mb={4} width={'40%'} src={forgotPasswordImg} />
      <Heading mb={4} size={'md'}>
        Forgot your password?
      </Heading>
      <Text color={'textGrey.500'} textAlign={'center'} mb={8}>
        Enter your registered email address below and we’ll email you a 6 digit verification code to reset your
        password.
      </Text>
      <FormControl mb={8} width={'100%'} isInvalid={state.touched && !state.email}>
        <Input
          value={state.email}
          onBlur={() => {
            setState({ ...state, touched: true });
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setState({ ...state, email: e.currentTarget.value });
          }}
          placeholder={'Email address'}
          type={'email'}
          data-testid="forgot-password-email-address"
          data-private
          autoComplete={'email'}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>
      <CustomButton
        rounded={3}
        my={0}
        py={0}
        type={'submit'}
        width={'80%'}
        alignSelf={'center'}
        colorScheme="dusk100"
        id="resetPasswordCodeBtn"
        isLoading={state.isSubmitting}
        isDisabled={!state.email}
        data-testid="forgot-password-submit-btn"
      >
        Get verification code
      </CustomButton>
    </Flex>
  );
}
