import React, { useState } from 'react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { CircuitPlaceholderIcon } from 'clipsal-cortex-icons/src/custom-appliance-icons';

import { formatSearchString } from '../../utils/string-formatting,';

interface Props {
  value: string;
  options: Record<string, string | number>[];
  labelValueAttributes: [string, string];
  isInvalid: boolean;
  onChange: (value: string, label: string) => void;
  isSearchable?: boolean;
  searchPlaceHolder?: string;
  placeholder?: string;
}

export const SelectBottomDrawer = ({
  options,
  value,
  onChange,
  isInvalid,
  isSearchable = false,
  searchPlaceHolder,
  placeholder,
  labelValueAttributes,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const [labelAttribute, valueAttribute] = labelValueAttributes;
  const { iconColor, textColor } = useColorModeValue(
    {
      iconColor: 'dusk100.500',
      textColor: 'dusk100.500',
    },
    {
      iconColor: 'dusk100.50',
      textColor: 'white',
    }
  );
  const selectedLabel = options.find((option) => value === option[valueAttribute])?.[labelAttribute];

  const handleDrawerClose = () => {
    onClose();
    setSearchTerm('');
  };

  return (
    <>
      <Button
        data-testid="select-bottom-drawer-open-btn"
        variant="outline"
        onClick={() => options.length && onOpen()}
        w={'100%'}
        alignItems="center"
        border={isInvalid ? '2px' : '1px'}
        borderColor={isInvalid ? 'red.500' : 'inherit'}
        cursor="pointer"
        pr={2}
      >
        <Text fontWeight="normal" color={value && options.length ? textColor : 'slate.500'}>
          {options.length ? selectedLabel || placeholder || 'Select an option...' : 'Loading...'}
        </Text>
        <ChevronDownIcon w={5} h={5} color={iconColor} ml={'auto'} />
      </Button>
      <Drawer placement="bottom" onClose={handleDrawerClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent borderTopRadius={20} height={'70vh'}>
          {isSearchable && (
            <DrawerHeader borderBottomWidth="1px">
              <Flex direction="column" align="center" justify="center">
                <IconButton
                  variant="ghost"
                  aria-label="Close Dropdown"
                  type={'button'}
                  mb={3}
                  onClick={handleDrawerClose}
                  boxShadow="none"
                  size="xs"
                  icon={<ChevronDownIcon w={8} h={8} color={iconColor} />}
                />

                <InputGroup>
                  <InputLeftElement>
                    <SearchIcon color={iconColor} />
                  </InputLeftElement>
                  <Input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={searchPlaceHolder || 'Search...'}
                  />
                </InputGroup>
              </Flex>
            </DrawerHeader>
          )}
          <DrawerBody p={0}>
            {options
              .filter((option) =>
                formatSearchString(option[labelAttribute].toString()).includes(formatSearchString(searchTerm))
              )
              .map((option, i) => {
                const circuitType = option[valueAttribute];

                // If the circuit type is not known, we will display a placeholder icon
                const isKnownCircuitType = circuitType in CIRCUIT_TYPE_TO_ICON;
                const IconComponent = isKnownCircuitType ? CIRCUIT_TYPE_TO_ICON[circuitType] : CircuitPlaceholderIcon;
                return (
                  <Button
                    data-testid={`option-${circuitType}`}
                    type={'button'}
                    variant="ghost"
                    key={`${option[labelAttribute]}-${i}`}
                    py={7}
                    px={6}
                    width="100%"
                    borderBottomWidth="1px"
                    alignItems="center"
                    justifyContent="flex-start"
                    cursor="pointer"
                    onClick={() => {
                      onChange(option[valueAttribute].toString(), option[labelAttribute].toString());
                      handleDrawerClose();
                    }}
                  >
                    <IconComponent w={6} h={6} data-testid={`icon-${circuitType}`} />
                    <Text fontWeight="normal" ml={2}>
                      {option[labelAttribute]}
                    </Text>
                  </Button>
                );
              })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
