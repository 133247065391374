import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAppliances } from '../meterSetupSlice';

export const useSwitchAppliance = () => {
  const appliances = useSelector(selectAppliances);

  const switchAppliances = useMemo(() => {
    return appliances.filter((appliance) => appliance.circuits.length === 1);
  }, [appliances]);

  return switchAppliances;
};
