import React, { useMemo, useState } from 'react';
import { Box, Flex, Heading, Select, Text, useColorModeValue, useTheme } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { Card } from 'clipsal-cortex-ui/src/components/card/Card';

import { BatterySimulation, LoadProfile } from '../../api/api-battery-upsell';
import { HighchartsPoint } from '../../api/api-fleet-data';
import { COMMON_CHART_DATETIME_FORMATS } from '../../common/constants';
import { BatteryDataType, BatteryDataTypePicker } from './BatteryDataPicker';
import { BatteryInfoStats } from './BatteryInfoStats';

highchartsAccessibility(Highcharts);

type AverageDailyEnergyChartProps = {
  isMobileViewport?: boolean;
  // JSPDF cannot render shadows properly, so we need to hide it when exporting to PDF
  isRenderingPdf?: boolean;
  simulation: BatterySimulation;
  loadProfile: LoadProfile[];
};

export const AverageDailyEnergyChart = ({
  simulation,
  loadProfile,
  isMobileViewport,
  isRenderingPdf,
}: AverageDailyEnergyChartProps) => {
  const [selectedBatteryDataType, setSelectedBatteryDataType] = useState<BatteryDataType>(BatteryDataType.After);
  const theme = useTheme();
  const chartLabelColor = useColorModeValue(theme.colors.gray[500], theme.colors.gray[400]);

  const { totalSolarProduction, totalLoadConsumption, totalBatteryCharge, totalBatteryDischarge } = useMemo(() => {
    const isBeforeBattery = selectedBatteryDataType === BatteryDataType.Before;
    return {
      totalSolarProduction: simulation.metrics_daily.solar_production_daily_kwh,
      totalLoadConsumption: isBeforeBattery
        ? simulation.metrics_daily.load_consumption_daily_before_kwh
        : simulation.metrics_daily.load_consumption_daily_after_kwh,
      totalBatteryCharge: simulation.metrics_daily.battery_charge_daily_after_kwh,
      totalBatteryDischarge: simulation.metrics_daily.battery_discharge_daily_after_kwh,
    };
  }, [simulation, selectedBatteryDataType]);

  const data = useMemo(() => {
    return (loadProfile || []).reduce(
      (acc, item) => {
        const {
          time,
          pv_site_net_total_kw: pvSiteNetTotalKw,
          battery_storage_kw: batteryStorageKw,
          total_consumed_before_kw: totalConsumedBeforeKw,
          total_consumed_after_kw: totalConsumedAfterKw,
        } = item;
        const x = time * 1000; // in ms

        acc.solar.push({ x, y: pvSiteNetTotalKw });
        acc.battery.push({ x, y: batteryStorageKw });
        acc.consumptionBefore.push({ x, y: totalConsumedBeforeKw });
        acc.consumptionAfter.push({ x, y: totalConsumedAfterKw });
        return acc;
      },
      {
        solar: [] as HighchartsPoint[],
        battery: [] as HighchartsPoint[],
        consumptionBefore: [] as HighchartsPoint[],
        consumptionAfter: [] as HighchartsPoint[],
      }
    );
  }, [selectedBatteryDataType, loadProfile]);

  const options: Highcharts.Options = useMemo(() => {
    const isBeforeBattery = selectedBatteryDataType === BatteryDataType.Before;
    const valueSuffix = ' kW';

    const series: Highcharts.Options['series'] = [
      {
        color: 'rgba(255, 197, 52, 1)',
        lineColor: 'rgba(255, 197, 52, 1)',
        borderColor: 'rgba(255, 197, 52, 1)',
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(255, 197, 52, 0.5)'],
            [1, 'rgba(255, 197, 52, 0)'],
          ],
        },
        type: 'area',
        data: data.solar,
        name: 'Solar Production',
      },
      {
        color: 'rgba(134, 182, 209, 1)',
        lineColor: 'rgba(134, 182, 209, 1)',
        borderColor: 'rgba(134, 182, 209, 1)',
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(134, 182, 209, 0.5)'],
            [1, 'rgba(134, 182, 209, 0)'],
          ],
        },
        type: 'area',
        data: isBeforeBattery ? data.consumptionBefore : data.consumptionAfter,
        name: 'Load Consumption',
      },
    ];

    if (!isBeforeBattery)
      series.push({
        color: 'rgba(87, 187, 89, 1)',
        lineColor: 'rgba(87, 187, 89, 1)',
        borderColor: 'rgba(87, 187, 89, 1)',
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(87, 187, 89, 0.5)'],
            [1, 'rgba(87, 187, 89, 0)'],
          ],
        },
        type: 'area',
        data: data.battery,
        name: 'Battery Operation',
      });

    return {
      series,
      chart: {
        height: 300,
        marginTop: isMobileViewport ? 20 : 50,
        backgroundColor: 'transparent',
        spacingLeft: 0,
        spacingRight: 0,
        style: {
          fontFamily: '-apple-system, sans-serif',
        },
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        // endOnTick: false,
        title: {
          text: '',
        },
        labels: {
          style: { color: chartLabelColor },
          formatter: function () {
            return this.value + valueSuffix;
          },
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
        labels: {
          style: { color: chartLabelColor, textTransform: 'uppercase' },
          formatter: function () {
            const date: Date = new Date(this.value);
            const hour24 = date.getUTCHours();
            const hour12 = hour24 % 12 || 12;
            const ampm = hour24 >= 12 ? 'PM' : 'AM';
            return `${hour12} ${ampm}`;
          },
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        animation: false,
        shared: true,
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
        valueDecimals: 3,
        valueSuffix,
        xDateFormat: `Today, ${COMMON_CHART_DATETIME_FORMATS.minute}`,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      legend: {
        enabled: false,
      },
    };
  }, [selectedBatteryDataType, chartLabelColor, isMobileViewport, loadProfile]);

  return (
    <Card p={5} w="100%" mb={0} shadow={isRenderingPdf ? 'none' : 'md'} data-testid="average-daily-energy-chart">
      <Flex alignItems={'center'} mb={2}>
        <Heading
          fontWeight={600}
          size={isMobileViewport ? 'sm' : 'md'}
          color={'black'}
          _dark={{ color: 'white' }}
          mt={isRenderingPdf ? -6 : 0}
        >
          Average Daily Energy Profile
        </Heading>

        {isMobileViewport ? (
          <Select
            data-testid="select-battery-data-type"
            fontSize={'14px'}
            w={126}
            ml="auto"
            variant={'unstyled'}
            value={selectedBatteryDataType}
            onChange={(e) => {
              setSelectedBatteryDataType(e.target.value as BatteryDataType);
            }}
          >
            <option value={BatteryDataType.Before}>Before Battery</option>
            <option value={BatteryDataType.After}>After Battery</option>
          </Select>
        ) : (
          <Box w="100%" maxW={280} ml="auto">
            <BatteryDataTypePicker
              selectedBatteryDataType={selectedBatteryDataType}
              setSelectedBatteryDataType={setSelectedBatteryDataType}
            />
          </Box>
        )}
      </Flex>

      <Flex wrap="wrap" mb={2} data-testid="average-daily-chart-stats">
        {/* Fixes weird padding issues when rendering pdf */}
        {isRenderingPdf && <Box h={16} />}
        <BatteryInfoStats
          bg={'ellipse.500'}
          title={'Solar Production'}
          value={totalSolarProduction}
          mt={2}
          mr={10}
          isRenderingPdf={isRenderingPdf}
        />
        <BatteryInfoStats
          bg={'day.500'}
          title={'Load Consumption'}
          value={totalLoadConsumption}
          mr={isMobileViewport ? 4 : 10}
          mt={2}
          isRenderingPdf={isRenderingPdf}
        />

        <Flex mt={2}>
          <BatteryInfoStats
            bg={'primaryBranding.500'}
            title={'Battery Operation'}
            value={totalBatteryCharge}
            suffix="kWh Charge"
            isRenderingPdf={isRenderingPdf}
          />
          <Text mt="auto" mb={isRenderingPdf ? 2 : 0} ml={6} lineHeight={5} color={'black'} _dark={{ color: 'white' }}>
            <Box as="span" fontWeight={'bold'} fontSize={'20px'} mr={1} lineHeight={6}>
              {totalBatteryDischarge?.toFixed(1) ?? 'N/A'}
            </Box>
            kWh Discharge
          </Text>
        </Flex>
      </Flex>

      <Box data-testid="fleet-dashboard-usage-chart-container">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Box>
    </Card>
  );
};
