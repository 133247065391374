import React, { useRef, useState } from 'react';
import { CheckCircleIcon, ChevronRightIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Link as ChakraLink,
  Flex,
  Grid,
  Heading,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { del } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import { MobileTopNav } from '../../../common/components/MobileTopNav';
import { SUPPORT_EMAIL } from '../../../common/constants';
import { IOS_APP_STORE_REVIEW_EMAIL } from '../user-helpers';
import { logOut, selectUser } from '../userSlice';

export function AccountSettings() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const toast = useToast({ isClosable: true });
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpenCannotDeleteModal,
    onClose: onCloseCannotDeleteModal,
    isOpen: isOpenCannotDeleteModal,
  } = useDisclosure();
  const cancelRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { background, hoverBackground } = useColorModeValue(
    { background: 'white', hoverBackground: 'gray.50' },
    { background: 'gray.900', hoverBackground: 'gray.800' }
  );
  const [{ isDeleting, hasDeleted }, setState] = useState({
    isDeleting: false,
    hasDeleted: false,
  });
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    // if deleted, don't close modal as redirecting to login
    if (hasDeleted) return;
    onClose();
    setState((prevState) => ({ ...prevState, isDeleting: false }));
  };

  return (
    <Box px={[0, 0, 10]}>
      {isMobileViewport ? (
        <MobileTopNav title={'Account Settings'} backURL={'/account/home'} />
      ) : (
        <Button as={Link} to={'/account/home'} mt={3}>
          Back
        </Button>
      )}
      <Center mx={[0, 0, 10]} flexDirection={'column'}>
        <Box w={['100%', '100%', '50%']} bg={background} borderRadius={[0, 0, 10]} overflow="hidden">
          {isMobileViewport || (
            <Heading mb={5} size={'lg'} paddingLeft={[0, 0, 6]} paddingTop={[0, 0, 6]}>
              Account Settings
            </Heading>
          )}

          <Grid borderTop={`1px solid ${theme.colors.dusk005[500]}`} p={6} gridTemplateColumns={'1fr 25px'}>
            <Box justifyContent={'center'}>
              <Text fontWeight={'bold'}>Name</Text>
              <Text data-private>{user.user_full_name}</Text>
            </Box>
          </Grid>

          <Grid borderTop={`1px solid ${theme.colors.dusk005[500]}`} p={6} gridTemplateColumns={'1fr 25px'}>
            <Box justifyContent={'center'}>
              <Text fontWeight={'bold'}>Email</Text>
              <Text data-private>{user.user_email}</Text>
            </Box>
          </Grid>

          <Grid borderTop={`1px solid ${theme.colors.dusk005[500]}`} p={6} gridTemplateColumns={'1fr 25px'}>
            <Box justifyContent={'center'}>
              <Text fontWeight={'bold'}>Phone</Text>
              <Text data-private>{user.user_phone}</Text>
            </Box>
          </Grid>

          <Grid
            onClick={() => navigate('/account/change_password')}
            role={'button'}
            _hover={{ background: hoverBackground }}
            cursor={'pointer'}
            borderTop={`1px solid ${theme.colors.dusk005[500]}`}
            p={6}
            gridTemplateColumns={'1fr 25px'}
          >
            <Box justifyContent={'center'} data-testid={'change-password-btn'}>
              <Text fontWeight={'bold'}>Password</Text>
              <Text>************</Text>
            </Box>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>
          <Grid
            onClick={() => {
              // This is ugly but we need to prevent the user from deleting the account used for the iOS app store review
              const mustNotDelete = user.user_email === IOS_APP_STORE_REVIEW_EMAIL;
              if (mustNotDelete) {
                onOpenCannotDeleteModal();
              } else {
                onOpen();
              }
            }}
            role={'button'}
            _hover={{ background: 'red.600' }}
            cursor={'pointer'}
            borderTop={`1px solid ${theme.colors.dusk005[500]}`}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            p={6}
            gridTemplateColumns={'1fr 25px'}
            bg="red.500"
            color="white"
          >
            <Box justifyContent={'center'} data-testid={'delete-account-btn'}>
              <Text fontWeight={'bold'}>Delete Account</Text>
            </Box>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>

          <AlertDialog
            leastDestructiveRef={cancelRef}
            onClose={onCloseCannotDeleteModal}
            isOpen={isOpenCannotDeleteModal}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent data-testid="cannot-delete-account-dialog" mx={4}>
                <AlertDialogBody>
                  <Center mt={4}>
                    <Flex bg="red.100" rounded={100} p={4}>
                      <WarningTwoIcon color="red.500" w={8} h={8} mx="auto" />
                    </Flex>
                  </Center>
                  <Text textAlign="center" fontSize="lg" fontWeight="bold" my={4}>
                    Sorry, this account cannot be deleted!
                  </Text>
                  <Text color="dusk100.400">
                    This account is used for the iOS App Store review process. If you would like to test account
                    deletion, you can log out and create a brand new account to delete.
                  </Text>
                </AlertDialogBody>
                <Button
                  data-testid="cannot-delete-acccount-btn"
                  colorScheme="primaryBranding"
                  type="submit"
                  onClick={onCloseCannotDeleteModal}
                  mx="auto"
                  w={250}
                  my={4}
                >
                  Got it!
                </Button>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <AlertDialog leastDestructiveRef={cancelRef} onClose={handleModalClose} isOpen={isOpen} isCentered>
            <AlertDialogOverlay>
              <AlertDialogContent data-testid="delete-account-dialog" mx={4}>
                {hasDeleted ? (
                  <Center flexDirection={'column'} minH={'50vh'} data-testid="delete-redirect-message">
                    <CheckCircleIcon color="green.500" w={16} h={16} mx="auto" mt={4} mb={2} />
                    <Heading mb={2} size={'md'} my={4}>
                      Successfully deleted account!
                    </Heading>
                    <Text>Redirecting you to the login page...</Text>
                  </Center>
                ) : (
                  <>
                    <AlertDialogBody>
                      <Center mt={4}>
                        <Flex bg="red.100" rounded={100} p={4}>
                          <WarningTwoIcon color="red.500" w={8} h={8} mx="auto" />
                        </Flex>
                      </Center>
                      <Text textAlign="center" fontSize="lg" fontWeight="bold" my={4}>
                        Delete Account?
                      </Text>
                      <Text color="dusk100.400">
                        We're sad to see you go. Once your account is deleted, all of your information will be
                        permanently gone. If you're not sure about that, we suggest you to contact us at
                        <ChakraLink
                          onClick={() => {
                            navigator.clipboard.writeText(SUPPORT_EMAIL);
                            toast({ title: 'Email copied to clipboard!' });
                          }}
                          href={`mailto:${SUPPORT_EMAIL}?subject=Clipsal Cortex Fleet App Issues`}
                          mx={1}
                          color="customLinkBlue.500"
                        >
                          support@clipsalcortex.com
                        </ChakraLink>
                        instead.
                      </Text>
                    </AlertDialogBody>
                    <Flex w="100%" flexDirection={'column'} px={6} pb={4} as="form" mt={4}>
                      <Button
                        data-testid="confirm-delete-acccount-btn"
                        colorScheme="red"
                        type="submit"
                        isLoading={isDeleting}
                        onClick={async (e) => {
                          e.preventDefault();
                          setState((prevState) => ({ ...prevState, isDeleting: true }));
                          try {
                            await del(`/fleet/v2/users/${user.user_id}`, {});
                            await Auth.signOut({ global: true });
                            setState((prevState) => ({ ...prevState, isDeleting: false, hasDeleted: true }));
                            toast({ title: 'Successfully deleted account!' });

                            // Show redirect screen for 1.5 second before redirecting to login page
                            setTimeout(() => {
                              dispatch(logOut());
                            }, 1500);
                          } catch (e) {
                            console.error(e);
                            toast({ title: 'Something went wrong. Please try again!', status: 'error' });
                            setState((prevState) => ({ ...prevState, isDeleting: false }));
                          }
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        data-testid="cancel-delete-acccount-btn"
                        onClick={handleModalClose}
                        variant={'ghost'}
                        mt={2}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </>
                )}
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>
      </Center>
    </Box>
  );
}
