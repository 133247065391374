import React from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  LayoutProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

type OptionalProps = { useDefaultLayout?: boolean; icon?: React.ReactNode; heading?: string; subHeading?: string };

type CommonProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  drawerHeight?: LayoutProps['height'];
};

type Props = CommonProps & OptionalProps;

export function InfoBottomDrawer({
  children,
  isOpen,
  onClose,
  useDefaultLayout = false,
  drawerHeight = '70vh',
  icon,
  heading,
  subHeading,
}: Props) {
  const textColor = useColorModeValue('dusk100.500', 'dusk100.50');
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={20} height={drawerHeight} data-testid="info-bottom-drawer">
        <DrawerCloseButton />
        {useDefaultLayout && (
          <Flex direction="column" align={'center'} width="100%">
            <Box mt={12}>{icon}</Box>

            <DrawerHeader mx={4} textAlign="center">
              {heading}
            </DrawerHeader>

            <DrawerBody>
              <Text mx={4} textAlign="center" color={textColor}>
                {subHeading}
              </Text>
            </DrawerBody>
          </Flex>
        )}
        {children}
      </DrawerContent>
    </Drawer>
  );
}
