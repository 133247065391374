import React, { useMemo } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  Heading,
  Link,
  SkeletonCircle,
  SkeletonText,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { Pie, PieChart } from 'recharts';

import { useTotalBatteryUpsellSites } from '../../upsell/batteryUpsellApi';

export const BatteryUpsellWidget = () => {
  const theme = useTheme();
  const { total, isFetchingTotalSites } = useTotalBatteryUpsellSites();

  const chartData = useMemo(
    () => [
      { numSites: total.interested, fill: theme.colors.customPurple[500] },
      { numSites: total.not_set + total.interested, fill: theme.colors.customLinkBlue[500] },
    ],
    [total]
  );

  return (
    <>
      <Flex>
        <Heading size={'md'}>Battery Upsell</Heading>
        <Link href="/upsells" ml="auto" color="customLinkBlue.500" minW={106} data-testid="view-battery-upsell-sites">
          View More <ChevronRightIcon w={6} h={6} />
        </Link>
      </Flex>

      <Center position={'relative'}>
        {!isFetchingTotalSites ? (
          <>
            <VStack position={'absolute'}>
              <Heading size={'md'}>{total.not_set + total.interested}</Heading>
              <Text size={'sm'} maxW={120} textAlign={'center'}>
                All battery ready sites
              </Text>
            </VStack>
            <PieChart data-testid={'total-sites-with-battery'} width={200} height={200}>
              <Pie data={chartData} dataKey="numSites" outerRadius={85} innerRadius={70} />
            </PieChart>
          </>
        ) : (
          <SkeletonCircle my={4} size={'40'} />
        )}
      </Center>

      <Flex justify={'space-evenly'}>
        <Flex mr={2}>
          <Box w={'4px'} bg={'customPurple.500'} />
          <Box pl={4}>
            <Heading size={'sm'}>Interested</Heading>
            {!isFetchingTotalSites ? (
              <Text data-testid={'num-battery-sites'} mt={2}>
                {total.interested}
              </Text>
            ) : (
              <SkeletonText />
            )}
          </Box>
        </Flex>

        <Flex>
          <Box w={'4px'} bg={'customLinkBlue.500'} />
          <Box pl={4}>
            <Heading size={'sm'}>Not Requested</Heading>
            {!isFetchingTotalSites ? (
              <Text data-testid={'num-battery-ready-sites'} mt={2}>
                {total.not_set}
              </Text>
            ) : (
              <SkeletonText />
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
